import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { onOpenIntercomWidget } from 'src/util/intercom'
import { appLinks } from 'src/util/links'
import { NavLink } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import * as React from 'react'
import { useMemo } from 'react'
import { tollFreeErrors } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/errors'

export const UseCaseErrorAlert = observer(() => {
  const { verificationErrorDescription, isNotResubmitable, isSuperAdminModal } =
    tollFreeVerificationStore
  const info = useMemo(() => {
    const mockTitle = verificationErrorDescription || ''
    const mockText = (
      <>
        {isNotResubmitable ? (
          <div className={'par2 black'}>
            Ineligible for resubmission. If you feel that this was in error,
            please{' '}
            <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
              contact us.
            </button>
            <br />
            <br />
            See{' '}
            <a
              href={appLinks.tollFreeErrorCodes}
              target={'_blank'}
              rel="noreferrer"
            >
              forbidden message categories
            </a>{' '}
            for more information on use cases and content.
          </div>
        ) : (
          <div className={'par2 black'}>
            Verify that all the business information fields are correct. Ensure
            that you have a live website URL. <br />
            <br />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            It's best to edit and resubmit within 7 days for a faster review.
          </div>
        )}
      </>
    )

    const customError = tollFreeErrors[mockTitle?.toLowerCase()]

    return {
      title: customError?.cause || mockTitle,
      text: customError?.solutions || mockText || '',
    }
  }, [verificationErrorDescription, isNotResubmitable])
  return (
    <div className={'mt32'}>
      <Alert
        type={AlertTypeEnum.error}
        title={info.title}
        text={info.text}
        rightContent={
          <div className={'mt4'}>
            {!isSuperAdminModal && (
              <NavLink
                to={`${RoutesEnum.settingsCompliance}/${RoutesEnum.tollFreeVerification}`}
                className={'linkError s1'}
              >
                Update
              </NavLink>
            )}
          </div>
        }
      />
    </div>
  )
})
