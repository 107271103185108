// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TOeVW3ctQmzAQbFPg034{margin-top:40px;display:flex;justify-content:space-between}.TOeVW3ctQmzAQbFPg034.jAJo0eJ1LshrXAo69M7g{margin-top:86px}.TOeVW3ctQmzAQbFPg034 .MU7GJODlXhcm0gmxbuE1{display:flex;column-gap:12px}.IWNqkS8v0gyi9kEpG1zA{padding:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/keywords/create/createForm/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,2CACE,eAAA,CAEF,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".actionsWrap{\n  margin-top: 40px;\n  display: flex;\n  justify-content: space-between;\n  &.fromModal{\n    margin-top: 86px;\n  }\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsWrap": `TOeVW3ctQmzAQbFPg034`,
	"fromModal": `jAJo0eJ1LshrXAo69M7g`,
	"left": `MU7GJODlXhcm0gmxbuE1`,
	"menuWrap": `IWNqkS8v0gyi9kEpG1zA`
};
export default ___CSS_LOADER_EXPORT___;
