import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'

type Props = {
  to?: string
  className?: string
}

export const LinkWrapper: FC<Props> = observer(
  ({ to, className, children }) => {
    const { pathname } = useLocation()
    if (to) {
      const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (
          (pathname.includes('new') ||
            pathname.includes('edit') ||
            pathname.includes('duplicate')) &&
          !pathname.includes('segments/new') &&
          uiStore.isBeforeUnload
        ) {
          uiStore.setOpenCancelCreateModal(true)
          uiStore.setNextRoute(to)
          e.preventDefault()
        } else {
          uiStore.setNextRoute('')
        }
      }

      return (
        <Link to={to} onClick={onClick} className={className}>
          {children}
        </Link>
      )
    }
    return <>{children}</>
  }
)
