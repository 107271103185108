import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { CreateStore } from 'components/CreateElement/store/createStore'
import { IBroadcast } from 'src/types/IBroadcast'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import {
  Asset,
  BroadcastInput,
  ContactCardFragment,
  ContentType,
  GetBroadcastEstimatedPriceQuery,
  PhoneType,
  Status,
  VerificationStatus,
} from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import { IOption } from 'src/types/IOption'
import { numberString } from 'src/util/functions'
import numbersStore from 'store/settings/numbers/numbersStore'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'

export interface IPreviewMessage {
  messageHtml: string
  attachImages: Asset[]
  vCards?: ContactCardFragment[]
  answer?: string
  messageId: string
  withoutCompliance?: boolean
  placeholder?: string
  compliance?: boolean
  complianceText?: string
}

export class CreateBroadCastStore extends CreateStore {
  dropdownContactListsStore: DropdownContactListsStore
  scheduleStore: BroadcastScheduleStore
  constructor(broadcast?: IBroadcast, isDuplicate?: boolean) {
    super({
      createBtnText: 'Create broadcast',
      compliance: false,
    })
    this.dropdownContactListsStore = new DropdownContactListsStore()
    this.scheduleStore = new BroadcastScheduleStore()

    makeObservable(this, {
      initInput: observable,
      dropdownContactListsStore: observable,
      edit: observable,
      totalContacts: observable,
      totalCredits: observable,
      editId: observable,
      duplicate: observable,
      status: observable,
      selectedContactListOptions: observable,
      isHiddenVerifyInfoTollFree: observable,
      isHiddenVerifyInfoLocal: observable,
      broadcastAllowed: observable,
      openCreateModal: observable,

      disabledCreate: computed,
      broadcastInput: computed,
      neededCredits: computed,
      isVerifyInfoTollFree: computed,
      isVerifyInfoLocal: computed,
      isUnVerifyPhone: computed,
      limitMessage: computed,
      isLimitInfo: computed,
      limitMessageText: computed,
      isDisableByLimit: computed,

      setEdit: action.bound,
      setEstimate: action.bound,
      setHiddenVerifyInfoTollFree: action.bound,
      setHiddenVerifyInfoLocal: action.bound,
      setOpenCreateModal: action.bound,
    })

    if (broadcast) {
      this.setEdit(broadcast, isDuplicate || false)
    }

    reaction(
      () => this.broadcastInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        runInAction(() => {
          this.isHaveChanges = initInputString !== inputString
        })
      }
    )

    reaction(
      () => this.isUnVerifyPhone,
      (value) => {
        this.textareaStore.setUnVerifyToolFree(value)
      }
    )
    reaction(
      () => this.scheduleStore.sendTimeString,
      (value) => {
        this.setSendTimeString(value)
      }
    )
  }

  element = 'Broadcast'
  edit = false
  totalContacts = 0
  totalCredits = 0
  broadcastAllowed = true
  broadcastAllowedMessage = ''
  editId = ''
  duplicate = false
  selectedContactListOptions: IOption[] = []
  status = Status.Scheduled
  initInput: BroadcastInput | null = null
  isHiddenVerifyInfoTollFree = false
  isHiddenVerifyInfoLocal = false
  openCreateModal = false

  get isUnVerifyPhone() {
    return !(
      this.smsPhoneFull?.verificationStatus === VerificationStatus.Verified ||
      this.smsPhoneFull?.verificationStatus === VerificationStatus.Pending ||
      this.smsPhoneFull?.verificationStatus === VerificationStatus.InReview
    )
  }

  get isVerifyInfoTollFree() {
    return (
      this.isUnVerifyPhone &&
      !this.isHiddenVerifyInfoTollFree &&
      this.smsPhoneFull?.type === PhoneType.TollFree
    )
  }

  get isVerifyInfoLocal() {
    return (
      this.isUnVerifyPhone &&
      !this.isHiddenVerifyInfoLocal &&
      this.smsPhoneFull?.type === PhoneType.Local
    )
  }

  get isLimitInfo() {
    if (this.activeTab === 0) {
      if (billingStore.isTrial) {
        return true
      }
      if (this.smsPhoneFull?.type === PhoneType.ShortCode) {
        return false
      }
      if (
        this.smsPhoneFull?.type === PhoneType.TollFree &&
        this.smsPhoneFull.verificationStatus === VerificationStatus.Verified
      ) {
        return false
      }
      if (
        this.smsPhoneFull?.type === PhoneType.Local &&
        this.smsPhoneFull?.verificationStatus === VerificationStatus.Verified
      ) {
        return false
      }
      return !!this.smsPhoneFull?.type
    }
    return false
  }

  get limitMessage() {
    if (billingStore.isTrial) {
      return `You’re limited to ${numberString({ val: 25 })} messages`
    }
    if (
      this.smsPhoneFull?.type === PhoneType.TollFree ||
      this.smsPhoneFull?.type === PhoneType.Local
    ) {
      if (
        this.smsPhoneFull?.verificationStatus === VerificationStatus.Verified &&
        this.smsPhoneFull?.type === PhoneType.Local
      ) {
        return `You’re limited to  ${numberString({
          val: 200000,
        })} messages/day on verified toll-free`
      }
      if (
        this.smsPhoneFull?.verificationStatus === VerificationStatus.InReview ||
        this.smsPhoneFull?.verificationStatus === VerificationStatus.Pending
      ) {
        return `You’re limited to  ${numberString({
          val: 2000,
        })} messages/day on ${
          this.smsPhoneFull?.type === PhoneType.TollFree
            ? 'pending toll-free'
            : 'local numbers'
        }`
      }
    }
    return ''
  }

  get limitMessageText() {
    if (billingStore.isTrial) {
      return 'Upgrade to a plan to increase your limit'
    }
    if (
      this.smsPhoneFull?.verificationStatus === VerificationStatus.InReview ||
      this.smsPhoneFull?.verificationStatus === VerificationStatus.Pending
    ) {
      return 'After toll-free approval, you’ll be able to send more messages.'
    }
    return this.broadcastAllowedMessage
  }

  get isDisableByLimit() {
    return !!(
      billingStore.clSubscription?.limits?.broadcasts &&
      billingStore.clSubscription?.limits?.broadcasts <=
        broadcastsStore.tableStore.total &&
      !this.edit
    )
  }

  get neededCredits() {
    if (billingStore.autoRecharge && !billingStore.isTrial) {
      return 0
    }
    return this.totalCredits - billingStore.currentCredits
  }

  get disabledCreate() {
    if (!this.name) {
      return true
    }
    if (this.scheduleStore.scheduleError) {
      return true
    }
    if (!this.broadcastInput.sendFromPhoneNumber) {
      return true
    }
    if (this.type === ContentType.Sms) {
      if (!this.textareaStore.smsMessageInput.text) {
        return true
      }
      if (this.textareaStore.isError) {
        return true
      }
    }
    if (this.type === ContentType.Voice) {
      if (this.voiceStore.disabledCreate) {
        return true
      }
    }
    if (this.type === ContentType.Ringless) {
      if (this.ringlessStore.disabledCreate) {
        return true
      }
    }
    if (!this.dropdownContactListsStore.selectedOptions.length) {
      return true
    }
    return false
  }

  get broadcastInput(): BroadcastInput {
    return {
      id: this.edit ? this.editId : undefined,
      name: this.name,
      status: this.status,
      listIds: this.dropdownContactListsStore.selectedContactListsIds,
      contactIds: this.dropdownContactListsStore.selectedContactIds,
      filterValue: {
        segmentIds: this.dropdownContactListsStore.selectedSegmentsIds,
      },
      smsMessage:
        this.type === ContentType.Sms
          ? this.textareaStore.smsMessageInput
          : undefined,
      voiceMessage:
        this.type === ContentType.Voice
          ? this.voiceStore.voiceMessage
          : undefined,
      ringlessMessage:
        this.type === ContentType.Ringless
          ? this.ringlessStore.ringlessMessage
          : undefined,
      sendFromPhoneNumber:
        this.type === ContentType.Sms
          ? numbersStore.numbersMapByFriendly.get(this.smsPhone)
          : numbersStore.numbersMapByFriendly.get(this.voicePhone),
      schedulingConfig: this.scheduleStore.schedulingConfigInput,
    }
  }

  setOpenCreateModal(state: boolean) {
    if (state) {
      this.broadcastAllowed = true
    }
    this.openCreateModal = state
  }

  setEdit = (broadcast: IBroadcast, isDuplicate: boolean) => {
    const newBroadcast: IBroadcast = {
      ...broadcast,
      name: `${broadcast.name}${isDuplicate ? ' copy' : ''}`,
    }
    if (isDuplicate) {
      this.duplicate = true
      this.status = Status.Scheduled
    } else {
      this.edit = true
      this.editId = broadcast.id
      this.status = broadcast.status || Status.Draft
    }
    this.name = newBroadcast.name || ''
    this.type = broadcast.type || ContentType.Sms
    if (broadcast.type === ContentType.Sms) {
      if (
        !broadcast.sendFromPhoneNumber?.verificationStatus ||
        broadcast.sendFromPhoneNumber?.verificationStatus ===
          VerificationStatus.Unverified
      ) {
        this.smsPhone = ''
      } else {
        this.smsPhone = broadcast.sendFromPhoneNumber?.friendlyName || ''
      }
    }
    if (broadcast.type !== ContentType.Sms) {
      if (broadcast.type === ContentType.Voice) {
        this.voiceStore.setInit(broadcast.voiceMessage)
      }
      if (broadcast.type === ContentType.Ringless) {
        this.ringlessStore.setInit(broadcast.ringlessMessage)
      }
      this.voicePhone = broadcast.sendFromPhoneNumber?.friendlyName || ''
    }
    this.textareaStore.setSmsMessage(broadcast.smsMessage)
    this.textareaStore.setTriggerMessageUpdate()

    this.dropdownContactListsStore.setInitItems(broadcast.items)

    if (broadcast.schedulingConfig) {
      this.scheduleStore.setSchedulingConfig(broadcast.schedulingConfig)
    }
    this.initInput = this.broadcastInput
  }

  setEstimate(data: GetBroadcastEstimatedPriceQuery) {
    this.totalContacts =
      data.getBroadcastEstimatedPrice?.activeContactsCount || 0
    this.totalCredits = data.getBroadcastEstimatedPrice?.totalPrice || 0
    this.broadcastAllowed =
      data.getBroadcastEstimatedPrice?.broadcastAllowed || false
    this.broadcastAllowedMessage =
      data.getBroadcastEstimatedPrice?.message || ''
  }

  setHiddenVerifyInfoTollFree() {
    this.isHiddenVerifyInfoTollFree = true
  }
  setHiddenVerifyInfoLocal() {
    this.isHiddenVerifyInfoLocal = true
  }
}
