import { Card } from 'components/Card/Card'
import { useSetPaymentsMethods } from 'src/routes/settings/billing/hooks/useSetPaymentsMethods'
import { Token } from '@stripe/stripe-js'
import billingStore from 'store/settings/billing/billingStore'
import user from 'store/user/user'
import mainStore from 'store/mainStore'
import { nanoid } from 'nanoid'
import alertStore from 'store/alertStore'
import { useContactDetailsRegistrationStepMutation } from 'src/generated/graphql'
import { useSetMember } from 'src/routes/settings/account/hooks/useSetMember'
import { useMemo, useState } from 'react'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { BackMigrationBtn } from 'src/routes/auth/migration/BackMigrationBtn'
import { observer } from 'mobx-react-lite'
import { useRegisterHubspotMember } from 'src/hooks/useRegisterHubspotMember'
import { useUpdateMemberLead } from 'src/hooks/useUpdateMemberLead'
import { MemberInfo } from 'src/widgets/MemberInfo/MemberInfo'
import { useCreditCard } from 'src/widgets/CreditCard/useCreditCard'
import { CreditCard } from 'src/widgets/CreditCard/CreditCard'

const title = 'What is your name?'

export const ContactDetailsStep = observer(() => {
  useSetMember()
  useRegisterHubspotMember()
  useSetPaymentsMethods()

  const [loading, setLoading] = useState(false)

  const creditCardProps = useCreditCard({ setLoading })

  const { selectedValue, errorAlert, readyCard, setError, refBtn } =
    creditCardProps

  const [info, setInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  })
  const { firstName, lastName, phone } = info

  useUpdateMemberLead(phone, firstName, lastName)

  const [contactDetailsRegistrationStep, { loading: loadingMigration }] =
    useContactDetailsRegistrationStepMutation()

  const onNext = async (
    cardId?: string,
    tokenId?: string,
    zipCode?: string
  ) => {
    try {
      const { data } = await contactDetailsRegistrationStep({
        variables: {
          input: {
            paymentMethodId: tokenId ? undefined : cardId || selectedValue,
            phone,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            tokenId,
            zipCode,
          },
        },
      })
      if (data?.contactDetailsRegistrationStep) {
        user.setMember(data.contactDetailsRegistrationStep)
        mainStore.updateAnalyticTrigger()
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message.includes('Your card was declined.')) {
          billingStore.setUpgradeCardId(cardId || selectedValue)
          setError(nanoid())
          alertStore.disabledErrorAlert(e.message)
        }
      }
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const onAction = (token: Token) => {
    const tokenId = token.id
    return onNext(undefined, tokenId, token.card?.address_zip || '')
  }

  const onContinue = async () => {
    setLoading(true)
    if (!!billingStore.cardsOptions.length) {
      if (billingStore.showNewCard) {
        billingStore.setAddActionNewCardCb(onNext)
        billingStore.newCardElement?.click()
      } else {
        onNext()
      }
    } else {
      if (refBtn.current) {
        refBtn.current?.click()
      }
    }
  }
  const isDisabled = useMemo(() => {
    if (!!billingStore.cardsOptions.length) {
      if (billingStore.showNewCard) {
        if (!billingStore.readyNewCard) {
          return true
        }
      } else {
        if (!selectedValue) {
          return true
        }
      }
    } else {
      if (!readyCard) {
        return true
      }
    }

    return (
      !firstName.trim() || !lastName.trim() || !phone || phone.includes('_')
    )
  }, [
    billingStore.showNewCard,
    billingStore.readyNewCard,
    readyCard,
    selectedValue,
    firstName,
    lastName,
    phone,
  ])

  return (
    <Card width={480} margin={0}>
      <>
        <h3 className={'bold'}>{title}</h3>
        <SizedBox height={32} />
        {errorAlert}
        <MemberInfo info={info} setInfo={setInfo} disabledFields={['email']} />
        <SizedBox height={32} />
        <CreditCard
          {...creditCardProps}
          setLoading={setLoading}
          onAction={onAction}
          skip
        />

        <div className={'mt40 '}>
          <div className={'row12'}>
            <Button
              size={BtnSize.medium}
              typeBtn={BtnType.primary}
              onClick={onContinue}
              disabled={isDisabled}
              loading={loading || loadingMigration}
            >
              Continue
            </Button>
            <BackMigrationBtn />
          </div>
        </div>
      </>
    </Card>
  )
})
