import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import styles from 'components/Dropdown/styles.module.scss'
import { Button } from 'components/Button/Button'
import { OptionsRender } from 'components/Dropdown/components/OptionsRender'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as PlusNoMask } from 'icons/16pxNoMask/Plus.svg'
import classNames from 'classnames'
import { Tag } from 'components/Tag/Tag'
import React, { ReactNode, useEffect } from 'react'
import { DropdownProps } from 'components/Dropdown/types'
import { IOption } from 'src/types/IOption'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

type DropdownToolTipProps<T> = DropdownProps<T> & {
  trigger: HTMLDivElement | HTMLButtonElement | null
  showTT: boolean
  propsSearch: string | undefined
  search: string
  isSelectALl: boolean
  dropdownSelectedOption: IOption | undefined
  filteredOptions: IOption[] | undefined
  dropdownSelectedOptions: IOption[]
  setShowTT: (value: boolean) => void
  handleCreate: () => void
  searchError?: string
  noResultsAdditionalContent?: ReactNode
  loadingSearchData?: boolean
  noDataText?: string
}

export const DropdownToolTip = <T,>(props: DropdownToolTipProps<T>) => {
  const {
    options,
    defaultOption,
    selectedOption,
    selectedValue,
    labelDropdown,
    emptyText,
    dropDownContentWidth,
    dropDownContentMinWidth,
    position = TTPositionEnum.bottomLeft,
    multi,
    addLeftPosition,
    maxHeightDropdown = 262,
    withInput,
    withPhoneInput,
    inputValue = '',
    BottomDropdownButton,
    TopDropdownButton,
    minHeightOption,
    withSelectALl,
    withNoResults,
    withNoData,
    hideDropdown,
    trigger,
    showTT,
    search,
    dropdownSelectedOption,
    isSelectALl,
    filteredOptions,
    dropdownSelectedOptions,
    withCreateTag,
    setInputValue,
    onSelect,
    onSelectValue,
    onMultiSelect,
    onMultiSelectValues,
    setShowTT,
    propsSearch,
    handleCreate,
    grouping,
    searchError,
    noResultsAdditionalContent,
    loadingSearchData,
    noDataText = 'No data',
    groupActions,
  } = props
  const onClose = () => {
    setShowTT(false)
  }

  const showCreateBtn = (): boolean => {
    if (loadingSearchData) return false
    if (options?.length && !search) {
      return false
    }
    return !!(
      withCreateTag &&
      (!filteredOptions?.length ||
        (!filteredOptions.find(
          (opt) =>
            opt.title?.toString().toLocaleLowerCase() ===
            search.toLocaleLowerCase()
        ) &&
          search))
    )
  }

  const handleSelect = (opt: IOption) => {
    setInputValue && setInputValue(String(opt.value))
    onSelect && onSelect(opt)
    onSelectValue && onSelectValue(opt.value, opt.data)
  }
  const handleMultiSelect = (options: IOption[]) => {
    onMultiSelect && onMultiSelect(options)
    onMultiSelectValues &&
      onMultiSelectValues(options.map((option) => option.value))
  }

  const onSelectAll = () => {
    if (!handleMultiSelect) {
      return
    }
    if (!dropdownSelectedOptions?.length) {
      options && handleMultiSelect(options)
    } else {
      handleMultiSelect([])
    }
  }

  useEffect(() => {
    if (defaultOption && (!selectedOption || !selectedValue)) {
      handleSelect(defaultOption)
    }
    if (defaultOption && handleMultiSelect) {
      handleMultiSelect([defaultOption])
    }
  }, [defaultOption])

  const showNoData =
    !options?.length && !search && withNoData && !loadingSearchData

  const showNoResults =
    (!!options?.length || propsSearch) &&
    !filteredOptions?.length &&
    withNoResults &&
    search &&
    !searchError &&
    !loadingSearchData

  const showError = !!searchError && !loadingSearchData

  return (
    <FixedTooltip
      trigger={trigger}
      visible={showTT && !hideDropdown}
      white
      noArrow
      width={dropDownContentWidth || trigger?.clientWidth}
      minWidth={dropDownContentMinWidth}
      position={position}
      noOpacity
      addLeftPosition={addLeftPosition}
      triggerChange={search}
    >
      <div className={styles.content} style={{ maxHeight: maxHeightDropdown }}>
        {TopDropdownButton && (
          <TopDropdownButton
            onClose={onClose}
            selectedOption={dropdownSelectedOption}
          />
        )}

        {((!!options?.length && labelDropdown) ||
          (labelDropdown && showCreateBtn()) ||
          (!!options?.length && withSelectALl)) && (
          <div className={styles.labelDropdownWrap}>
            {labelDropdown && (
              <span className={'s2 medium gray1'}>{labelDropdown}</span>
            )}
            {withSelectALl && (
              <Button onClick={onSelectAll}>
                <span className={'s2 link'}>
                  <b>{isSelectALl ? 'Select all' : 'Deselect all'}</b>
                </span>
              </Button>
            )}
          </div>
        )}
        <OptionsRender
          grouping={grouping}
          options={filteredOptions}
          selectedOptions={dropdownSelectedOptions}
          selectedOption={dropdownSelectedOption}
          onSelect={handleSelect}
          onMultiSelect={handleMultiSelect}
          setShowTT={setShowTT}
          multi={multi}
          value={inputValue}
          withInput={withInput || withPhoneInput}
          minHeightOption={minHeightOption}
          showTT={showTT}
          groupActions={groupActions}
        />
        {loadingSearchData && (
          <div className={styles.wrapLoading}>
            <AbsoluteLoader size={16} />
          </div>
        )}
        {showError && (
          <div className={styles.errorSearch}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <ValidItem text={searchError} />
          </div>
        )}
        {showNoResults && (
          <div className={styles.noResult}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <span>No results for "{search}"</span>
            {noResultsAdditionalContent}
          </div>
        )}
        {showNoData && (
          <div className={styles.noData}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <span>{noDataText}</span>
          </div>
        )}
        {showCreateBtn() && (
          <div className={styles.wrapCreateBtn}>
            <Button
              hoverGray
              className={styles.createBtn}
              onClick={handleCreate}
              fullWidth
              disabled={!search}
            >
              <div className={styles.create}>
                <AppIcon
                  color={search ? ColorsNames.blue1 : ColorsNames.blue1Disabled}
                >
                  <PlusNoMask />
                </AppIcon>
                <span
                  className={classNames(
                    search ? 'blue1' : 'blue1Disabled',
                    'bold'
                  )}
                >
                  Create
                </span>
              </div>
              {search && (
                <Tag text={search} leftIcon={'contactsFill'} noneEvents />
              )}
            </Button>
          </div>
        )}
        {!options?.length && emptyText && (
          <p className={classNames(styles.empty, 'par2')}>{emptyText}</p>
        )}
        {BottomDropdownButton && (
          <BottomDropdownButton
            onClose={onClose}
            selectedOption={dropdownSelectedOption}
          />
        )}
      </div>
    </FixedTooltip>
  )
}
