export const appLinks = {
  termsService: 'https://www.callloop.com/legal/terms-conditions',
  tos: 'https://www.callloop.com/legal/terms-of-use',
  privacy: 'https://www.callloop.com/legal/privacy-policy',
  antiSpam: 'https://www.callloop.com/legal/anti-spam-policy',
  callLoop: 'https://www.callloop.com',
  callLoopSupport: 'https://help.callloop.com',
  callLoopCompliance:
    'https://help.callloop.com/en/collections/5748988-compliance',
  a2p10dlcCompliance:
    'https://help.callloop.com/en/articles/7062642-what-is-a2p-10dlc-compliance',
  a2p10dlcRegistration:
    'https://help.callloop.com/en/articles/7904455-a2p-10dlc-registration#h_f30beea3ba',
  tollFreeVerification:
    'https://help.callloop.com/en/articles/7061964-what-is-toll-free-verification',
  //google
  googleTermsService: 'https://policies.google.com/terms',
  googlePrivacy: 'https://policies.google.com/privacy',
  //integrations
  activeCampaignDocs:
    'https://help.callloop.com/en/articles/7062104-activecampaign-app',
  hubSpotDocs:
    'https://help.callloop.com/en/articles/7062101-hubspot-integration',
  webhookDocs:
    'https://help.callloop.com/en/articles/9114439-add-contacts-using-webhooks',
  zapierDocs: 'https://help.callloop.com/en/articles/8708594-new-zapier-app ',
  keapDocs:
    'https://help.callloop.com/en/articles/7062112-keap-infusionsoft-app ',

  smsErrorCodes:
    'https://help.callloop.com/en/articles/7060820-sms-error-codes',
  voiceErrorCodes:
    'https://help.callloop.com/en/articles/8208997-voice-results-definitions',
  ringlessErrorCodes:
    'https://help.callloop.com/en/articles/7976172-ringless-voicemail-errors',
  tollFreeErrorCodes:
    'https://help.callloop.com/en/articles/7062039-why-was-my-toll-free-verification-rejected',
  tollFreeVerificationRegistration:
    'https://help.callloop.com/en/articles/7030813-toll-free-verification-registration#h_628b65f7c4',
  tollFreeVerificationRegistrationOptIn:
    'https://help.callloop.com/en/articles/7030813-toll-free-verification-registration#h_344d8583f0',
  tollFreeVerificationRegistrationSamples:
    'https://help.callloop.com/en/articles/7030813-toll-free-verification-registration#h_388dd972e7',
  smsOptInGuideViaTextKeywordsQrCodes:
    'https://help.callloop.com/en/articles/7062585-sms-opt-in-guide-via-text-keywords-qr-codes',
  smsOptInGuideWebForms:
    'https://help.callloop.com/en/articles/7062575-sms-opt-in-guide-web-forms',
  smsOptInGuidePaperForms:
    'https://help.callloop.com/en/articles/7062321-sms-opt-in-guide-paper-forms',
  termsTollFree: 'https://sti-ga.atis.org/',
  zapier: 'https://zapier.com/',
}
