import styles from './styles.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { IObjective } from 'store/dashboard/objectives/types'
import objectivesStore from 'store/dashboard/objectives/objectivesStore'
import { RoutesEnum } from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from 'components/CircularProgress/CircularProgress'
import { SizedBox } from 'components/SizedBox'
import { LessonCard } from 'src/routes/main/dashboard/Objectives/ActiveObjective/CourseContent/LessonCard/LessonCard'
import { Separator } from 'components/Separator/Separator'
import { ColorsValues } from 'styles/variables'
import { Fragment } from 'react'

export const CourseContent = observer(() => {
  const { step } = useParams()
  const navigate = useNavigate()
  const activeObjective: IObjective | undefined =
    objectivesStore.objectivesMap.get(Number(step))
  if (!activeObjective) {
    navigate(RoutesEnum.dashboard)
    return <></>
  }
  const { lessons, currentLesson } = activeObjective
  const completeLessons = lessons.filter((lesson) => lesson.complete)
  return (
    <div className={styles.wrap}>
      <div className={styles.row}>
        <p className={'bold'}>Course content</p>
        <CircularProgress
          progress={(completeLessons.length / lessons.length) * 100}
        />
      </div>
      <SizedBox height={28} />
      <div>
        {lessons.map((lesson, index) => (
          <Fragment key={lesson.id}>
            <LessonCard
              {...lesson}
              active={index === currentLesson}
              index={index}
              countLessons={lessons.length}
              onClickLesson={() =>
                objectivesStore.changeObjective({
                  ...activeObjective,
                  currentLesson: index,
                })
              }
            />
            {index !== lessons.length - 1 && (
              <Separator color={ColorsValues.gray3} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
})
