import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Link } from 'icons/16pxNoMask/Link.svg'
import { ReactComponent as Duplicate } from 'icons/16pxNoMask/Duplicate.svg'
import { useEffect, useRef, useState } from 'react'

export const CopyBtn = ({
  content,
  linkIcon,
  textBtn = 'Copy',
  className,
  size = BtnSize.small,
  typeBtn = BtnType.secondaryGray,
  menuBtn,
  withCopiedIcon,
  onCopied,
}: {
  content: string
  linkIcon?: boolean
  textBtn?: string
  className?: string
  size?: BtnSize
  typeBtn?: BtnType
  menuBtn?: boolean
  withCopiedIcon?: boolean
  onCopied?: () => void
}) => {
  const [copied, setCopied] = useState(false)
  const [initWidth, setInitWidth] = useState<number | undefined>(undefined)
  const refBtn = useRef<HTMLButtonElement>(null)
  const onClick = () => {
    if (content) {
      navigator.clipboard.writeText(content).then(() => setCopied(true))
      setTimeout(() => {
        setCopied(false)
        onCopied && onCopied()
      }, 2000)
    }
  }
  useEffect(() => {
    if (refBtn.current) {
      setInitWidth(refBtn.current.offsetWidth)
    }
  }, [refBtn])
  return (
    <Button
      size={size}
      typeBtn={typeBtn}
      onClick={onClick}
      className={className}
      menuBtn={menuBtn}
      hoverGray={menuBtn}
      withIcon
      width={initWidth}
      ref={refBtn}
    >
      {copied ? (
        <>
          {withCopiedIcon && (linkIcon ? <Link /> : <Duplicate />)}
          Copied
        </>
      ) : (
        <>
          {linkIcon ? <Link /> : <Duplicate />}
          {textBtn}
        </>
      )}
    </Button>
  )
}
