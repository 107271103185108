import classNames from 'classnames'
import styles from './styles.module.scss'
import { Icon } from 'components/Icon/Icon'
import { useRef, useState } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
import { TooltipContent } from './TooltipContent/TooltipContent'

export const SwitchOrganization = () => {
  const [open, setOpen] = useState(false)
  const disabledCloseOnHover = useRef(false)
  const [timoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const onOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const onMouseEnter = () => {
    if (timoutId) clearTimeout(timoutId)
    setTimeoutId(null)
    onOpen()
  }

  const onMouseLeave = () => {
    if (timoutId) clearTimeout(timoutId)
    const id = setTimeout(() => {
      if (!disabledCloseOnHover.current) {
        onClose()
      }
    }, 1000)
    setTimeoutId(id)
  }

  const onTooltipClick = () => {
    disabledCloseOnHover.current = true
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <MuiTooltip
          title={
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={onTooltipClick}
            >
              <TooltipContent onClose={onClose} />
            </div>
          }
          white
          width={350}
          placement={'right-start'}
          open={open}
        >
          <div
            className={classNames(styles.wrap, { [styles.active]: open })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Icon icon={'userSwitch'} className={styles.icon} />
            <span>Switch organization</span>
            <Icon icon={'angleRight'} />
          </div>
        </MuiTooltip>
      </div>
    </ClickAwayListener>
  )
}
