import styles from './styles.module.scss'
import { ReactComponent as Check } from 'icons/16px/Check.svg'
import { ReactComponent as Info } from 'icons/16px/Info-1.svg'
import { ReactComponent as Alert } from 'icons/16px/Alert.svg'
import { ReactComponent as Tick } from 'icons/20px/NoMask/Tick.svg'
import classNames from 'classnames'

type Props = {
  type?:
    | 'info'
    | 'success'
    | 'warning'
    | 'upgrade'
    | 'infoWarning1'
    | 'infoError1'
}

export const ModalIcon = ({ type = 'success' }: Props) => (
  <div className={classNames(styles.iconWrap, styles[type])}>
    <div className={classNames(styles.icon, styles[type])}>
      {type === 'success' && <Check />}
      {type === 'info' && <Info />}
      {type === 'infoWarning1' && <Info />}
      {type === 'infoError1' && <Info />}
      {type === 'warning' && <Alert />}
      {type === 'upgrade' && <Tick />}
    </div>
  </div>
)
