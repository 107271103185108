import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import { ICardDetail } from 'components/DetailCard/DetailCard'
import { ContentType, MessageStatisticsResponse } from 'src/generated/graphql'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { detailsCards } from 'components/DetailCard/variants'

type MessageStatisticCardsProps = {
  messageStatistic: MessageStatisticsResponse | undefined | null
  type: ContentType
  tableStore: TableStore<IStatisticMessage>
  withToday?: boolean
}

export const MessageStatisticCards = ({
  messageStatistic,
  type,
  tableStore,
  withToday,
}: MessageStatisticCardsProps) => {
  const totalStatistics = messageStatistic?.totalStatistics
  const percentageStatistics = messageStatistic?.percentageStatistics
  const shortUrlClickStatistics = messageStatistic?.shortUrlClickStatistics
  const todayStatistics = messageStatistic?.todayStatistics

  const totalCount = totalStatistics?.totalCount || 0
  const successCount = totalStatistics?.successCount || 0
  const unsubscribedCount = totalStatistics?.unsubscribedCount || 0
  const pendingCount = totalStatistics?.pendingCount || 0
  const failedCount = totalStatistics?.failedCount || 0

  const successPercentage = percentageStatistics?.successPercentage || 0
  const unsubscribedPercentage =
    percentageStatistics?.unsubscribedPercentage || 0
  const failedPercentage = percentageStatistics?.failedPercentage || 0
  const pendingPercentage = percentageStatistics?.pendingPercentage || 0

  const totalCountToday = todayStatistics?.totalCount || 0
  const successCountToday = todayStatistics?.successCount || 0
  const unsubscribedCountToday = todayStatistics?.unsubscribedCount || 0
  const pendingCountToday = todayStatistics?.pendingCount || 0
  const failedCountToday = todayStatistics?.failedCount || 0

  const cardObj = {
    [TableStatuses.totalCount]: detailsCards.total({
      count: totalCount,
      today: withToday ? totalCountToday : undefined,
    }),

    [TableStatuses.successCount]: detailsCards.success({
      count: successCount,
      percent: successPercentage,
      today: withToday ? successCountToday : undefined,
    }),
    [TableStatuses.clicksCount]:
      shortUrlClickStatistics &&
      detailsCards.shortUrlClickStatistics({
        shortUrlClickStatistics,
        card: {
          today: withToday
            ? shortUrlClickStatistics?.totalVisitsToday
            : undefined,
        },
      }),
    [TableStatuses.unsubscribedCount]: detailsCards.unsubscribed({
      count: unsubscribedCount,
      percent: unsubscribedPercentage,
      today: withToday ? unsubscribedCountToday : undefined,
    }),
    [TableStatuses.pendingCount]: detailsCards.pending({
      count: pendingCount,
      percent: pendingPercentage,
      today: withToday ? pendingCountToday : undefined,
    }),
    [TableStatuses.failedCount]: detailsCards.failed({
      count: failedCount,
      percent: failedPercentage,
      today: withToday ? failedCountToday : undefined,
    }),
  }

  const getCards = (): ICardDetail[] => {
    if (type === ContentType.Ringless) {
      return [
        cardObj[TableStatuses.totalCount],
        cardObj[TableStatuses.pendingCount],
        cardObj[TableStatuses.successCount],
        cardObj[TableStatuses.failedCount],
      ]
    }
    return [
      cardObj[TableStatuses.totalCount],
      cardObj[TableStatuses.pendingCount],
      cardObj[TableStatuses.successCount],
      shortUrlClickStatistics?.shortUrlVisits?.length &&
        cardObj[TableStatuses.clicksCount],
      cardObj[TableStatuses.failedCount],
      cardObj[TableStatuses.unsubscribedCount],
    ].filter((card) => Boolean(card)) as ICardDetail[]
  }

  return <DetailCardsWrapper cards={getCards()} tableStore={tableStore} />
}
