import styles from './style.module.scss'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import Graphic from 'assets/images/Graphic.svg'
import { Icon, IIcon } from 'components/Icon/Icon'
import { ColorsValues } from 'styles/variables'

export type MassMessageItem = {
  title: string
  description: string
  to: string
  icon?: IIcon
}

export const MassMessageCard: FC<MassMessageItem> = ({
  title,
  description,
  icon,
  to,
}) => (
  <Link to={to}>
    <div className={styles.wrap}>
      <div className={styles.icon}>
        {icon && <Icon icon={icon} color={ColorsValues.blue1} fontSize={20} />}
      </div>
      <div>
        <p className={'medium'}>{title}</p>
        <span className={'s2 gray1 text-left'}>{description}</span>
      </div>
      <div className={styles.graphic}>
        <img src={Graphic} alt={'graphic'} />
      </div>
    </div>
  </Link>
)
