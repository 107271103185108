import { HoverRowComponentProps } from 'components/NewTable/components/RowComponent'
import { IAudioItem } from 'src/types/IAudioItem'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import { useAudioFunctions } from 'src/routes/settings/audio/hooks/useAudioFunctions'
import { getActionsByType } from 'src/routes/settings/audio/functions/getActionsByType'

export const HoverRowComponent = ({
  row,
  setHover,
  tr,
}: HoverRowComponentProps<IAudioItem>) => {
  const [open, setOpen] = useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const { getRowBtnContentByAction, getMoreContentByAction } =
    useAudioFunctions({ audioItem: row, setHover, tr })
  const actions = getActionsByType(row.type)

  return useMemo(() => {
    const rowActions = actions.slice(0, 3)
    const moreActions = actions.slice(3)
    return (
      <>
        {rowActions.map((action) => getRowBtnContentByAction(action))}
        {moreActions.length > 1 && (
          <MuiTooltip title={'More'} placement={'top'} arrow>
            <div>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <MuiTooltip
                    title={
                      <div>
                        {moreActions.map((action) =>
                          getMoreContentByAction(action)
                        )}
                      </div>
                    }
                    placement={'bottom-end'}
                    open={open}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    white
                    width={148}
                  >
                    <div>
                      <Button
                        size={BtnSize.small}
                        hoverGray2
                        onClick={() => setOpen(!open)}
                        active={open}
                      >
                        <More />
                      </Button>
                    </div>
                  </MuiTooltip>
                </div>
              </ClickAwayListener>
            </div>
          </MuiTooltip>
        )}
        {moreActions.length === 1 && getRowBtnContentByAction(moreActions[0])}
      </>
    )
  }, [actions])
}
