import {
  CustomField,
  CustomFieldRequestInput,
  CustomFieldType,
  InputMaybe,
  SpecificField,
  StandardField,
} from 'src/generated/graphql'
import { ICustomField } from 'src/types/ICustomField'
import { IOption } from 'src/types/IOption'
import { IIcon } from 'components/Icon/Icon'

export function transformCustomFieldsToBack(
  fields: ICustomField[]
): Array<InputMaybe<CustomFieldRequestInput>> {
  return fields.map((field) => ({
    key: (field.id && field.id.startsWith('custom') && field.id) || undefined,
    name: field.name?.trim(),
    type: field.type || CustomFieldType.Text,
  }))
}

export function transformCustomFieldsFromBack(
  fields: Partial<CustomField | StandardField | SpecificField>[]
): ICustomField[] {
  return fields.map((field) => {
    if (field.__typename === 'CustomField') {
      return {
        idLong: field.id,
        id: field.key || '',
        key: field.key || '',
        type: field.type || undefined,
        name: field.name || '',
        used: !!field.used,
      }
    } else {
      return {
        idLong: 0,
        id: field.key || '',
        key: field.key || '',
        type: undefined,
        name: field.name || '',
        used: false,
      }
    }
  })
}

export function iCustomFieldToOption(
  field: ICustomField
): IOption<ICustomField> {
  return {
    idLong: field.idLong,
    title: field.name,
    value: field.id,
    data: field,
  }
}

const fieldIcons: { [key in CustomFieldType]: IIcon } = {
  [CustomFieldType.Text]: 'text',
  [CustomFieldType.Number]: 'number',
  [CustomFieldType.Url]: 'link',
  [CustomFieldType.Date]: 'calendar',
  [CustomFieldType.DateTime]: 'dateTime',
}

export function iCustomFieldToOptionWithIcon(field: ICustomField): IOption {
  return {
    idLong: field.idLong,
    title: field.name,
    value: field.id,
    data: field,
    leftIcon: field.type && fieldIcons[field.type as CustomFieldType],
  }
}
