// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O9W_XTo_UCZsV5msRR1D{width:fit-content;height:fit-content;line-height:1em;color:var(--primary-color-gray-1)}.O9W_XTo_UCZsV5msRR1D svg{margin:0;height:1em;width:1em;line-height:1em}`, "",{"version":3,"sources":["webpack://./src/components/Icon/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,iCAAA,CAEA,0BACE,QAAA,CACA,UAAA,CACA,SAAA,CACA,eAAA","sourcesContent":[".wrap{\n  width: fit-content;\n  height: fit-content;\n  line-height: 1em;\n  color: var(--primary-color-gray-1);\n\n  svg{\n    margin: 0;\n    height: 1em;\n    width: 1em;\n    line-height: 1em;\n\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `O9W_XTo_UCZsV5msRR1D`
};
export default ___CSS_LOADER_EXPORT___;
