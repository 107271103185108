import { SizedBox } from 'components/SizedBox'
import { useNavigate } from 'react-router-dom'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import user from 'store/user/user'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useCreateOrganizationMutation } from 'src/generated/graphql'
import { RoutesEnum } from 'src/routes/routes'
import { nanoid } from 'nanoid'
import billingStore from 'store/settings/billing/billingStore'
import { useSetMember } from 'src/routes/settings/account/hooks/useSetMember'
import { FooterRegister } from 'src/routes/auth/components/FooterRegister'
import { Token } from '@stripe/stripe-js'
import alertStore from 'store/alertStore'
import mainStore from 'store/mainStore'
import { RangeBar } from 'components/RangeBar/RangeBar'
import { MemberInfo } from 'src/widgets/MemberInfo/MemberInfo'
import { CreditCard } from 'src/widgets/CreditCard/CreditCard'
import { useCreditCard } from 'src/widgets/CreditCard/useCreditCard'
import auth from 'store/auth'

type RegisterMemberProps = {
  companyName: string
  employees: string | null
}

export const RegisterMember = observer(
  ({ companyName, employees }: RegisterMemberProps) => {
    useSetMember()

    const [createOrganizationMutation] = useCreateOrganizationMutation()

    const [loading, setLoading] = useState(false)

    const creditCardProps = useCreditCard({ setLoading })

    const { errorAlert, readyCard, setError, refBtn } = creditCardProps

    const navigate = useNavigate()

    const [info, setInfo] = useState({
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    })

    const onAction = async (token: Token) => {
      const tokenId = token.id
      try {
        const { data } = await createOrganizationMutation({
          variables: {
            tokenId: tokenId,
            input: {
              companyName,
              employees,
            },
          },
        })
        if (data?.createOrganization) {
          if (data.createOrganization?.member) {
            user.setMember(data.createOrganization?.member)
          }
          if (data.createOrganization?.subscription) {
            billingStore.setClSubscription(
              data?.createOrganization.subscription
            )
          }
          auth.setRefetchMainInfo()
          mainStore.updateAnalyticTrigger()
          navigate(RoutesEnum.broadcasts)
        }
      } catch (e) {
        if (e instanceof Error) {
          if (e.message.includes('Your card was declined.')) {
            setError(nanoid())
            alertStore.disabledErrorAlert(e.message)
          }
        }
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    const onContinue = async () => {
      setLoading(true)
      if (refBtn.current) {
        refBtn.current?.click()
      }
    }

    return (
      <>
        <h2>Add your card</h2>
        <SizedBox height={8} />
        <p className={'par2 gray1 medium mb20'}>
          Just one more step from creating your account
        </p>
        <RangeBar progress={100} style2 />

        <SizedBox height={32} />
        {errorAlert}
        <MemberInfo
          info={info}
          setInfo={setInfo}
          disabledFields={['email', 'firstName', 'lastName', 'phone']}
        />
        <SizedBox height={32} />
        <CreditCard
          {...creditCardProps}
          setLoading={setLoading}
          onAction={onAction}
          onlyCreate
        />

        <div className={'mt40 '}>
          <Button
            size={BtnSize.large}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={!readyCard}
            loading={loading}
          >
            Start my free trial
          </Button>
          <SizedBox height={16} />
          <FooterRegister
            text={
              'After your 14-day trial is over, you will be upgraded to the Pro Monthly 500 $25/month. Cancel anytime.'
            }
          />
        </div>
      </>
    )
  }
)
