import { Status } from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ColorsValues } from 'styles/variables'
import { CustomStatuses } from 'components/StatusContent/types'

const backgroundColor: { [key: string]: ColorsValues | undefined } = {
  [Status.Active]: ColorsValues.success3,
  [CustomStatuses.Migrated]: ColorsValues.success3,
  [Status.Invalid]: ColorsValues.error3,
  [Status.Unsubscribed]: ColorsValues.gray2Light,
  [Status.Inactive]: ColorsValues.gray2Light,
  [Status.Archived]: ColorsValues.warning4,
}

export function statusToOptions(
  status: Status,
  label: string
): IOption<Status> {
  return {
    title: <StatusContent status={status} />,
    value: label || status,
    group: 'status',
    backgroundColor: backgroundColor[status],
  }
}
