import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Dropdown } from 'components/Dropdown/Dropdown'
import React, { useEffect, useState } from 'react'
import { Currencies, numberString } from 'src/util/functions'
import { SizedBox } from 'components/SizedBox'
import { PrimaryCardDropdown } from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import { useBuyCreditsMutation } from 'src/generated/graphql'
import {
  AutoRechargeCard,
  iCreditPriceToOption,
} from 'components/AutoRecahrge/AutoRechargeCard'
import { useCardDropdownOptions } from 'src/routes/settings/billing/components/hooks/useCardDropdownOptions'
import { Alert } from 'components/Alert/Alert'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { IOption } from 'src/types/IOption'
import { ICreditPrice } from 'store/settings/billing/type'
import { useSetCreditPrices } from 'src/routes/settings/billing/hooks/useSetCreditPrices'

export const BuyCreditsModal = observer(() => {
  const [selectedValue, onSelectValue] = useCardDropdownOptions()
  const [buyCredits, { loading }] = useBuyCreditsMutation()

  useSetCreditPrices(
    billingStore.clSubscription?.price?.planName || '',
    !billingStore.openByCreditsModal
  )

  const creditPriceOptions: IOption<ICreditPrice>[] =
    billingStore.creditPrices.map((price) => iCreditPriceToOption(price))

  const [creditsOption, setCreditOption] = useState(creditPriceOptions[0])
  const [uiLoading, setUiLoading] = useState(false)

  useEffect(() => {
    if (!creditsOption) {
      setCreditOption(creditPriceOptions[0])
    }
  }, [creditPriceOptions])

  const onClose = () => {
    billingStore.setOpenBuyCreditsModal(false)
  }
  const onNext = async (cardId?: string) => {
    try {
      const res = await buyCredits({
        variables: {
          creditsAmount: Number(creditsOption.value),
          paymentMethodId: cardId || selectedValue,
        },
      })
      if (res.data?.buyCredits) {
        billingStore.setBalances(res.data?.buyCredits)
        billingStore.setOpenBuyCreditsModal(false)
        billingStore.setSuccessBuyCredits(
          creditsOption?.data?.creditsAmount || 0
        )
      }
    } catch (e) {
      billingStore.setUpgradeCardId(cardId || selectedValue)
      if (e instanceof Error) {
        alertStore.disabledErrorAlert(e.message)
      }
      console.error(e)
    } finally {
      setUiLoading(false)
    }
  }
  const onBuy = () => {
    setUiLoading(true)
    if (billingStore.showNewCard) {
      billingStore.setAddActionNewCardCb(onNext)
      billingStore.newCardElement?.click()
    } else {
      onNext()
    }
  }
  return (
    <Modal
      open={billingStore.openByCreditsModal}
      onClose={onClose}
      title={'By credits'}
    >
      <div className={'mediumModal'}>
        {billingStore.upgradedCardId && (
          <>
            <Alert
              title={'Credit card updated!'}
              type={AlertTypeEnum.success3}
            />
            <SizedBox height={32} />
          </>
        )}
        {billingStore.buyCreditsInfo && (
          <>
            <Alert
              title={billingStore.buyCreditsInfo}
              type={AlertTypeEnum.notify2}
            />
            <SizedBox height={32} />
          </>
        )}
        <Dropdown
          className={styles.dropdown}
          labelInput={'Credits'}
          labelInputRight={`${billingStore.clAdditionalCreditPrice}¢/credit`}
          options={creditPriceOptions}
          selectedOption={creditsOption}
          onSelect={(opt) => setCreditOption(opt)}
        />
        <SizedBox height={40} />
        <PrimaryCardDropdown
          selectedValue={selectedValue}
          onSelectValue={onSelectValue}
          onFinish={() => setUiLoading(false)}
        />
        <SizedBox height={24} />
        <AutoRechargeCard fromBuyCredits />
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onBuy}
            disabled={
              billingStore.showNewCard
                ? !billingStore.readyNewCard
                : !selectedValue || selectedValue === billingStore.upgradeCardId
            }
            loading={loading || uiLoading}
          >
            Buy {creditsOption?.title} credits for{' '}
            {numberString({
              val: creditsOption?.data?.cost || 0,
              currency: Currencies.usd,
            })}
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
