import React, { FC, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const draggableStyles = {
  borderRadius: 8,
  background: 'white',
  filter:
    'drop-shadow(0px 0px 2px rgba(83, 102, 137, 0.2)) drop-shadow(0px 12px 16px rgba(83, 102, 137, 0.08)) drop-shadow(0px 10px 40px rgba(83, 102, 137, 0.08))',
}
const staticStyles = {
  filter: 'none',
  borderRadius: undefined,
}

type PropsDragItem = {
  index: number
  id: string
  addTopPosition?: number
}

export const DragItem: FC<PropsDragItem> = ({
  children,
  index,
  id,
  addTopPosition,
}) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        const newProps = { ...provided.draggableProps }
        if (newProps.style) {
          const newStyle = { ...newProps.style }

          if (addTopPosition) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newStyle.left = 0
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newStyle.top = newProps.style.top + addTopPosition
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (provided.draggableProps.style?.position) {
            newProps.style = { ...newStyle, ...draggableStyles }
          } else {
            newProps.style = { ...newStyle, ...staticStyles }
          }
        }

        return (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...newProps}
          >
            {children}
          </div>
        )
      }}
    </Draggable>
  )
}

type Props = {
  elements: Array<{
    id: string
    component: JSX.Element
  }>
  fixed?: boolean
  addTopPosition?: number
  order: string[]
  setOrder: (order: string[]) => void
}

export const DragList: FC<Props> = ({
  elements,
  fixed,
  addTopPosition = 0,
  order,
  setOrder,
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const newOrder = reorder(
      order,
      result.source.index,
      result.destination.index
    )

    setOrder(newOrder)
  }

  return (
    <div ref={setRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {order.map((id, index) => (
                <DragItem
                  key={id}
                  index={index}
                  id={id}
                  addTopPosition={
                    fixed
                      ? -(ref?.getClientRects()[0].top || 0) + addTopPosition
                      : 0
                  }
                >
                  {elements.find((el) => el.id === id)?.component}
                </DragItem>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
