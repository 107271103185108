import * as React from 'react'
import { FC, useState } from 'react'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ReactComponent as Delete } from 'icons/16pxNoMask/Delete.svg'
import styles from './styles.module.scss'
import { ActionsEnum } from 'components/Button/types'
import { AppIcon, IconColor, IIconType } from 'components/AppIcon/AppIcon'
import classNames from 'classnames'

type Props = {
  type: ActionsEnum
  tooltip?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  secondaryGray?: boolean
  active?: boolean
  gray4?: boolean
  confirmDelete?: boolean
  icon?: JSX.Element
  iconType?: IIconType
  iconColor?: IconColor
  comingSoon?: boolean
}

export const SmallBtn: FC<Props> = ({
  type,
  onClick,
  tooltip,
  disabled,
  secondaryGray,
  active,
  icon,
  iconType,
  iconColor,
  gray4,
  confirmDelete,
  comingSoon,
}) => {
  if (type === ActionsEnum.delete) {
    return (
      <DeleteBtn
        onClick={onClick}
        tooltip={tooltip}
        disabled={disabled}
        confirmDelete={confirmDelete}
      />
    )
  }
  if (type === ActionsEnum.delete2) {
    return (
      <DeleteBtn2 onClick={onClick} tooltip={tooltip} disabled={disabled} />
    )
  }
  return (
    <MuiTooltip
      title={comingSoon ? 'Coming soon' : tooltip || type}
      placement={'top'}
      arrow
    >
      <div>
        <Button
          size={BtnSize.small}
          typeBtn={secondaryGray ? BtnType.secondaryGray : undefined}
          hoverGray2={!secondaryGray && !gray4}
          onClick={onClick}
          className={classNames(styles.btnSmall, gray4 && styles.gray4)}
          disabled={disabled || comingSoon}
          active={active}
        >
          {icon || <AppIcon icon={type} type={iconType} color={iconColor} />}
        </Button>
      </div>
    </MuiTooltip>
  )
}

const DeleteBtn: FC<{
  tooltip?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  confirmDelete?: boolean
}> = ({
  onClick = () => undefined,
  tooltip,
  disabled,
  confirmDelete = true,
}) => {
  const [isConfirmDelete, setConfirmDelete] = useState(false)
  const onDelete: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isConfirmDelete || !confirmDelete) {
      onClick && onClick(e)
    } else {
      setConfirmDelete(true)
      setTimeout(() => {
        setConfirmDelete(false)
      }, 3000)
    }
  }
  return (
    <WrapIcon
      tooltip={isConfirmDelete && confirmDelete ? 'Sure?' : tooltip || 'Delete'}
      onClick={onDelete}
      disabled={disabled}
    >
      <Delete />
    </WrapIcon>
  )
}

const DeleteBtn2: FC<{
  tooltip?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}> = ({ onClick = () => undefined, tooltip, disabled }) => {
  return (
    <MuiTooltip title={tooltip || ''} placement={'top'} arrow>
      <Button
        size={BtnSize.small}
        typeBtn={BtnType.secondaryGrayDelete}
        onClick={onClick}
        className={styles.btnSmall}
        disabled={disabled}
      >
        <Delete />
      </Button>
    </MuiTooltip>
  )
}

const WrapIcon: FC<{
  tooltip: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}> = ({ onClick = () => undefined, tooltip, disabled, children }) => (
  <MuiTooltip title={tooltip} placement={'top'} arrow>
    <Button
      size={BtnSize.small}
      hoverGray2
      onClick={onClick}
      className={styles.btnSmall}
      disabled={disabled}
    >
      {children}
    </Button>
  </MuiTooltip>
)
