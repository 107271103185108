import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material'
import { ColorsValues } from 'styles/variables'

type Props = {
  determinateColor?: string
  indeterminateColor?: string
}

export function Loader({
  determinateColor,
  indeterminateColor,
  ...props
}: CircularProgressProps & Props) {
  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            determinateColor ||
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={props.size || 32}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: indeterminateColor || ColorsValues.blue1,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={props.size || 32}
        thickness={4}
        {...props}
      />
    </Box>
  )
}
