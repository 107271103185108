import { observer } from 'mobx-react-lite'
import { RoutesEnum } from 'src/routes/routes'
import { useLocation } from 'react-router-dom'
import styles from './styles.module.scss'
import { ReactComponent as Migration } from 'icons/20px/Migration.svg'
import { Loader } from 'components/Loader/Loader'
import companyStore from 'store/settings/company/companyStore'
import { Status, useCheckMigrationStatusQuery } from 'src/generated/graphql'
import { useEffect, useState } from 'react'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { onOpenIntercomWidget } from 'src/util/intercom'
import { ColorsValues } from 'styles/variables'
import auth from 'store/auth'
import { Separator } from 'components/Separator/Separator'

export const MigrationCard = observer(() => {
  const [processing, setProcessing] = useState(false)
  const [disabledAuthAlert, setDisabledAuthAlert] = useState(false)
  const [idInterval, setIdInterval] = useState<NodeJS.Timeout | null>(null)
  const { data, refetch } = useCheckMigrationStatusQuery({ skip: !processing })
  const { pathname } = useLocation()

  useEffect(() => {
    if (
      auth.isMigration &&
      companyStore.migrationStatus === Status.Finished &&
      !disabledAuthAlert
    ) {
      alertStore.setAlert(
        {
          title: 'Your data has been successfully migrated from Call Loop 1.0',
          type: AlertTypeEnum.success,
        },
        true
      )
    }
  }, [auth.isMigration])

  useEffect(() => {
    if (companyStore.migrationStatus === Status.Pending) {
      setDisabledAuthAlert(true)
      alertStore.setAlert(
        {
          title:
            'Migration of you data is in progress. We’ll notify you when it’s completed.',
          type: AlertTypeEnum.blue3,
        },
        true
      )
      setProcessing(true)
    }
  }, [companyStore.migrationStatus])
  useEffect(() => {
    if (processing) {
      const id = setInterval(() => {
        refetch()
      }, 60000)
      setIdInterval(id)
    }
  }, [processing])

  useEffect(() => {
    if (processing && data?.getMyOrganization?.migrationStatus) {
      companyStore.setMigrationStatus(data?.getMyOrganization?.migrationStatus)
      if (data?.getMyOrganization?.migrationStatus === Status.Finished) {
        alertStore.setAlert(
          {
            title:
              'Your data has been successfully migrated from Call Loop 1.0',
            type: AlertTypeEnum.success,
          },
          true
        )
      }
      if (data?.getMyOrganization?.migrationStatus === Status.Failed) {
        alertStore.setAlert(
          {
            title: 'Your data failed to migrate from Call Loop 1.0',
            type: AlertTypeEnum.error,
            rightContent: (
              <button
                onClick={onOpenIntercomWidget}
                className={'s1 bold white'}
              >
                Contact support
              </button>
            ),
          },
          true
        )
      }
      if (data?.getMyOrganization?.migrationStatus !== Status.Pending) {
        setProcessing(false)
        idInterval && clearInterval(idInterval)
      }
    }
  }, [data?.getMyOrganization?.migrationStatus, processing])

  if (
    pathname.includes(RoutesEnum.admin) ||
    companyStore.migrationStatus !== Status.Pending
  ) {
    return <></>
  }

  return (
    <div>
      <div className={styles.wrap}>
        <Migration />
        <span>Migration</span>
        <Loader
          size={16}
          indeterminateColor={ColorsValues.success1}
          determinateColor={'#B4DFCB'}
        />
      </div>
      <Separator marginBlock={12} />
    </div>
  )
})
