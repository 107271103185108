import { FC, useEffect } from 'react'
import { PostHogProvider } from 'posthog-js/react'
import { isPostHog } from 'src/util/variables'
import { initPostHogFeaturesFlags } from 'src/util/posthog/initPostHogFeaturesFlags'

const options = {
  api_host: 'https://app.posthog.com',
  // segment: analytics, // Pass window.analytics here - NOTE: `window.` is important
  // capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: { password: true },
  },
}

export const AppPostHogProvider: FC = ({ children }) => {
  if (isPostHog) {
    return (
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        {children}
      </PostHogProvider>
    )
  }
  return <>{children}</>
}
