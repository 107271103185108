import styles from './styles.module.scss'
import { FC } from 'react'
import classNames from 'classnames'

type Props = {
  variant2?: boolean
}
export const InputCheckboxGroup: FC<Props> = (props) => {
  return (
    <div
      className={classNames(styles.group, props.variant2 && styles.variant2)}
      {...props}
    />
  )
}
