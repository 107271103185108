import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import alertStore from 'store/alertStore'
import { Alert } from 'components/Alert/Alert'
import { Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import {
  NoCompleteRoutes,
  RoutesEnum,
  UnauthorizedRoutes,
} from 'src/routes/routes'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import billingStore from 'store/settings/billing/billingStore'
import auth from 'store/auth'
import { RejectTollFreeAlert } from 'components/Alert/RejectTollFreeAlert'
import { IntegrationDisconnectAlert } from 'components/Alert/IntegrationDisconnectAlert'
import { CarrierFeeFailureAlert } from 'components/Alert/CarrierFeeFailureAlert'
import { AddCreditCardAlert } from 'components/Alert/AddCreditCardAlert'

export const GlobalAlert = observer(() => {
  const location = useLocation()

  const isBottom = () => {
    if (
      location.pathname.includes(NoCompleteRoutes.register1) ||
      location.pathname.includes(NoCompleteRoutes.register2) ||
      location.pathname.includes(UnauthorizedRoutes.login) ||
      location.pathname.includes(UnauthorizedRoutes.signup) ||
      location.pathname.includes(RoutesEnum.newOrganization)
    ) {
      return true
    }
  }

  return (
    <div
      className={classNames(
        styles.global,
        (alertStore.alert?.fullBottom || isBottom()) && styles.fullBottom
      )}
    >
      {auth.isAssume && !auth.loadingMainInfo && (
        <Alert
          title={"You have assumed another user's account."}
          type={AlertTypeEnum.warning1}
          global
          rightContent={
            <button
              className={styles.close}
              onClick={() => {
                window.close()
              }}
            >
              <span className={'bold'}>Stop Assuming</span>
              <AppIcon color={ColorsNames.black} type={IIconType.stroke}>
                <Close />
              </AppIcon>
            </button>
          }
        />
      )}
      <RejectTollFreeAlert />
      <CarrierFeeFailureAlert />
      <AddCreditCardAlert />
      <IntegrationDisconnectAlert />
      {/*{!!superAdminImportsStore.needsReviewImportsCount && (*/}
      {/*  <Alert*/}
      {/*    title={'You have imports that need review.'}*/}
      {/*    type={AlertTypeEnum.warning1}*/}
      {/*    global*/}
      {/*    rightContent={*/}
      {/*      <Link to={RoutesEnum.adminImports}>*/}
      {/*        <span className={'bold'}>Review imports</span>*/}
      {/*      </Link>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      {billingStore.isNotEnoughCreditsAlert && (
        <Alert
          title={'Not enough credits for testing the message'}
          type={AlertTypeEnum.error}
          global
          rightContent={
            <div className={styles.close}>
              <button
                className={'s1 bold white'}
                onClick={() => billingStore.setOpenBuyCreditsModal(true)}
              >
                Buy credits
              </button>
              <Button
                onClick={() => {
                  billingStore.onCloseNotEnoughCreditsAlert()
                }}
              >
                <AppIcon color={ColorsNames.white} type={IIconType.stroke}>
                  <Close />
                </AppIcon>
              </Button>
            </div>
          }
        />
      )}
      {alertStore.errorAlert && (
        <Alert
          {...alertStore.errorAlert}
          global
          rightContent={
            <div className={styles.close}>
              <Button
                onClick={() => {
                  alertStore.closeErrorAlert()
                }}
              >
                <AppIcon color={ColorsNames.white} type={IIconType.stroke}>
                  <Close />
                </AppIcon>
              </Button>
            </div>
          }
        />
      )}
      {alertStore.downgradeAlert && (
        <Alert
          {...alertStore.downgradeAlert}
          global
          rightContent={
            <div className={styles.close}>
              {alertStore.downgradeAlert.rightContent}
              {billingStore.isPayGoLite && (
                <Button
                  onClick={() => {
                    alertStore.hideDowngradeAlert()
                  }}
                >
                  <AppIcon color={ColorsNames.white} type={IIconType.stroke}>
                    <Close />
                  </AppIcon>
                </Button>
              )}
            </div>
          }
        />
      )}
      {alertStore.cancelSubscriptionAlert && (
        <Alert
          {...alertStore.cancelSubscriptionAlert}
          global
          rightContent={
            <div className={styles.close}>
              {alertStore.cancelSubscriptionAlert.rightContent}
            </div>
          }
        />
      )}
      {alertStore.alert && (
        <Alert
          {...alertStore.alert}
          global
          rightContent={
            <div className={styles.close}>
              <Button
                onClick={() => {
                  alertStore.alert?.onClose && alertStore.alert?.onClose()
                  alertStore.setAlert(null)
                }}
              >
                {alertStore.alert.rightContent}
                <AppIcon
                  color={
                    alertStore.alert.type === AlertTypeEnum.warning1
                      ? ColorsNames.black
                      : ColorsNames.white
                  }
                  type={IIconType.stroke}
                >
                  <Close />
                </AppIcon>
              </Button>
            </div>
          }
        />
      )}
      {alertStore.importContactAlert && (
        <Alert
          {...alertStore.importContactAlert}
          global
          rightContent={
            <div className={styles.close}>
              {alertStore.importContactAlert.rightContent}
              <Button
                onClick={() => {
                  alertStore.importContactAlert &&
                    alertStore.setImportContactAlert(
                      null,
                      alertStore.loadingContactImportIds[
                        alertStore.loadingContactImportIds.length - 1
                      ]
                    )
                }}
              >
                <AppIcon color={ColorsNames.white} type={IIconType.stroke}>
                  <Close />
                </AppIcon>
              </Button>
            </div>
          }
        />
      )}
    </div>
  )
})
