import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import styles from 'src/routes/main/contacts/segments/DetailSegment/components/header/styles.module.scss'
import { BtnSize, Button } from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { Icon } from 'components/Icon/Icon'

export const HeaderWrapper: FC = ({ children }) => {
  const navigate = useNavigate()
  return (
    <div>
      <BreadCrumbs />
      <div className={styles.content}>
        <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
          <Icon icon={'angleLeft'} />
        </Button>

        <div className={styles.row}>{children}</div>
      </div>
    </div>
  )
}
