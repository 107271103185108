import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import { IAudioItem } from 'src/types/IAudioItem'
import { useAudioFunctions } from 'src/routes/settings/audio/hooks/useAudioFunctions'
import { getActionsByType } from 'src/routes/settings/audio/functions/getActionsByType'

export const OneSelectContent = observer(
  ({ audioItem }: { audioItem: IAudioItem }) => {
    const [open, setOpen] = useState(false)
    const handleTooltipClose = () => {
      setOpen(false)
    }
    const { getRowBtnContentByAction, getMoreContentByAction } =
      useAudioFunctions({ audioItem })
    const actions = getActionsByType(audioItem.type)

    return useMemo(() => {
      const rowActions = actions.slice(0, 4)
      const moreActions = actions.slice(4)
      return (
        <>
          {rowActions.map((action) => getRowBtnContentByAction(action, true))}
          {!!moreActions.length && (
            <MuiTooltip title={'More'} placement={'top'} arrow>
              <div>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <MuiTooltip
                      title={
                        <div>
                          {moreActions.map((action) =>
                            getMoreContentByAction(action)
                          )}
                        </div>
                      }
                      placement={'bottom-end'}
                      open={open}
                      onClose={handleTooltipClose}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      white
                      width={148}
                    >
                      <div>
                        <Button
                          size={BtnSize.medium}
                          typeBtn={BtnType.secondaryGray}
                          icon
                          onClick={() => setOpen(!open)}
                          active={open}
                        >
                          <More />
                        </Button>
                      </div>
                    </MuiTooltip>
                  </div>
                </ClickAwayListener>
              </div>
            </MuiTooltip>
          )}
        </>
      )
    }, [actions])
  }
)
