import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Microphone } from 'icons/16px/Microphone.svg'
import { ReactComponent as AngleUp } from 'icons/16px/Angle-Up.svg'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import audioStore from 'store/audioStore/audioStore'
import { ReactComponent as Import } from 'icons/16px/Import.svg'
import * as React from 'react'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { RecordDropdown } from 'src/Modals/AudioModal/RecordDropdown/RecordDropdown'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { Icon } from 'components/Icon/Icon'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'
import { NewLabel } from 'components/NewLabel/NewLabel'

export const NoSelectContent = () => {
  const { showTT, setShowTT, trigger, onTriggerClick, setTrigger } =
    useFixedTooltip()

  const closeDropdown = () => setShowTT(false)
  return (
    <>
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        position={TTPositionEnum.bottomLeft}
      >
        <RecordDropdown closeDropdown={closeDropdown} />
      </FixedTooltip>
      <div className={'row12'}>
        <div ref={setTrigger}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            withIcon
            onClick={onTriggerClick}
          >
            <Microphone />
            Record
            {showTT ? <AngleUp /> : <AngleDown />}
          </Button>
        </div>

        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          withIcon
          onClick={() => audioStore.onOpenUploadModal()}
        >
          <Import />
          Upload
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          withIcon
          onClick={() => createTextToSpeechStore.setOpenModal(true)}
        >
          <Icon icon={'textToSpeech'} />
          Create text to speech
          <NewLabel inverted />
        </Button>
      </div>
    </>
  )
}
