import billingStore from 'store/settings/billing/billingStore'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { numberString } from 'src/util/functions'
import { RangeBar } from 'components/RangeBar/RangeBar'
import { observer } from 'mobx-react-lite'
import { CreditsItem } from 'src/routes/settings/billing/components/CreditsContent/CreditsItem'

export const CreditsProgressBar = observer(() =>
  billingStore.balances ? (
    <MuiTooltip
      title={
        <div>
          <div className={'mb8'}>
            <span className={'white'}>
              {numberString({
                val: billingStore.planCredits,
                maximumFractionDigits: 1,
              })}{' '}
              /{' '}
              {numberString({
                val: billingStore.allCredits,
                maximumFractionDigits: 1,
              })}{' '}
              credits
            </span>
            <br />
            {billingStore.isTrial ? (
              <span className={'s2 gray2'}>
                {billingStore.endTrialDateString}
              </span>
            ) : (
              <span className={'s2 gray2'}>
                Plan - Resets{' '}
                {billingStore.clSubscription?.price?.period?.toLowerCase()}
              </span>
            )}
          </div>
          {!billingStore.isTrial && (
            <CreditsItem
              label={'Rollover'}
              infoText={
                'Unused credits purchased in the prior billing cycle. Will not rollover to next cycle'
              }
              count={billingStore.rolloverCredits}
              mb8
            />
          )}
          {!billingStore.isTrial && (
            <CreditsItem
              label={'Purchased'}
              infoText={'Expire in 12 months from purchase date'}
              count={billingStore.purchasedCredits}
            />
          )}
        </div>
      }
      arrow
      placement={'top'}
    >
      <div>
        <RangeBar
          progress={Math.round(
            (billingStore.currentCredits / billingStore.subscriptionCredits) *
              100
          )}
        />
      </div>
    </MuiTooltip>
  ) : (
    <></>
  )
)
