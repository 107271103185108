import { makeAutoObservable, reaction } from 'mobx'
import { bytesToSize, htmlToText, textToHtml } from 'src/util/functions'
import { nanoid } from 'nanoid'
import user, { IDataCollectionItem } from 'store/user/user'
import mediaStore from 'store/mediaStore'
import { COMPLIANCE_TEXT, SIZE_LIMIT_5_MB } from 'src/static/constants'
import { RoutesEnum } from 'src/routes/routes'
import { count } from 'sms-length'
import {
  Asset,
  ContactCard,
  ContactCardFragment,
  Maybe,
  SmsMessage,
  SmsMessageDetailsFragment,
  SmsMessageInput,
  SmsMessageRequestInput,
} from 'src/generated/graphql'
import { isHttps } from 'src/util/validators'

export interface ITextareaDataCollectionItem extends IDataCollectionItem {
  messageHtml: string
  messageInnerText: string
  attachImagesIds: string[]
  active: boolean
  messageId: string
}

const getinitDateCollectionMap = () =>
  new Map<string, ITextareaDataCollectionItem>(
    user.dataCollectionItems.map((item) => [
      item.key,
      {
        ...item,
        messageHtml: '',
        messageInnerText: '',
        attachImagesIds: [],
        active: false,
        messageId: '',
      },
    ])
  )

export class TextareaStore {
  mediaStore: typeof mediaStore
  user: typeof user
  from: RoutesEnum | undefined
  constructor({
    from,
    compliance,
    complianceText,
  }: {
    from?: RoutesEnum
    compliance?: boolean
    complianceText?: string
  }) {
    this.mediaStore = mediaStore
    this.user = user
    this.dataCollectionMap = getinitDateCollectionMap()
    this.from = from
    this.compliance = compliance ?? false
    if (complianceText) {
      this.complianceText = complianceText
    }
    if (compliance) {
      const messageInfo = count(
        `${this.messageInnerText}${
          this.compliance ? ' ' + this.complianceText : ''
        }`.trim()
      )
      this.messageCountSMS = messageInfo.messages
      this.messageRemainsSMS = messageInfo.remaining
    }

    makeAutoObservable(this)

    reaction(
      () => this.compliance,
      () => {
        const messageInfo = count(
          `${this.messageInnerText}${
            this.compliance ? ' ' + this.complianceText : ''
          }`.trim()
        )
        this.messageCountSMS = messageInfo.messages
        this.messageRemainsSMS = messageInfo.remaining
      }
    )
    reaction(
      () => this.messageRemains,
      (count) => {
        this.errorMMSLength = this.isMMS && count < 0
        this.errorSMSLength = !this.isMMS && this.messageCountSMS > 10
      }
    )
  }
  messageId = nanoid()
  messageHtml = ''
  errorMMSLength = false
  errorSMSLength = false
  isUnVerifyToolFree = false
  messageInnerText = ''
  additionalText = ''
  presentationImageId = ''
  attachImagesIds: string[] = []
  vCards: ContactCardFragment[] = []
  uploadSelectedIds: string[] = []
  triggerMessageUpdate = ''
  triggerMessageUpdateText = ''
  messageCountSMS = 1
  messageRemainsSMS = 160
  activeMediaTab = 0
  compliance = false
  complianceText = COMPLIANCE_TEXT
  dataCollectionMap: Map<string, ITextareaDataCollectionItem> = new Map<
    string,
    ITextareaDataCollectionItem
  >()

  get isError() {
    return (
      (this.isMMS ? this.errorMMSLength : this.errorSMSLength) ||
      this.errorVerifyToolFree
    )
  }

  get errorVerifyToolFree() {
    return (this.isMMS || isHttps(this.messageHtml)) && this.isUnVerifyToolFree
  }

  get text() {
    return this.compliance
      ? htmlToText(this.messageHtml).trimStart()
      : htmlToText(this.messageHtml).trim()
  }

  get smsMessage(): SmsMessage {
    return {
      messageCards: this.vCards?.map((card) => ({
        contactCard: card as ContactCard,
      })),
      assetIds: this.attachImagesIds,
      cardIds: this.cardIds,
      compliance: this.compliance,
      text: this.text,
    }
  }

  get smsMessageInput(): SmsMessageInput {
    return {
      assetIds: this.attachImagesIds,
      cardIds: this.cardIds,
      compliance: this.compliance,
      text: this.text,
    }
  }

  get smsMessageRequestInput(): SmsMessageRequestInput {
    return {
      assetIds: this.attachImagesIds,
      cardIds: this.cardIds,
      compliance: this.compliance,
      text: this.text,
    }
  }

  get messageCount() {
    if (this.isMMS) {
      return `${this.messageInnerText}${
        this.compliance ? this.complianceText : ''
      }`.trim().length
        ? 1
        : 0
    } else {
      return this.messageCountSMS
    }
  }
  get messageRemains() {
    if (this.isMMS) {
      return (
        1600 -
        `${this.messageInnerText}${
          this.compliance ? ' ' + this.complianceText : ''
        }`.trim().length
      )
    } else {
      return this.messageRemainsSMS
    }
  }
  get isMMS() {
    return !!this.attachImages.length || !!this.vCards.length
  }

  get dataCollection() {
    return Array.from(this.dataCollectionMap.values())
  }
  get dataCollectionOrder() {
    return this.dataCollection.map((item) => item.key)
  }

  get attachImages() {
    const images: Asset[] = []
    this.attachImagesIds.forEach((id) => {
      const findImg = this.mediaStore.assetsMap.get(id)
      if (findImg) {
        images.push(findImg)
      }
    })
    return images
  }
  get cardIds() {
    return this.vCards.map((card) => card.id)
  }
  get attachImagesSizeString() {
    let size = 0
    this.attachImagesIds.forEach((id) => {
      const img = this.mediaStore.assetsMap.get(id)
      if (img) {
        size += img.sourceSize
      }
    })
    return bytesToSize(size)
  }
  get selectedUploadSize() {
    let size = 0
    this.uploadSelectedIds.forEach((id) => {
      const img = this.mediaStore.assetsMap.get(id)
      if (img) {
        size += img.sourceSize
      }
    })
    return size
  }
  get selectedUploadSizeString() {
    return bytesToSize(this.selectedUploadSize)
  }
  get disabledAddImages() {
    if (!this.uploadSelectedIds.length || this.uploadSelectedIds.length > 5) {
      return true
    }
    return this.selectedUploadSize > SIZE_LIMIT_5_MB
  }

  setUnVerifyToolFree(value: boolean) {
    this.isUnVerifyToolFree = value
  }

  setTriggerMessageUpdateText = () => {
    this.triggerMessageUpdateText = nanoid()
  }

  setSmsMessage(smsMessage?: Maybe<SmsMessageDetailsFragment>) {
    if (smsMessage) {
      this.setMessageText(
        textToHtml(smsMessage?.text || ''),
        smsMessage?.text || ''
      )
      this.setTriggerMessageUpdateText()

      this.compliance = smsMessage.compliance
      if (smsMessage.messageAssets || smsMessage.assetIds) {
        const assets: Asset[] = []
        if (smsMessage.messageAssets) {
          smsMessage.messageAssets.forEach((item) => {
            if (item?.asset) {
              assets.push(item?.asset)
            }
          })

          mediaStore.setAssets(assets)
          this.attachImagesIds = assets.map((asset) => asset.id)
        } else if (smsMessage.assetIds) {
          this.attachImagesIds = smsMessage.assetIds
        }
      }
      if (smsMessage.messageCards) {
        const vCards: ContactCardFragment[] = []
        smsMessage.messageCards.forEach((smsCard) => {
          if (smsCard?.contactCard) {
            vCards.push(smsCard.contactCard)
          }
        })
        this.vCards = vCards
      }
      const messageInfo = count(
        `${this.messageInnerText}${
          this.compliance ? ' ' + this.complianceText : ''
        }`.trim()
      )
      this.messageCountSMS = messageInfo.messages
      this.messageRemainsSMS = messageInfo.remaining
    }
  }

  setMessageText(html: string, innerText: string) {
    this.messageHtml = html
    this.messageInnerText = innerText
    const messageInfo = count(
      `${this.messageInnerText}${
        this.compliance ? ' ' + this.complianceText : ''
      }`.trim()
    )
    this.messageCountSMS = messageInfo.messages
    this.messageRemainsSMS = messageInfo.remaining
  }

  setAttachImages(ids: string[]) {
    this.attachImagesIds = ids
  }
  removeAttachImages(ids: string[]) {
    this.attachImagesIds = this.attachImagesIds.filter(
      (id) => !ids.includes(id)
    )
    this.uploadSelectedIds = this.uploadSelectedIds.filter(
      (id) => !ids.includes(id)
    )
  }
  onSelectUploadImg(id: string) {
    if (this.uploadSelectedIds.includes(id)) {
      this.uploadSelectedIds = this.uploadSelectedIds.filter(
        (uid) => id !== uid
      )
    } else {
      this.uploadSelectedIds.push(id)
    }
  }
  onSelectUploadImagesAll() {
    this.uploadSelectedIds = this.mediaStore.uploadImagesIds
  }
  onDeselectUploadImagesAll() {
    this.uploadSelectedIds = []
  }
  isCheckedUploadImg(id: string) {
    return this.uploadSelectedIds.includes(id)
  }
  onAddImage() {
    this.attachImagesIds = this.uploadSelectedIds
  }
  setTriggerMessageUpdate() {
    this.triggerMessageUpdate = nanoid()
  }
  setActiveMediaTab(index: number) {
    this.activeMediaTab = index
  }

  activeMedia = false
  activeShortenLink = false
  activeEmoji = false
  activeTemplate = false

  setActiveMedia(state: boolean) {
    if (state) {
      this.uploadSelectedIds = [...this.attachImagesIds]
    }
    this.activeMedia = state
  }
  onClose() {
    this.activeMedia = false
    this.activeShortenLink = false
    this.activeEmoji = false
    this.activeTemplate = false
  }
  onClear() {
    this.messageHtml = ''
    this.attachImagesIds = []
  }
  addVCard(vCard: ContactCardFragment) {
    if (!this.vCards.find((card) => card.id === vCard.id)) {
      this.vCards = [...this.vCards, vCard]
    }
  }
  removeVCard(id: number) {
    this.vCards = this.vCards.filter((vCard) => vCard.id !== id)
  }
  updateDataCollectionItem(key: string, newItem: ITextareaDataCollectionItem) {
    this.dataCollectionMap.set(key, newItem)
  }
  setDataCollection(newDataCollection: ITextareaDataCollectionItem[]) {
    if (!newDataCollection.length) {
      this.dataCollectionMap = getinitDateCollectionMap()
    } else {
      this.dataCollectionMap = new Map(
        newDataCollection.map((item) => [item.key, item])
      )
    }
  }
  setDataCollectionOrder(order: string[]) {
    const fields: ITextareaDataCollectionItem[] = []
    order.forEach((id) => {
      const field = this.dataCollectionMap.get(id)
      if (field) {
        fields.push(field)
      }
    })
    this.dataCollectionMap = new Map(fields.map((filed) => [filed.key, filed]))
  }
  setAdditionalText(text: string) {
    this.additionalText = text
  }
  setCompliance(state: boolean) {
    this.compliance = state
  }
}

export function smsMessageToSmsMessageInput(
  smsMessage: SmsMessageDetailsFragment
): SmsMessageInput {
  return {
    assetIds: smsMessage.assetIds,
    compliance: smsMessage.compliance,
    text: smsMessage.text,
  }
}

export function smsMessageToSmsMessageRequestInput(
  smsMessage: SmsMessage
): SmsMessageRequestInput {
  return {
    assetIds: smsMessage.assetIds,
    compliance: smsMessage.compliance,
    text: smsMessage.text || '',
  }
}
