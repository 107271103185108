import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import numbersStore from 'store/settings/numbers/numbersStore'
import { useSetFullBusinessProfile } from 'src/routes/settings/compliance/BusinessProfile/hooks/useSetFullBusinessProfile'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { UnVerificationTollFreeContent } from 'src/routes/settings/compliance/modals/UnverifiedNumberModal/content/UnVerificationTollFreeContent'
import { RoutesEnum } from 'src/routes/routes'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { ResumeTollFreeContent } from 'src/routes/settings/compliance/modals/UnverifiedNumberModal/content/ResumeTollFreeContent'
import { useSetFullTollFreeProfile } from 'src/routes/settings/compliance/TollFreeVerification/hooks/useSetFullTollFreeProfile'

export const UnverifiedNumberModal = observer(() => {
  const { profile } = tollFreeVerificationStore
  const { loading: loadingBusinessProfile } = useSetFullBusinessProfile(
    !numbersStore.openUnverifiedNumberModal
  )
  const { loading: loadingTollFreeProfile } = useSetFullTollFreeProfile(
    !numbersStore.openUnverifiedNumberModal
  )
  const navigate = useNavigate()
  const onClose = () => {
    numbersStore.setOpenUnverifiedNumberModal(false)
  }

  const onAction = (to: string) => {
    onClose()
    if (uiStore.isBeforeUnload) {
      uiStore.setOpenCancelCreateModal(true)
      uiStore.setNextRoute(to)
    } else {
      uiStore.setNextRoute('')
      navigate(to)
    }
  }
  const onVerify = () => {
    const to =
      RoutesEnum.settingsCompliance + '/' + RoutesEnum.tollFreeVerification
    onAction(to)
  }
  // const onBusiness = () => {
  //   const to = RoutesEnum.settingsCompliance + '/' + RoutesEnum.business
  //   onAction(to)
  // }

  const content = useMemo(() => {
    if (profile) {
      return <ResumeTollFreeContent onClose={onClose} onAction={onVerify} />
    }
    return (
      <UnVerificationTollFreeContent onClose={onClose} onAction={onVerify} />
    )
  }, [
    businessProfileStore.businessProfile,
    profile,
    businessProfileStore.completed,
  ])
  return (
    <Modal
      open={numbersStore.openUnverifiedNumberModal}
      onClose={onClose}
      loading={loadingBusinessProfile || loadingTollFreeProfile}
      withoutHeader={
        businessProfileStore.completed
          ? !profile
          : !businessProfileStore.businessProfile
      }
      minWidth={600}
      title={'Compliance'}
    >
      {content}
    </Modal>
  )
})
