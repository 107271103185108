import { SearchInput } from 'components/Input/InputText/SearchInput'
import styles from './styles.module.scss'
import { useState } from 'react'
import { Button } from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { ColorsValues } from 'styles/variables'
import { NavLink } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import {
  useGetProfileOrganizationsQuery,
  useSelectOrganizationMutation,
} from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import companyStore from 'store/settings/company/companyStore'

export const TooltipContent = observer(
  ({ onClose }: { onClose: () => void }) => {
    const [search, setSearch] = useState('')
    const { data } = useGetProfileOrganizationsQuery({
      variables: {
        search,
      },
    })

    const [selectOrganizationMutation, { loading }] =
      useSelectOrganizationMutation()

    const onSelect = async (id: number) => {
      if (companyStore.id === id) return
      if (loading) return
      try {
        const { data } = await selectOrganizationMutation({
          variables: {
            organizationId: id,
          },
        })
        if (data?.selectOrganization) {
          window.location.reload()
        }
        onClose()
      } catch (e) {
        console.error(e)
      }
    }

    const items = data?.getProfileOrganizations?.map((item) => (
      <Button
        key={item?.id}
        menuBtn
        fullWidth
        hoverGray
        onClick={() => onSelect(item?.id)}
      >
        <div className={styles.btn}>
          <span className={'ellipsisNowrap'}>{item?.companyName}</span>
          {companyStore.id === item?.id && (
            <Icon icon={'check'} color={ColorsValues.blue1} />
          )}
        </div>
      </Button>
    ))
    return (
      <div className={styles.wrap}>
        <div className={styles.wrapInput}>
          <SearchInput value={search} onSearch={setSearch} small />
        </div>
        {items}
        <NavLink to={RoutesEnum.newOrganization}>
          <Button menuBtn fullWidth hoverGray>
            <Icon icon={'plus'} color={ColorsValues.blue1} />
            <span className={'blue1 bold'}>New organization</span>
          </Button>
        </NavLink>
      </div>
    )
  }
)
