import styles from './styles.module.scss'
import classNames from 'classnames'
import { DetailCard, ICardDetail } from 'components/DetailCard/DetailCard'
import { TableStore } from 'components/NewTable/store/TableStore'
import { useEffect, useState } from 'react'
import { RequiredFields } from 'components/NewTable/types'
import { observer } from 'mobx-react-lite'

export const DetailCardsWrapper = observer(
  <T extends RequiredFields<T>>({
    cards,
    tableStore,
    noSelect,
  }: {
    cards: ICardDetail[]
    tableStore?: TableStore<T>
    noSelect?: boolean
  }) => {
    const [invisibleCards, setInvisibleCards] = useState<ICardDetail[]>([])

    const [renderCards, setRenderCards] = useState(cards)

    const handleSelectCard = (card: ICardDetail) => {
      !noSelect &&
        card.status &&
        tableStore &&
        tableStore.setStatus(card.status)
    }

    const handleSelectInvisibleCard = (card: ICardDetail) => {
      const newCards = renderCards.filter((item) => item.value !== card.value)
      newCards.splice(4, 0, card)
      setRenderCards([...newCards])
      handleSelectCard(card)
    }

    useEffect(() => {
      setRenderCards(cards)
    }, [cards])
    return (
      <div
        className={classNames(
          styles.wrap,
          styles[`size${renderCards.length > 5 ? 5 : renderCards.length}`]
        )}
      >
        {renderCards.map((card, index) => (
          <DetailCard
            key={card.value}
            card={card}
            checked={!!tableStore && card.status === tableStore?.status}
            noSelect={noSelect}
            setInvisibleCards={setInvisibleCards}
            handleSelectInvisibleCard={handleSelectInvisibleCard}
            isLastVisible={index === cards.length - 1 - invisibleCards.length}
            invisibleCards={invisibleCards}
            handleSelectCard={() => handleSelectCard(card)}
          />
        ))}
      </div>
    )
  }
)
