import { FC } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Stats } from 'icons/32px/Stats.svg'
import classNames from 'classnames'

type Props = {
  title?: string
  description: string
  height?: string
  width?: string
  className?: string
  icon?: JSX.Element
}

export const ComingSoon: FC<Props> = ({
  title = 'Coming soon',
  description,
  children,
  height,
  icon,
  width,
  className,
}) => {
  return (
    <div
      className={classNames(styles.wrap, className)}
      style={{ height, width }}
    >
      <div className={styles.wrapCard} style={{ height }}>
        <div className={styles.card}>
          {icon ?? <Stats />}
          <h3 className={'bold'}>{title}</h3>
          <p className={'par2 gray1'}>{description}</p>
        </div>
      </div>

      <div className={styles.wrapChild}>{children}</div>
    </div>
  )
}
