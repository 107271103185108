import styles from './styles.module.scss'
import classNames from 'classnames'
import { ReactComponent as Star } from 'icons/12px/Star.svg'
import billingStore from 'store/settings/billing/billingStore'
import { observer } from 'mobx-react-lite'
import { numberString } from 'src/util/functions'
import { CreditsProgressBar } from 'src/routes/settings/billing/components/CreditsContent/CreditsProgressBar'
import alertStore from 'store/alertStore'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useSetBalance } from 'src/routes/settings/billing/hooks/useSetBalance'
import {
  useCancelDowngradeMutation,
  useStopCancelSubscriptionMutation,
} from 'src/generated/graphql'
import { useLocation } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import user from 'store/user/user'

export const Plan = observer(() => {
  const { pathname } = useLocation()
  useSetBalance()
  const [cancelDowngradeSubscription] = useCancelDowngradeMutation()
  const [stopCancelSubscription] = useStopCancelSubscriptionMutation()
  const handleCancelDowngrade = async () => {
    try {
      const { data } = await cancelDowngradeSubscription()
      if (data?.cancelDowngrade) {
        billingStore.setClSubscription(data?.cancelDowngrade)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleStopCancelSubscription = async () => {
    try {
      const { data } = await stopCancelSubscription()
      if (data?.stopCancelSubscription) {
        billingStore.setClSubscription(data?.stopCancelSubscription)
      }
    } catch (e) {
      console.error(e)
    }
  }
  if (pathname.includes(RoutesEnum.admin)) {
    return <></>
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.row}>
        <span className={'bold'}>{billingStore.clSubscriptionTitle || ''}</span>
        {user.isOwner && (
          <>
            {billingStore.isCancelSubscription ? (
              <button
                onClick={handleStopCancelSubscription}
                className={'s2 bold link'}
              >
                Resubscribe
              </button>
            ) : billingStore.isPausedSubscription ? (
              <button
                onClick={() => {
                  alertStore.setAlert(null)
                  billingStore.setIsPausedSubscription(false)
                }}
                className={'s2 bold link'}
              >
                Cancel
              </button>
            ) : billingStore.clSubscription?.nextPlan ? (
              !billingStore.isPayGoLite && (
                <button
                  onClick={handleCancelDowngrade}
                  className={'s2 bold link'}
                >
                  Cancel
                </button>
              )
            ) : (
              <button
                onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
                className={'s2 bold link'}
                id={'card_plan_upgrade'}
              >
                Upgrade
              </button>
            )}
          </>
        )}
      </div>
      {billingStore.isTrial &&
        billingStore.leftDaysTrialling <= 5 &&
        !billingStore.isCancelSubscription && (
          <span className={classNames('s2', styles.ends)}>
            {billingStore.endTrialDateString}
          </span>
        )}
      {billingStore.isCancelSubscription && (
        <span className={classNames('s2', styles.ends)}>
          Ends on {billingStore.cancelAtString}
        </span>
      )}
      {billingStore.isPausedSubscription && (
        <span className={classNames('s2', styles.ends)}>
          Pause starts on Jan 1, 2021
        </span>
      )}
      {billingStore.clSubscription?.nextPlan && (
        <span
          className={classNames(
            's2',
            billingStore.isPayGoLite ? styles.ups : styles.ends
          )}
        >
          {billingStore.isPayGoLite ? 'Upgrades' : 'Downgrades'} on{' '}
          {billingStore.downgradeDate}
        </span>
      )}
      <div className={styles.wrapBar}>
        <CreditsProgressBar />
      </div>
      <div className={styles.row}>
        <div className={styles.row}>
          <div className={styles.starWrap}>
            <Star />
          </div>
          <span className={'s2'}>
            {numberString({
              val: billingStore.currentCredits,
              maximumFractionDigits: 2,
            })}{' '}
            credits
          </span>
        </div>
        {billingStore.isTrial ? (
          <span className={'s2 gray1'}>
            {billingStore.leftDaysTriallingString}
          </span>
        ) : (
          user.isOwner && (
            <button
              onClick={() => billingStore.setOpenBuyCreditsModal(true)}
              className={'s2 bold link'}
            >
              Buy
            </button>
          )
        )}
      </div>
    </div>
  )
})
