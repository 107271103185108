import styles from './styles.module.scss'
import { ReactComponent as Link12 } from 'icons/12px/Link.svg'
import { FixedTooltip } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import React, { useState } from 'react'
import classNames from 'classnames'
import { Loader } from 'components/Loader/Loader'
import { ColorsValues } from 'styles/variables'

type ShortenLinkTooltipProps = {
  currentHoverLink: HTMLAnchorElement | undefined
  onClickShortenLink: () => Promise<void>
  refHoverLink: React.MutableRefObject<boolean>
  setCurrentHoverLink: React.Dispatch<
    React.SetStateAction<HTMLAnchorElement | undefined>
  >
}

export const ShortenLinkTooltip = ({
  currentHoverLink,
  onClickShortenLink,
  refHoverLink,
  setCurrentHoverLink,
}: ShortenLinkTooltipProps) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    // if (loading.current) return
    // loading.current = true
    await onClickShortenLink()
    setLoading(false)
    // loading.current = false
  }
  return (
    <FixedTooltip
      visible={!!currentHoverLink || loading}
      trigger={currentHoverLink}
    >
      <div
        className={classNames(styles.linkBtn, loading && styles.loading)}
        onClick={handleClick}
        aria-hidden={true}
        onMouseEnter={() => (refHoverLink.current = true)}
        onMouseLeave={() => {
          if (!loading) {
            refHoverLink.current = false
            setCurrentHoverLink(undefined)
          }
        }}
      >
        {loading ? (
          <Loader
            size={12}
            indeterminateColor={ColorsValues.white}
            determinateColor={ColorsValues.gray1}
          />
        ) : (
          <Link12 />
        )}
        Shorten link
      </div>
    </FixedTooltip>
  )
}
