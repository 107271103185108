import { makeAutoObservable, runInAction } from 'mobx'
import { IMessageTemplate } from 'src/widgets/MessageTemplate'
import {
  Direction,
  GetMessageTemplatesQuery,
  UpdateMessageTemplateRequestInput,
} from 'src/generated/graphql'
import { MessageTemplateApi } from 'src/api/messageTemplate/api'

class MessageTemplateStore {
  constructor() {
    makeAutoObservable(this)
  }

  open = false

  myTemplatesMap: Map<number, IMessageTemplate> = new Map()

  isCreateTemplate = false
  editTemplate: IMessageTemplate | null = null

  loading = false
  search = ''
  page = 0
  total = 0
  pageSize = 20

  get myTemplates() {
    return Array.from(this.myTemplatesMap.values())
  }

  setSearch = (search: string) => {
    this.search = search
    this.reset()
    this.loadData()
  }

  createTemplate = async (template: UpdateMessageTemplateRequestInput) => {
    try {
      const { data } = await MessageTemplateApi.create({
        template,
      })
      if (data?.createMessageTemplate) {
        this.addMyTemplate(data.createMessageTemplate, true)
        this.onCloseCreateMode()
        this.total = this.total + 1
      }
    } catch (e) {
      console.error(e)
    }
  }
  updateTemplate = async (template: UpdateMessageTemplateRequestInput) => {
    try {
      const { data } = await MessageTemplateApi.update({
        template,
      })
      if (data?.updateMessageTemplate) {
        this.addMyTemplate(data.updateMessageTemplate, true)
        this.onCloseCreateMode()
      }
    } catch (e) {
      console.error(e)
    }
  }

  addMyTemplate = (template: IMessageTemplate, toStart?: boolean) => {
    this.myTemplatesMap.set(template.id, template)
  }
  editeMyTemplate = (template: IMessageTemplate) => {
    this.editTemplate = template
  }
  deleteMyTemplate = async (id: number) => {
    try {
      await MessageTemplateApi.delete({
        templateIds: [id],
      })
      this.myTemplatesMap.delete(id)
      this.total = this.total - 1
    } catch (e) {
      console.error(e)
    }
  }

  setIsCreateTemplate = (state: boolean) => {
    this.isCreateTemplate = state
  }

  onCloseCreateMode = () => {
    this.isCreateTemplate = false
    this.editTemplate = null
  }

  private setData = (data: GetMessageTemplatesQuery) => {
    if (data.getMessageTemplates) {
      this.total = data.getMessageTemplates.total
      data.getMessageTemplates.content?.forEach((item) => {
        if (item) {
          this.addMyTemplate(item)
        }
      })
    }
  }

  loadData = async (props?: { ignoreCash?: boolean }) => {
    if (this.loading) return
    this.loading = true
    try {
      const { data } = await MessageTemplateApi.getList(
        {
          page: {
            pageNumber: this.page,
            pageSize: this.pageSize,
            sort: {
              orders: [
                {
                  property: 'updatedAt',
                  direction: Direction.Desc,
                },
              ],
            },
          },
          searchPattern: this.search,
        },
        props?.ignoreCash ? 'network-only' : undefined
      )
      this.setData(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  loadMore = () => {
    if (this.total <= this.myTemplatesMap.size) {
      return
    }
    this.page += 1
    this.loadData()
  }

  reset = () => {
    this.page = 0
    this.total = 0
    this.myTemplatesMap.clear()
  }

  onOpen = () => {
    this.open = true
  }

  onClose = () => {
    this.open = false
    this.reset()
    this.search = ''
    this.isCreateTemplate = false
    this.editTemplate = null
  }
}

export default new MessageTemplateStore()
