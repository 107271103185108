import { FC, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import uiStore from 'store/uiStore'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

type Props = {
  center?: boolean
  className?: string
  white?: boolean
  loading?: boolean
}

export const Page: FC<Props> = observer(
  ({ center, className, white, children, ...props }) => {
    const ref = useRef<HTMLDivElement>(null)
    const { pathname } = useLocation()
    const loading = pathname === uiStore.loadingRoute
    useEffect(() => {
      if (ref.current) {
        uiStore.setCurrentPage(ref.current)
      }
    }, [ref])
    return (
      <div
        ref={ref}
        {...props}
        className={classNames(
          styles.wrap,
          center && styles.center,
          white && styles.white,
          className
        )}
      >
        {loading && <AbsoluteLoader size={40} />}
        <div className={'main-container'}>
          <div
            style={{ display: loading ? 'none' : undefined }}
            className={styles.child}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }
)
