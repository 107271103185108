import styles from './styles.module.scss'
import { Currencies, numberString } from 'src/util/functions'
import { Switch } from 'components/Switch/Switch'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { BalanceFallsOptions } from 'store/settings/billing/hellpers'
import { observer } from 'mobx-react-lite'
import {
  AutoRechargeThreshold,
  SimplePrice,
  useGetPricesQuery,
  useSetupAutoRechargeMutation,
} from 'src/generated/graphql'
import classNames from 'classnames'
import billingStore from 'store/settings/billing/billingStore'
import { DisabledAutoRechargeModal } from 'src/routes/settings/billing/modals/DisabledAutoRechargeModal/DisabledAutoRechargeModal'
import { IOption } from 'src/types/IOption'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useEffect } from 'react'
import { ICreditPrice } from 'store/settings/billing/type'
import { useSetCreditPrices } from 'src/routes/settings/billing/hooks/useSetCreditPrices'
import migrationStore from 'store/migration/migrationStore'

export const iCreditPriceToOption = (
  price: ICreditPrice,
  fromMigration?: boolean
): IOption => ({
  value: price?.creditsAmount,
  title: numberString({ val: price?.creditsAmount || 0 }),
  rightContent: price.allowed ? (
    <span className={'gray1'}>
      {numberString({ val: price?.cost || 0, currency: Currencies.usd })}
    </span>
  ) : (
    <button className={'s1 gray1 nowrap'}>Upgrade plan</button>
  ),
  onClick:
    price.allowed || fromMigration
      ? undefined
      : () => {
          upgradePlanStore.setOpenUpgradePlanModal(true)
        },

  data: price,
})

type Props = {
  planName?: string
  fromBillingPage?: boolean
  fromUpgrade?: boolean
  fromBuyCredits?: boolean
  mt?: number
}

export const AutoRechargeCard = observer(
  ({
    fromBillingPage,
    fromUpgrade,
    fromBuyCredits,
    mt,
    planName: planNameProps,
  }: Props) => {
    const [setupAutoRecharge] = useSetupAutoRechargeMutation()
    const fromMigration = migrationStore.fromMigration

    const planName =
      planNameProps || billingStore.clSubscription?.price?.planName || ''

    const creditPrices = billingStore.creditPricesMap.get(planName) || []

    const { data } = useGetPricesQuery({
      skip: !!billingStore.prices.length || fromMigration,
    })
    useEffect(() => {
      if (data?.getPrices) {
        const filtered: SimplePrice[] = []
        data.getPrices?.forEach((price) => {
          if (price) {
            filtered.push(price)
          }
        })
        upgradePlanStore.setPrices(filtered)
      }
    }, [data])

    const isHide =
      billingStore.autoRecharge &&
      ((fromUpgrade &&
        billingStore.disableHideAutoRechargeFor !== 'fromUpgrade') ||
        (fromBuyCredits &&
          billingStore.disableHideAutoRechargeFor !== 'fromBuyCredits'))

    useSetCreditPrices(planName, isHide)

    if (isHide) {
      return <></>
    }

    const creditPriceOptions: IOption[] = creditPrices.map((price) =>
      iCreditPriceToOption(price, fromMigration)
    )
    const activeCreditPrice = creditPrices.find(
      (price) => price.creditsAmount === billingStore.autoRechargeCreditsAmount
    )
    const selectedCreditPriceOption = activeCreditPrice
      ? iCreditPriceToOption(activeCreditPrice, fromMigration)
      : undefined

    const initialSetup = fromMigration || fromUpgrade ? true : undefined

    const handleSetActiveCreditPrice = async (option: IOption) => {
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: billingStore.autoRecharge,
              creditsAmount: option.value,
              threshold: billingStore.autoRechargeThreshold,
              initialSetup,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
      } catch (e) {
        console.error(e)
      }
    }
    const setActiveAutoRecharge = async () => {
      if (fromUpgrade) {
        billingStore.setDisableHideAutoRechargeFor('fromUpgrade')
      }
      if (fromBuyCredits) {
        billingStore.setDisableHideAutoRechargeFor('fromBuyCredits')
      }
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: !billingStore.autoRecharge,
              creditsAmount: 500,
              threshold: AutoRechargeThreshold.Credits100,
              initialSetup,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
      } catch (e) {
        console.error(e)
      }
    }

    const handleAutoRecharge = async () => {
      if (billingStore.autoRecharge) {
        billingStore.setOpenDisabledAutoRechargeModal(true)
      } else {
        await setActiveAutoRecharge()
      }
    }

    const setFallsOption = async (opt: IOption) => {
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: billingStore.autoRecharge,
              creditsAmount: 500,
              threshold: opt.value,
              initialSetup,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <div
        className={classNames(
          styles.autoRecharge,
          fromBillingPage && styles.onlyContent
        )}
        style={{ marginTop: mt }}
      >
        <DisabledAutoRechargeModal
          setActiveAutoRecharge={setActiveAutoRecharge}
        />
        <div className={styles.row}>
          <div>
            <span className={'bold'}>Auto-recharge</span>
            <div className={styles.wrapAutoText}>
              <span className={'s2 gray1'}>
                {billingStore.autoRecharge ? (
                  <>
                    Automatically add{' '}
                    {numberString({
                      val: Number(activeCreditPrice?.creditsAmount),
                    })}{' '}
                    credits (
                    {numberString({
                      val: Number(activeCreditPrice?.cost),
                      currency: Currencies.usd,
                    })}
                    ) to your account when your <br /> balance goes below{' '}
                    {numberString({
                      val: parseInt(
                        String(billingStore.autoRechargeThresholdOption.title)
                      ),
                    })}{' '}
                    credits.
                  </>
                ) : (
                  'Enable auto-recharge to ensure that you always have enough credits on your account'
                )}
              </span>
            </div>
          </div>
          <Switch
            active={billingStore.autoRecharge}
            onChange={handleAutoRecharge}
          />
        </div>
        {billingStore.autoRecharge && (
          <div className={styles.rowGrid}>
            <Dropdown
              labelInput={'If Balance Falls Bellow'}
              options={BalanceFallsOptions}
              selectedOption={billingStore.autoRechargeThresholdOption}
              onSelect={setFallsOption}
            />
            <Dropdown
              labelInput={'Recharge My Company'}
              options={creditPriceOptions}
              selectedOption={selectedCreditPriceOption}
              onSelect={handleSetActiveCreditPrice}
            />
          </div>
        )}
      </div>
    )
  }
)
