import styles from './styles.module.scss'
import { ColorsNames } from 'styles/variables'
import { FC } from 'react'
import classNames from 'classnames'
import { ReactComponent as Edit } from 'icons/16pxNoMask/Edit.svg'
import { ReactComponent as TestSMS } from 'icons/16pxNoMask/Test-it SMS.svg'
import { ReactComponent as TestAudio } from 'icons/16pxNoMask/Test-it Audio.svg'
import { ReactComponent as EditLists } from 'icons/16pxNoMask/Edit lists.svg'
import { ReactComponent as Unarchive } from 'icons/16pxNoMask/Unarchive.svg'
import { ReactComponent as Archive } from 'icons/16pxNoMask/Archive.svg'
import { ReactComponent as ContactDelete } from 'icons/16pxNoMask/Contact Delete.svg'
import { ReactComponent as ContactsAdd } from 'icons/16pxNoMask/Contacts Add.svg'
import { ReactComponent as Export } from 'icons/16pxNoMask/Export.svg'
import { ReactComponent as Delete } from 'icons/16pxNoMask/Delete.svg'
import { ReactComponent as StatsView } from 'icons/16pxNoMask/StatsView.svg'
import { ReactComponent as Stop } from 'icons/16pxNoMask/Stop.svg'
import { ReactComponent as Start } from 'icons/16pxNoMask/Start.svg'
import { ReactComponent as Download } from 'icons/16pxNoMask/Download.svg'
import { ReactComponent as MarkValid } from 'icons/16pxNoMask/Mark Valid.svg'
import { ReactComponent as Link } from 'icons/16pxNoMask/Link.svg'
import { ReactComponent as Duplicate } from 'icons/16pxNoMask/Duplicate.svg'
import { ReactComponent as WebFormIcon } from 'icons/16pxNoMask/Web Form.svg'
import { ReactComponent as Code } from 'icons/16pxNoMask/Code.svg'
import { ReactComponent as Settings } from 'icons/16pxNoMask/Settings.svg'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { ReactComponent as Earth } from 'icons/16pxNoMask/earth.svg'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import { ReactComponent as Eye } from 'icons/16pxNoMask/Eye.svg'
import { ReactComponent as Running } from 'icons/16pxNoMask/Status/Running.svg'
import { ReactComponent as Refresh } from 'icons/16pxNoMask/Refresh.svg'
import { ReactComponent as Reload } from 'icons/16pxNoMask/Reload.svg'
import { ReactComponent as Unassigned } from 'icons/16pxNoMask/Status/Unassigned.svg'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { ReactComponent as CloseNew } from 'icons/Close.svg'
import { ReactComponent as Check } from 'icons/16pxNoMask/Check.svg'
import { ReactComponent as ValidatePhone } from 'icons/16pxNoMask/ValidatePhone.svg'
import { ReactComponent as Search } from 'icons/16pxNoMask/Search.svg'
import { ReactComponent as Sent } from 'icons/16pxNoMask/Status/Sent.svg'
import { ReactComponent as Arrows } from 'icons/16pxNoMask/Arrows.svg'
import { ReactComponent as Disabled } from 'icons/16pxNoMask/Disabled.svg'
import { ReactComponent as Minus } from 'icons/16pxNoMask/Status/Minus.svg'
import { ActionsEnum } from 'components/Button/types'

export enum ImportIcons {
  more = 'more',
  phone = 'phone',
  link = 'link',
  earth = 'earth',
  plus = 'plus',
  closeNew = 'closeNew',
}

const icons = {
  [ActionsEnum.edit]: <Edit />,
  [ActionsEnum.rename]: <Edit />,
  [ActionsEnum.start]: <Start />,
  [ActionsEnum.resume]: <Start />,
  [ActionsEnum.editLists]: <EditLists />,
  [ActionsEnum.unarchive]: <Unarchive />,
  [ActionsEnum.restore]: <Unarchive />,
  [ActionsEnum.archive]: <Archive />,
  [ActionsEnum.unsubscribe]: <ContactDelete />,
  [ActionsEnum.resubscribe]: <ContactsAdd />,
  [ActionsEnum.export]: <Export />,
  [ActionsEnum.delete]: <Delete />,
  [ActionsEnum.delete2]: <Delete />,
  [ActionsEnum.analytics]: <StatsView />,
  [ActionsEnum.stop]: <Stop />,
  [ActionsEnum.download]: <Download />,
  [ActionsEnum.markValid]: <MarkValid />,
  [ActionsEnum.copy]: <Link />,
  [ActionsEnum.duplicate]: <Duplicate />,
  [ActionsEnum.testWebWorm]: <WebFormIcon />,
  [ActionsEnum.testMessage]: <TestSMS />,
  [ActionsEnum.testAudio]: <TestAudio />,
  [ActionsEnum.getCode]: <Code />,
  [ActionsEnum.setting]: <Settings />,
  [ActionsEnum.details]: <Eye />,
  [ActionsEnum.pause]: <Running />,
  [ActionsEnum.refresh]: <Refresh />,
  [ActionsEnum.reload]: <Reload />,
  [ActionsEnum.ban]: <Stop />,
  [ActionsEnum.unblock]: <Start />,
  [ActionsEnum.close]: <Close />,
  [ActionsEnum.check]: <Check />,
  [ActionsEnum.validatePhone]: <ValidatePhone />,
  [ActionsEnum.search]: <Search />,
  [ActionsEnum.sent]: <Sent />,
  [ActionsEnum.forward]: <Arrows />,
  [ActionsEnum.disabled]: <Disabled />,
  [ActionsEnum.switch]: <Disabled />,
  [ActionsEnum.turnOn]: <Sent />,
  [ActionsEnum.turnOff]: <Minus />,

  [ActionsEnum.more]: <More />,
  [ActionsEnum.addCredits]: <Unassigned />,
  [ActionsEnum.addCallerId]: <Phone />,
  [ImportIcons.more]: <More />,
  [ImportIcons.phone]: <Phone />,
  [ImportIcons.link]: <Link />,
  [ImportIcons.earth]: <Earth />,
  [ImportIcons.plus]: <Plus />,
  [ImportIcons.closeNew]: <CloseNew />,
}

export enum IIconType {
  fill = 'fill',
  stroke = 'stroke',
  strokeFill = 'strokeFill',
  circle = 'circle',
}

type Props = {
  color?:
    | ColorsNames.gray1
    | ColorsNames.gray2
    | ColorsNames.white
    | ColorsNames.black
    | ColorsNames.purple1
    | ColorsNames.blue1
    | ColorsNames.blue2
    | ColorsNames.blue3
    | ColorsNames.blue4
    | ColorsNames.blue1Disabled
    | ColorsNames.orange1
    | ColorsNames.error1
    | ColorsNames.success1
    | ColorsNames.warning2
    | ColorsNames.aqua1
  type?: IIconType
  icon?: ActionsEnum | ImportIcons
  className?: string
}

export type IconColor = Props['color']

export const AppIcon: FC<Props> = ({
  color = ColorsNames.gray1,
  type = IIconType.fill,
  icon,
  className,
  children,
}) => (
  <span className={classNames(styles[color], styles[type], className)}>
    {icon && icons[icon]}
    {children}
  </span>
)
