// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oPNahL5_8Cdh7JFR0prg{margin-left:32px;margin-top:40px;display:flex;justify-content:space-between}.oPNahL5_8Cdh7JFR0prg .oe1132YS0rwRT0rQOA0c{display:flex;column-gap:12px}.Ez2A3hLplokmTAVyCEOF{padding:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/campaigns/create/createForm/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,eAAA,CACA,YAAA,CACA,6BAAA,CACA,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".actionsWrap{\n  margin-left: 32px;\n  margin-top: 40px;\n  display: flex;\n  justify-content: space-between;\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsWrap": `oPNahL5_8Cdh7JFR0prg`,
	"left": `oe1132YS0rwRT0rQOA0c`,
	"menuWrap": `Ez2A3hLplokmTAVyCEOF`
};
export default ___CSS_LOADER_EXPORT___;
