import styles from './styels.module.scss'
import classNames from 'classnames'

type Props = {
  progress: number
  style2?: boolean
}

export const RangeBar = ({ progress, style2 }: Props) => (
  <div className={classNames(styles.range, style2 && styles.style2)}>
    <div
      className={styles.bar}
      style={{ width: progress > 100 ? '100%' : `${progress}%` }}
    />
  </div>
)
