import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { Dispatch, SetStateAction } from 'react'
import { TestPhoneInputComponent } from 'components/TestItBtn/TestPhoneInputComponent'
import { ContentType } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'

type Props = {
  onTest: () => void
  fromAudio?: boolean
  showTT: boolean
  trigger: HTMLDivElement | HTMLButtonElement | null
  position?: TTPositionEnum
  type?: ContentType
  loading?: boolean
  disabled?: boolean
  success?: boolean
  phone: string
  setPhone: Dispatch<SetStateAction<string>>
  staticContent?: boolean
}

export const SendTestTooltip = observer(
  ({
    trigger,
    showTT,
    fromAudio,
    onTest,
    type,
    position = TTPositionEnum.bottomRight,
    phone,
    setPhone,
    loading,
    disabled,
    success,
    staticContent,
  }: Props) => {
    const content = (
      <SendTestTooltipContent
        onTest={onTest}
        fromAudio={fromAudio}
        type={type}
        disabled={disabled}
        loading={loading}
        phone={phone}
        setPhone={setPhone}
        showTT={showTT}
        trigger={trigger}
        success={success}
      />
    )

    return staticContent ? (
      content
    ) : (
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        position={position}
        addLeftPosition={position === TTPositionEnum.bottomLeft ? -8 : 8}
        width={236}
        globalClasses={[ClassesEnum.p16]}
      >
        {content}
      </FixedTooltip>
    )
  }
)

export const SendTestTooltipContent = ({
  fromAudio,
  onTest,
  type,
  phone,
  setPhone,
  loading,
  disabled,
  success,
}: Partial<Props>) => {
  const getText = () => {
    if (type === ContentType.Sms) {
      return 'SMS'
    }
    if (type === ContentType.Voice) {
      return 'Voice'
    }
    if (type === ContentType.Ringless) {
      return 'Ringless'
    }
    return fromAudio ? 'audio' : 'SMS'
  }

  // const handleTest = async () => {
  //   if (billingStore.currentCredits <= 0) {
  //     billingStore.setDisabledHideNotEnoughCreditsTimestamp(0)
  //   } else {
  //     try {
  //       if (testMessageInput) {
  //         await sendTestMessage({
  //           variables: {
  //             testMessageInput: { ...testMessageInput, toNumber: phone },
  //           },
  //         })
  //       }
  //       if (testCallMessageRequestInput) {
  //         await sendTestCallMessage({
  //           variables: {
  //             testMessageInput: {
  //               ...testCallMessageRequestInput,
  //               toNumber: phone,
  //             },
  //           },
  //         })
  //       }
  //       if (testRinglessMessageRequestInput) {
  //         await sendTestRinglessMessage({
  //           variables: {
  //             testMessageInput: {
  //               ...testRinglessMessageRequestInput,
  //               toNumber: phone,
  //             },
  //           },
  //         })
  //       }
  //       setSuccess(true)
  //     } catch (e) {
  //       console.error(e)
  //     }
  //   }
  // }

  return (
    <>
      <span className={'bold'}>Send test {getText()}</span>
      <SizedBox height={16} />
      <TestPhoneInputComponent
        disabled={disabled}
        loading={loading}
        phone={phone}
        setPhone={setPhone}
      />
      <SizedBox height={16} />
      <Button
        typeBtn={BtnType.primary}
        size={BtnSize.small}
        p12
        onClick={onTest}
        disabled={disabled}
        loading={loading}
        success={success}
      >
        Send test
      </Button>
    </>
  )
}
