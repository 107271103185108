import { TableStatuses } from 'components/NewTable/store/TableStore'
import { Status } from 'src/generated/graphql'

export const tableStatusToBack = (
  tableStatus: TableStatuses
): Status | undefined => {
  const obj = {
    [TableStatuses.totalCount]: undefined,
    //TODO:CHANGE STATUS
    [TableStatuses.visitorsCount]: undefined,
    //
    [TableStatuses.clicksCount]: undefined,
    [TableStatuses.subscribedCount]: undefined,
    [TableStatuses.activeCount]: Status.Active,
    [TableStatuses.confirmedCount]: Status.Confirmed,
    [TableStatuses.successCount]: Status.Success,
    [TableStatuses.invalidCount]: Status.Invalid,
    [TableStatuses.archivedCount]: Status.Archived,
    [TableStatuses.unsubscribedCount]: Status.Unsubscribed,
    [TableStatuses.failedCount]: Status.Failed,
    [TableStatuses.pendingCount]: Status.Pending,
    [TableStatuses.inactiveCount]: Status.Inactive,
  }
  return obj[tableStatus]
}
