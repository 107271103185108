import { FC } from 'react'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import auth from 'store/auth'
import { useLogout } from 'components/NavBar/components/Logout'
import { onError } from '@apollo/client/link/error'
import { observer } from 'mobx-react-lite'
import alertStore from 'store/alertStore'
import apiSore from 'store/apiSore'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'

const httpLink = new HttpLink({
  uri: process.env.API + '/graphql',
})

export const maintenanceError =
  'Sorry for the inconvenience but we are performing some maintenance at the moment. We will be back online shortly!'

export const AppApolloProvider: FC = observer(({ children }) => {
  const { onLogout } = useLogout()
  const logoutLink = onError((res) => {
    const { networkError, graphQLErrors } = res
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (networkError?.result?.message === maintenanceError) {
      window.location.reload()
    }

    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      networkError?.statusCode === 401 ||
      (graphQLErrors &&
        graphQLErrors[0].message.includes(
          'An Authentication object was not found in the SecurityContext'
        )) ||
      networkError?.message.includes('401')
    ) {
      onLogout(true)
    } else if (
      graphQLErrors &&
      graphQLErrors[0].message.includes('Member not found')
    ) {
      auth.setRefetchMainInfo()
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (networkError?.result?.message) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        console.error(networkError?.result?.message)
      }
      if (networkError?.message) {
        alertStore.setErrorAlert({
          message: networkError?.message,
        })
      }
      if (graphQLErrors && graphQLErrors[0].message) {
        alertStore.setErrorAlert({
          message: graphQLErrors[0].message,
        })
      }
    }
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: auth.token ? `Bearer ${auth.token}` : '',
      },
    }))

    return forward(operation)
  })
  const removeTypenameLink = removeTypenameFromVariables()
  const client = new ApolloClient({
    link: from([
      removeTypenameLink,
      concat(authMiddleware, logoutLink.concat(httpLink)),
    ]),
    cache: new InMemoryCache(),
  })
  apiSore.setClient(client)
  return <ApolloProvider client={client}>{children}</ApolloProvider>
})
