import { ColorsValues } from 'styles/variables'

export const Separator = ({
  color = ColorsValues.gray3,
  width = '100%',
  marginLeft = 0,
  marginBlock = 0,
}) => (
  <div
    style={{ height: 1, width, background: color, marginLeft, marginBlock }}
  />
)
