import { StatusContent } from 'components/StatusContent/StatusContent'
import { IMember } from 'src/types/IMember'
import { ColumnType } from 'components/NewTable/types'
import { DisplayStateEnum } from 'components/StatusContent/types'
import * as React from 'react'

export const memberColumns: ColumnType<IMember>[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'displayState',
    label: 'Status',
    format: (state) => (
      <StatusContent status={state as DisplayStateEnum} noIcon fromMember />
    ),
  },
  {
    id: 'displayRole',
    label: 'Role',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'posthog',
    label: 'Posthog',
    format: (_, { id }) => (
      <a
        href={`https://app.posthog.com/person/${id}#activeTab=sessionRecordings`}
        className={'s1 regular'}
        target={'_blank'}
        rel="noreferrer"
      >
        View Recording
      </a>
    ),
  },
]
