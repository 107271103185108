import { observer } from 'mobx-react-lite'
import { ActionsEnum } from 'components/Button/types'
import audioStore from 'store/audioStore/audioStore'
import { useAudioFunctions } from 'src/routes/settings/audio/hooks/useAudioFunctions'

const actions = [ActionsEnum.download, ActionsEnum.delete]

export const ManySelectContent = observer(() => {
  const { getRowBtnContentByAction } = useAudioFunctions({
    ids: audioStore.tableStore.checkedRowsIds,
    audioItems: audioStore.tableStore.checkedRows,
  })

  return <>{actions.map((action) => getRowBtnContentByAction(action, true))}</>
})
