import {
  Maybe,
  PhoneNumber,
  PhoneType,
  VerificationStatus,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { RoutesEnum } from 'src/routes/routes'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import React, { MouseEventHandler } from 'react'
import numbersStore from 'store/settings/numbers/numbersStore'

export function numberToOption(
  phone: Maybe<PhoneNumber>,
  forBroadcast?: boolean
): IOption {
  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    numbersStore.setOpenUnverifiedNumberModal(true)
  }
  return {
    title: phone?.friendlyName || '',
    value: phone?.friendlyName || '',
    group: phone?.type,
    data: phone,
    onClick:
      (!phone?.verificationStatus ||
        phone?.verificationStatus === VerificationStatus.Unverified ||
        phone?.verificationStatus === VerificationStatus.Restricted ||
        phone?.verificationStatus === VerificationStatus.Blocked) &&
      forBroadcast
        ? onClick
        : undefined,
    leftMark: true,
    optionRightContent:
      ((phone?.verificationStatus === VerificationStatus.Unverified ||
        phone?.verificationStatus === VerificationStatus.Restricted) &&
        phone.type === PhoneType.TollFree &&
        forBroadcast && (
          <MuiTooltip title={'Unverified'} placement={'top'} arrow>
            <div>
              <LinkWrapper to={RoutesEnum.settingsCompliance}>
                <StatusContent
                  status={VerificationStatus.Unverified}
                  text={'Unregistered'}
                  right
                  jsxText={<span className={'s2 error1'}>Unregistered</span>}
                />
              </LinkWrapper>
            </div>
          </MuiTooltip>
        )) ||
      (phone?.verificationStatus === VerificationStatus.Blocked &&
        phone.type === PhoneType.TollFree &&
        forBroadcast && (
          <MuiTooltip title={'Blocked'} placement={'top'} arrow>
            <div>
              <LinkWrapper to={RoutesEnum.settingsCompliance}>
                <StatusContent
                  status={VerificationStatus.Blocked}
                  text={numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                  right
                  jsxText={
                    <span className={'s2 error1'}>
                      {numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                    </span>
                  }
                />
              </LinkWrapper>
            </div>
          </MuiTooltip>
        )) ||
      ((phone?.verificationStatus === VerificationStatus.InReview ||
        phone?.verificationStatus === VerificationStatus.Pending) &&
        phone.type === PhoneType.TollFree &&
        forBroadcast && (
          <MuiTooltip title={'In Review'} placement={'top'} arrow>
            <div>
              <StatusContent
                status={VerificationStatus.InReview}
                text={numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                jsxText={
                  <span className={'s2 gray1'}>
                    {numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                  </span>
                }
                right
              />
            </div>
          </MuiTooltip>
        )) ||
      (phone?.verificationStatus === VerificationStatus.Verified &&
        phone.type === PhoneType.TollFree &&
        forBroadcast && (
          <MuiTooltip title={'Verified'} placement={'top'} arrow>
            <div>
              <StatusContent
                status={VerificationStatus.Verified}
                text={numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                jsxText={
                  <span className={'s2 gray1'}>
                    {numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                  </span>
                }
                right
              />
            </div>
          </MuiTooltip>
        )) ||
      undefined,
  }
}
