import {
  BroadcastFullResponseFragment,
  BroadcastResponseFragment,
  RinglessMessageInput,
  SmsMessageRequestInput,
  TriggerFragmentFragment,
  VoiceMessageInput,
} from 'src/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'

export const transformToInputs = (
  item:
    | TriggerFragmentFragment
    | BroadcastResponseFragment
    | BroadcastFullResponseFragment
): {
  smsMessageInput: Maybe<SmsMessageRequestInput>
  voiceMessageInput: Maybe<VoiceMessageInput>
  ringlessMessageInput: Maybe<RinglessMessageInput>
} => ({
  smsMessageInput: item.smsMessage
    ? {
        text: item.smsMessage.text || '',
        assetIds: item.smsMessage.assetIds,
        compliance: item.smsMessage.compliance,
        cardIds: item.smsMessage.cardIds,
      }
    : undefined,
  voiceMessageInput: item.voiceMessage
    ? {
        liveAnswer: item.voiceMessage.liveAnswer
          ? {
              ...item.voiceMessage.liveAnswer,
              liveAnswer: undefined,
              audioId: item.voiceMessage.liveAnswer?.liveAnswer?.id,
            }
          : undefined,
        voiceMail: item.voiceMessage.voiceMail
          ? {
              ...item.voiceMessage.voiceMail,
              voiceMail: undefined,
              audioId: item.voiceMessage.voiceMail?.voiceMail?.id,
            }
          : undefined,
      }
    : undefined,
  ringlessMessageInput: item.ringlessMessage
    ? {
        audioId: item.ringlessMessage.voiceMail?.id,
      }
    : undefined,
})
