import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import {
  TollFreeVerificationProfile,
  useAdminInitTfProfileBusinessInfoMutation,
  useInitTfProfileBusinessInfoMutation,
} from 'src/generated/graphql'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsTollFreeStep } from 'src/routes/settings/compliance/TollFreeVerification/types'
import { TollFreeBackBtn } from 'src/routes/settings/compliance/TollFreeVerification/steps/TollFreeBackBtn'
import { BusinessCompanyWebsite } from 'src/routes/settings/compliance/BusinessProfile/steps/components/BusinessCompanyWebsite'
import { InputText } from 'components/Input/InputText/InputText'
import { Separator } from 'components/Separator/Separator'
import { ColorsValues } from 'styles/variables'
import { AmericanAddressBlock } from 'src/routes/settings/account/compponents/AmericanAddressBlock/AmericanAddressBlock'
import businessProfileStore from 'store/settings/company/businessProfileStore'

export const BusinessInfo = observer(({ onSuccess }: PropsTollFreeStep) => {
  const {
    businessInfo,
    tfBusinessInfoRequestInput,
    mapErrors,
    disabledBusinessInfo,
    continueText,
    setBusinessInfo,
    setError,
    adminOrgId,
    isSuperAdminModal,
  } = tollFreeVerificationStore
  const [initTfProfileBusinessInfo, { loading }] =
    useInitTfProfileBusinessInfoMutation()
  const [adminInitTfProfileBusinessInfo, { loading: loadingAdmin }] =
    useAdminInitTfProfileBusinessInfoMutation()
  const onContinue = async () => {
    try {
      let profile: TollFreeVerificationProfile | null | undefined
      if (adminOrgId) {
        const { data } = await adminInitTfProfileBusinessInfo({
          variables: {
            input: tfBusinessInfoRequestInput,
            organizationId: adminOrgId,
          },
        })
        profile = data?.adminInitTFProfileBusinessInfo
      } else {
        const { data } = await initTfProfileBusinessInfo({
          variables: {
            input: tfBusinessInfoRequestInput,
          },
        })
        profile = data?.initTFProfileBusinessInfo
      }
      profile && onSuccess(profile)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (businessProfileStore.ownerFullBusinessProfile && !isSuperAdminModal) {
        if (!businessInfo) {
          setBusinessInfo({
            address:
              businessProfileStore.ownerFullBusinessProfile.businessGeneralInfo
                ?.address,
            name: businessProfileStore.ownerFullBusinessProfile
              .businessGeneralInfo?.name,
            website:
              businessProfileStore.ownerFullBusinessProfile.businessInfo
                ?.website,
          })
        }
      }
    }, 50)
  }, [businessProfileStore.ownerFullBusinessProfile])

  return (
    <div className={'col40'}>
      <div className={'col24'}>
        <p>
          <b>General</b>
        </p>
        <InputText
          label={'Business name'}
          placeholder={'e.g. Example Inc.'}
          value={businessInfo?.name || ''}
          onChangeValue={(name) =>
            setBusinessInfo({
              ...businessInfo,
              name,
            })
          }
        />
        <BusinessCompanyWebsite
          website={businessInfo?.website || ''}
          setWebsite={(website) =>
            setBusinessInfo({
              ...businessInfo,
              website,
            })
          }
          isError={!!mapErrors.get('website')}
          setError={setError}
        />
      </div>
      <Separator
        width={'calc(100% + 64px)'}
        marginLeft={-32}
        color={ColorsValues.gray3}
      />
      <div>
        <p className={'mb24'}>
          <b>Location</b>
        </p>
        <AmericanAddressBlock
          address={businessInfo?.address}
          setAddress={(address) =>
            setBusinessInfo({
              ...businessInfo,
              address,
            })
          }
          noEIN
        />
      </div>

      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledBusinessInfo}
          loading={loading || loadingAdmin}
        >
          {continueText}
        </Button>
        <TollFreeBackBtn />
      </div>
    </div>
  )
})
