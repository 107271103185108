import classNames from 'classnames'
import styles from './styles.module.scss'
import { ReactComponent as ArrowUp } from 'icons/32px/Arrow-Up.svg'
import { ReactComponent as Plus } from 'icons/32px/Plus.svg'
import { ReactComponent as Progress } from 'icons/circular_progress.svg'
import { Separator } from 'components/Separator/Separator'
import { ColorsValues } from 'styles/variables'
import { InputText } from 'components/Input/InputText/InputText'
import { ReactComponent as Link } from 'icons/16px/Link.svg'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AttachmentFile } from 'components/AttachmentFIle/AttachmentFile'
import mediaStore from 'store/mediaStore'
import { bytesToSize } from 'src/util/functions'
import { Loader } from 'components/Loader/Loader'
import { Asset } from 'src/generated/graphql'

type Props = {
  onAddFiles: (files: File[]) => void
  onFilesRejections?: (files: File[]) => void
  files?: File[] | null
  assets?: Asset[] | null
  accept: string
  withLink?: boolean
  title?: string
  subtitle?: string
  fileLimitSize?: number
  disabled?: boolean | 'variant2'
  inputLoading?: boolean
  mini?: boolean
  fullWidth?: boolean
  variant2?: boolean
  onUploadFileURL?: (url: string) => void
  onRemoveFile?: (id: string) => void
  fullTouch?: boolean
  withCheckAccept?: boolean
}

export const DragAndDropInput: FC<Props> = ({
  onAddFiles,
  onFilesRejections,
  accept,
  withLink,
  title = 'browse or drag and drop a file here',
  subtitle,
  files,
  assets,
  fileLimitSize,
  disabled,
  inputLoading,
  onUploadFileURL,
  mini,
  fullWidth,
  variant2,
  onRemoveFile,
  fullTouch,
  withCheckAccept,
}) => {
  const [link, setLink] = useState('')
  const [commonFileRejections, setCommonFileRejections] = useState<Array<File>>(
    []
  )

  const checkedLimit = (files: File[]) => {
    if (fileLimitSize) {
      const allowedFiles: File[] = []
      const rejectedFiles: File[] = []
      files.forEach((file) => {
        if (file.size < fileLimitSize) {
          allowedFiles.push(file)
        } else {
          rejectedFiles.push(file)
        }
      })
      onFilesRejections && onFilesRejections(rejectedFiles)
      setCommonFileRejections(rejectedFiles)
      if (rejectedFiles.length) {
        mediaStore.setShowError(
          true,
          `The ${
            rejectedFiles.length === 1
              ? `file ${rejectedFiles[0].name}`
              : `files ${rejectedFiles.map((file) => file.name).join(', ')}`
          }  is too large, please upload files less than 5 MB`
        )
      }
      allowedFiles.length && onAddFiles(allowedFiles)
    } else {
      onAddFiles(files)
    }
  }
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: File[] = Array.from(e.target.files)
      if (withCheckAccept) {
        const acceptedFiles: File[] = []
        const rejectedFiles: File[] = []
        files.forEach((file) => {
          const ext = file.name.split('.').pop()
          if (ext && accept.includes(ext)) {
            acceptedFiles.push(file)
          } else {
            rejectedFiles.push(file)
          }
        })
        onFilesRejections && onFilesRejections(rejectedFiles)
        setCommonFileRejections(rejectedFiles)
        acceptedFiles.length && checkedLimit(acceptedFiles)
      } else {
        checkedLimit(files)
      }
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    const files: File[] = Array.from(acceptedFiles)
    if (files.length) {
      checkedLimit(files)
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      onDrop,
      accept,
    })

  useEffect(() => {
    setCommonFileRejections(fileRejections.map((item) => item.file))
    fileRejections.length &&
      onFilesRejections &&
      onFilesRejections(fileRejections.map((item) => item.file))
  }, [fileRejections])

  useEffect(() => {
    if (fileRejections.length) {
      mediaStore.setShowError(true)
    }
  }, [fileRejections])
  useEffect(() => {
    return () => {
      mediaStore.setShowError(false)
    }
  }, [])

  const getRootPropsDiv = (disabled: boolean) =>
    disabled ? {} : getRootProps()

  const onClick = () => {
    open()
  }

  const assetsContent = (
    <>
      {assets?.length || files?.length ? (
        <div className={styles.wrapFile}>
          <AttachmentFile
            name={
              (files && files[0].name) ||
              (assets && assets[0].originalFileName) ||
              ''
            }
            maxWidth={428}
            onRemoveFile={
              onRemoveFile
                ? () => onRemoveFile(assets && assets[0].id)
                : undefined
            }
          />
        </div>
      ) : (
        <span className={'mb8'}>{title}</span>
      )}
      <span className={'s2 medium gray1'}>{subtitle}</span>
    </>
  )

  return (
    <div
      className={classNames(
        styles.wrap,
        isDragActive && styles.isDragActive,
        disabled &&
          (disabled === 'variant2' ? styles.disabled2 : styles.disabled),
        mini && styles.mini,
        fullTouch && !assets?.length && styles.fullTouch,
        fullWidth && styles.fullWidth,
        variant2 && styles.variant2
      )}
      {...getRootPropsDiv(!!mini)}
      onClick={(event) => {
        if (fullTouch && !assets?.length) {
          onClick()
        }
        event.stopPropagation()
      }}
      aria-hidden={true}
    >
      <input
        {...getInputProps()}
        id={'inputMediaFile'}
        type={'file'}
        accept={accept}
        onChange={onSelectFile}
      />
      {mini ? (
        <div className={styles.wrapMini} {...getRootProps()}>
          <div className={styles.inputIconWrap}>
            {isDragActive ? (
              <div className={styles.arrow}>
                <ArrowUp />
              </div>
            ) : (
              <div className={styles.plus}>
                {inputLoading ? <Loader /> : <Plus />}
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {!(variant2 && assets?.length) && (
            <div
              className={styles.inputIconWrap}
              {...getRootPropsDiv(!mini || fullTouch)}
              onClick={fullTouch ? undefined : onClick}
              aria-hidden={true}
            >
              {isDragActive ? (
                <div className={styles.arrow}>
                  <ArrowUp />
                </div>
              ) : commonFileRejections.length ? (
                <Progress />
              ) : (
                <div className={styles.plus}>
                  {inputLoading ? <Loader /> : <Plus />}
                </div>
              )}
            </div>
          )}
          {!(variant2 && !files?.length && !assets?.length) && (
            <div className={styles.inputInfo}>
              {commonFileRejections.length ? (
                <>
                  <span className={'mb8'}>
                    {commonFileRejections.length === 1
                      ? commonFileRejections[0].name
                      : `${commonFileRejections.length} files`}
                  </span>

                  <span className={'s2 medium gray1'}>
                    file size:{' '}
                    {bytesToSize(
                      commonFileRejections.reduce(
                        (acc, item) => acc + item.size,
                        0
                      )
                    )}
                  </span>
                </>
              ) : (
                assetsContent
              )}
            </div>
          )}
        </>
      )}

      {withLink && (
        <>
          <div className={styles.dividerWrap}>
            <Separator color={ColorsValues.gray3} />
            <div className={styles.or}>or</div>
            <Separator color={ColorsValues.gray3} />
          </div>
          <div
            className={styles.wrapUrl}
            onClick={(event) => {
              event.stopPropagation()
            }}
            aria-hidden={true}
          >
            <InputText
              placeholder={'Paste an image URL'}
              leftIcon={<Link />}
              value={link}
              withoutClear
              onChange={(e) => setLink(e.target.value)}
            />
            <Button
              size={BtnSize.medium}
              typeBtn={BtnType.primary}
              disabled={!link || inputLoading}
              onClick={
                onUploadFileURL ? () => onUploadFileURL(link) : undefined
              }
            >
              Upload
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
