import { observer } from 'mobx-react-lite'
import { Dispatch, SetStateAction, useEffect } from 'react'
import user from 'store/user/user'
import { InputText } from 'components/Input/InputText/InputText'
import { SizedBox } from 'components/SizedBox'
import styles from 'src/routes/auth/styles.module.scss'

type MemberInfoProps = {
  info: {
    email: string
    firstName: string
    lastName: string
    phone: string
  }
  setInfo: Dispatch<SetStateAction<MemberInfoProps['info']>>
  disabledFields: Array<keyof MemberInfoProps['info']>
}

export const MemberInfo = observer(
  ({
    info: { email, firstName, lastName, phone },
    setInfo,
    disabledFields,
  }: MemberInfoProps) => {
    useEffect(() => {
      setInfo({
        email: user.email,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
      })
    }, [user.member])

    const handleInput =
      (key: keyof MemberInfoProps['info']) => (value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }))
      }

    return (
      <>
        <InputText
          id={'email'}
          label={'Email address'}
          placeholder={'email@address.com'}
          value={email}
          onChangeValue={handleInput('email')}
          onClickRightIcon={() => handleInput('email')('')}
          disabled={disabledFields.includes('email')}
        />
        <SizedBox height={16} />
        <div className={styles.inputWrap}>
          <InputText
            label={'First Name'}
            placeholder={'e.g. Adam'}
            value={firstName}
            onChangeValue={handleInput('firstName')}
            onClickRightIcon={() => handleInput('firstName')('')}
            focusOnMount
            disabled={disabledFields.includes('firstName')}
          />
          <InputText
            label={'Last Name'}
            placeholder={'e.g. Robertson'}
            value={lastName}
            onChangeValue={handleInput('lastName')}
            onClickRightIcon={() => handleInput('lastName')('')}
            disabled={disabledFields.includes('lastName')}
          />
        </div>
        <SizedBox height={16} />
        <InputText
          patternFormat
          format={'(###) ###-####'}
          maskFormat={'_'}
          label={'Phone number'}
          placeholder={'(123) 123-1234'}
          value={phone}
          onChangeValue={handleInput('phone')}
          onClickRightIcon={() => handleInput('phone')('')}
          disabled={disabledFields.includes('phone')}
        />
      </>
    )
  }
)
