import { FC } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || '')

export const StripeProvider: FC = ({ children }) => (
  <Elements
    stripe={stripePromise}
    options={{
      locale: 'en',
      fonts: [
        {
          family: 'Inter',
          src: 'url(/assets/Inter-Regular.woff)',
          weight: '400',
          cssSrc: 'https://fonts.googleapis.com/css2?family=Inter&display=swap',
        },
      ],
    }}
  >
    {children}
  </Elements>
)
