import { observer } from 'mobx-react-lite'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import React from 'react'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'

export const CreateTestItBroadcastBtn = observer(
  ({
    openTest,
    setOpenTest,
    newBroadCastStore,
    widthInput,
  }: {
    openTest: boolean
    setOpenTest: () => void
    newBroadCastStore: CreateBroadCastStore
    widthInput?: number
  }) => {
    return (
      <TestItBtn
        fromCreate
        setOpenTest={setOpenTest}
        openTest={openTest}
        testMessageInput={
          newBroadCastStore.activeTab === 0
            ? {
                smsMessage: {
                  compliance:
                    !!newBroadCastStore.broadcastInput.smsMessage?.compliance,
                  text: newBroadCastStore.broadcastInput.smsMessage?.text || '',
                  assetIds:
                    newBroadCastStore.broadcastInput.smsMessage?.assetIds,
                  cardIds: newBroadCastStore.broadcastInput.smsMessage?.cardIds,
                },
                toNumber: '',
                fromNumberId:
                  newBroadCastStore.broadcastInput.sendFromPhoneNumber?.id,
              }
            : undefined
        }
        testCallMessageRequestInput={
          newBroadCastStore.activeTab === 1
            ? {
                voiceMessage: newBroadCastStore.broadcastInput.voiceMessage,
                toNumber: '',
                fromNumberId:
                  newBroadCastStore.broadcastInput.sendFromPhoneNumber?.id,
              }
            : undefined
        }
        testRinglessMessageRequestInput={
          newBroadCastStore.activeTab === 2
            ? {
                ...newBroadCastStore.broadcastInput.ringlessMessage,
                toNumber: '',
                fromNumberId:
                  newBroadCastStore.broadcastInput.sendFromPhoneNumber?.id,
              }
            : undefined
        }
        fromAudio={newBroadCastStore.activeTab !== 0}
        widthInput={widthInput}
      />
    )
  }
)
