import styles from './styles.module.scss'
import { NavBar } from 'components/NavBar/NavBar'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { AppModals } from 'src/layout/AppModals'
import { useIsDowngrade } from 'src/routes/settings/billing/hooks/useIsDowngrade'
import { useIsCancelSubscription } from 'src/routes/settings/billing/hooks/useIsCancelSubscription'
import { useLoadingHistory } from 'src/routes/main/contacts/hooks/useLoadingHistory'
import { useSetMember } from 'src/routes/settings/account/hooks/useSetMember'
import { useBeforeUnload } from 'src/hooks/useBeforeUnload'
import { MobileBar } from 'components/NavBar/components/MobileBar/MobileBar'
import { useCanny } from 'src/hooks/useCanny'
import { useSetFullTollFreeProfile } from 'src/routes/settings/compliance/TollFreeVerification/hooks/useSetFullTollFreeProfile'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { useSetIntegrations } from 'src/routes/settings/integrations/hooks/useSetIntegrations'
import integrationsStore from 'store/settings/integrationsStore'
import { useSetTypes } from 'src/routes/settings/company/hooks/useSetTypes'
import { useSetConfig } from 'src/hooks/useSetConfig'
import { MainComponents } from 'components/App/AuthorizedApp/MainComponents'
import { useHubspotRegisterCompany } from 'src/hooks/useHubspotRegisterCompany'
import auth from 'store/auth'

export const AppLayout = observer(() => {
  useBeforeUnload()
  useSetMember(true)
  useIsDowngrade()
  useIsCancelSubscription()
  useLoadingHistory()
  useCanny()
  useSetFullTollFreeProfile(tollFreeVerificationStore.initLoaded)
  useSetIntegrations(integrationsStore.initLoading)
  useSetTypes()
  useSetConfig()
  useHubspotRegisterCompany(auth.completeRegisterTrigger)

  return (
    <>
      <div
        className={styles.wrap}
        // style={{ minHeight: uiStore.minRootHeight }}
      >
        <MainComponents />
        <NavBar />
        {uiStore.isMobile ? (
          <div className={styles.pageWrap}>
            {uiStore.isMobile && <MobileBar />}
            <Outlet />
          </div>
        ) : (
          <Outlet />
        )}

        <AppModals />
      </div>
    </>
  )
})
