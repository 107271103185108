import { observer } from 'mobx-react-lite'
import { IBroadcast } from 'src/types/IBroadcast'
import * as React from 'react'
import { FC, useMemo } from 'react'
import { getBroadcastActions } from 'src/routes/main/broadcasts/functions/getBroadcastActions'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'

export const OneSelectContent = observer(
  ({ broadcast }: { broadcast: IBroadcast }) => {
    const actions = getBroadcastActions(broadcast)

    return useMemo(() => {
      const rowActions = actions.slice(0, 4)
      const moreActions = actions.slice(4)
      return (
        <>
          {rowActions.map((Action, index) => (
            <Action key={index} medium />
          ))}
          {!!moreActions?.length && (
            <MoreBtn
              menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
              tooltip={'More'}
              medium
            />
          )}
        </>
      )
    }, [actions])
  }
)
