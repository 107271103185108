import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'

type InputCheckboxProps = {
  label: string | JSX.Element
  checked: boolean
  disabled?: boolean
  radio?: boolean
  tooltip?: string | JSX.Element
  fromDropdown?: boolean
  noBordered?: boolean
  noBordered2?: boolean
  noBordered3?: boolean
  small?: boolean
  onChecked: () => void
  rightIcon?: JSX.Element
  expandedContent?: JSX.Element
  maxWidth?: number
  className?: string
  rowClassName?: string
  fitHeight?: boolean
  marginLeft?: number
  width?: string
  fullWidthLabel?: boolean
  alignCenter?: boolean
}

export const InputCheckbox: FC<InputCheckboxProps> = ({
  label,
  checked,
  onChecked,
  tooltip,
  radio,
  fromDropdown,
  rightIcon,
  noBordered,
  noBordered2,
  noBordered3,
  maxWidth,
  expandedContent,
  className,
  rowClassName,
  disabled,
  small,
  fitHeight,
  marginLeft,
  width,
  fullWidthLabel,
  alignCenter,
}) => {
  return (
    <div
      className={classNames(
        styles.wrap,
        'inputCheckboxWrap',
        fromDropdown && styles.fromDropdown,
        noBordered && styles.noBordered,
        alignCenter && styles.alignCenter,
        noBordered2 && styles.noBordered2,
        noBordered3 && styles.noBordered3,
        small && styles.small,
        fitHeight && styles.fitHeight,
        disabled && styles.disabled,
        expandedContent && checked && styles.expanded,
        className
      )}
      onClick={onChecked}
      aria-hidden={true}
      style={{ maxWidth, marginLeft, width }}
    >
      <div className={classNames(styles.row, rowClassName)}>
        <CheckBox checked={checked} radio={radio} disabled={disabled} />
        <div className={classNames(fullWidthLabel && 'fullWidth')}>{label}</div>
        {tooltip && <InfoTooltip title={tooltip} />}
        {rightIcon}
      </div>

      {checked && expandedContent}
    </div>
  )
}
