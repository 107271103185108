import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Tag } from 'components/Tag/Tag'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import manualContactStore from 'store/contacts/manualContactStore'

export const UnsubscribeContactModal = observer(() => {
  const onClose = () => {
    contactStore.setUnsubscribedContactsListsIds([])
    contactStore.setOpenUnsubscribeContactModal(false)
  }
  const onConfirm = () => {
    onClose()
  }
  const onChecked = (id: string) => {
    const checked = contactStore.unsubscribedContactsListsIds.includes(id)
    if (checked) {
      contactStore.setUnsubscribedContactsListsIds(
        contactStore.unsubscribedContactsListsIds.filter(
          (listId) => listId !== id
        )
      )
    } else {
      contactStore.setUnsubscribedContactsListsIds([
        ...contactStore.unsubscribedContactsListsIds,
        id,
      ])
    }
  }
  const selectedLists = manualContactStore.selectedContactLists
  const unsubscribed = selectedLists.filter(
    (selectedList) =>
      !contactStore.unsubscribedContactsListsIds.includes(selectedList.id)
  )
  const subscribed = selectedLists.filter((selectedList) =>
    contactStore.unsubscribedContactsListsIds.includes(selectedList.id)
  )
  const onSubscribeAll = () => contactStore.setUnsubscribedContactsListsIds([])
  const onUnsubscribeAll = () =>
    contactStore.setUnsubscribedContactsListsIds(
      selectedLists.map((list) => list.id)
    )
  return (
    <Modal
      open={contactStore.openUnsubscribeContactModal}
      onClose={onClose}
      title={'Unsubscribe contact'}
      minWidth={528}
    >
      <div className={styles.wrap}>
        <div className={styles.wrapContent}>
          {!!subscribed.length && (
            <div>
              <div className={styles.row}>
                <span className={'s2 medium gray1'}>Unsubscribed</span>
                <button className={styles.resubscribe} onClick={onSubscribeAll}>
                  <span className={'s2 bold blue3'}>Resubscribe all</span>
                </button>
              </div>
              <InputCheckboxGroup variant2>
                {subscribed.map((list) => (
                  <InputCheckbox
                    key={list.id}
                    label={
                      <Tag
                        text={list.name}
                        leftIcon={'contactsFill'}
                        count={list.contacts.length}
                      />
                    }
                    checked={contactStore.unsubscribedContactsListsIds.includes(
                      list.id
                    )}
                    onChecked={() => onChecked(list.id)}
                  />
                ))}
              </InputCheckboxGroup>
            </div>
          )}
          {!!unsubscribed.length && (
            <div>
              <div className={styles.row}>
                <span className={'s2 medium gray1'}>Unsubscribe from</span>
                <button
                  className={styles.unsubscribe}
                  onClick={onUnsubscribeAll}
                >
                  <span className={'s2 bold error1'}>Unsubscribe all</span>
                </button>
              </div>
              <InputCheckboxGroup variant2>
                {unsubscribed.map((list) => (
                  <InputCheckbox
                    key={list.id}
                    label={
                      <Tag
                        text={list.name}
                        leftIcon={'contactsFill'}
                        count={list.contacts.length}
                      />
                    }
                    checked={contactStore.unsubscribedContactsListsIds.includes(
                      list.id
                    )}
                    onChecked={() => onChecked(list.id)}
                  />
                ))}
              </InputCheckboxGroup>
            </div>
          )}
        </div>

        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onConfirm}
          >
            Confirm
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
