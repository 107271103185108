import { ReactComponent as Sent } from 'icons/16pxNoMask/Status/Sent.svg'
import { ReactComponent as Scheduled } from 'icons/16px/Status/Scheduled.svg'
import { ReactComponent as Stopped } from 'icons/16px/Status/Stopped.svg'
import { ReactComponent as Draft } from 'icons/16px/Status/Draft.svg'
import { ReactComponent as Archived } from 'icons/16pxNoMask/Status/Archived.svg'
import { ReactComponent as Failed } from 'icons/16pxNoMask/Status/Failed.svg'
import { ReactComponent as Minus } from 'icons/16pxNoMask/Status/Minus.svg'
import { ReactComponent as Info } from 'icons/16pxNoMask/Info.svg'
import { ReactComponent as Checking } from 'icons/16px/Status/Checking.svg'
import { ColorsNames } from 'styles/variables'
import classNames from 'classnames'
import styles from './styles.module.scss'
import {
  InvoiceStatus,
  OrganizationStatus,
  Status,
  VerificationStatus,
} from 'src/generated/graphql'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ActionsEnum } from 'components/Button/types'
import { FC } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import {
  CustomStatuses,
  disabledStatuses,
  draftStatuses,
  failedStatuses,
  IStatus,
  limitedStatuses,
  pendingStatuses,
  reviewStatuses,
  successStatuses,
} from 'components/StatusContent/types'
import _ from 'lodash'

export const StatusContent = ({
  status,
  text,
  noIcon,
  right,
  fromMember,
  jsxText,
  tooltipIconText,
  tooltipWidth,
  upperFirst,
}: {
  status: IStatus
  text?: string
  jsxText?: JSX.Element
  noIcon?: boolean
  right?: boolean
  size16?: boolean
  fromMember?: boolean
  tooltipIconText?: string | JSX.Element
  tooltipWidth?: number | string
  upperFirst?: boolean
}) => {
  const statusText = upperFirst
    ? status
        .toLowerCase()
        .replace(/_/g, ' ')
        .split(' ')
        .map((word) => _.upperFirst(word))
        .join(' ')
    : status
  const title = jsxText || text || statusText
  const WrapIcon: FC = ({ children }) => {
    if (tooltipIconText) {
      return (
        <MuiTooltip
          title={tooltipIconText}
          placement={'top'}
          arrow
          width={tooltipWidth}
        >
          <div className={'flex'}>{children}</div>
        </MuiTooltip>
      )
    }
    return <>{children}</>
  }
  if (successStatuses.includes(status)) {
    const newTitle = status === OrganizationStatus.Active ? 'Active' : title

    return (
      <div
        className={classNames(
          ColorsNames.success1,
          styles.status,
          right && styles.right
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <AppIcon color={ColorsNames.success1}>
              <Sent />
            </AppIcon>
          </WrapIcon>
        )}
        {newTitle}
      </div>
    )
  }
  if (failedStatuses.includes(status)) {
    const newTitle = () => {
      if (status === OrganizationStatus.Suspended) {
        return 'Suspended'
      }
      if (status === OrganizationStatus.Banned) {
        return 'Banned'
      }
      return title
    }

    return (
      <div
        className={classNames(
          ColorsNames.error1,
          styles.status,
          right && styles.right,
          styles.failed
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <Failed />
          </WrapIcon>
        )}
        <span className={ColorsNames.error1} title={text}>
          {newTitle()}
        </span>
      </div>
    )
  }
  if (reviewStatuses.includes(status)) {
    return (
      <div
        className={classNames(
          ColorsNames.notify1,
          styles.status,
          right && styles.right
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <Scheduled />
          </WrapIcon>
        )}
        {title}
      </div>
    )
  }
  if (status === Status.Running || status === Status.Sending) {
    return (
      <div className={classNames(styles.status, right && styles.right)}>
        {!noIcon && (
          <WrapIcon>
            <AppIcon icon={ActionsEnum.start} color={ColorsNames.blue1} />
          </WrapIcon>
        )}
        <span className={ColorsNames.blue1}>{title}</span>
      </div>
    )
  }
  if (pendingStatuses.includes(status) && !fromMember) {
    return (
      <div className={classNames(styles.status, right && styles.right)}>
        {!noIcon && (
          <WrapIcon>
            <AppIcon color={ColorsNames.blue1}>
              <Info />
            </AppIcon>
          </WrapIcon>
        )}
        <span className={ColorsNames.blue1}>{title}</span>
      </div>
    )
  }
  if (status === CustomStatuses.Stopped2) {
    return (
      <div
        className={classNames(
          ColorsNames.orange1,
          styles.status,
          right && styles.right
        )}
      >
        <WrapIcon>
          <Stopped />
        </WrapIcon>
        Stopped
      </div>
    )
  }
  if (draftStatuses.includes(status)) {
    return (
      <div
        className={classNames(
          ColorsNames.gray1,
          styles.status,
          right && styles.right
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <Draft />
          </WrapIcon>
        )}
        {title}
      </div>
    )
  }
  if (disabledStatuses.includes(status)) {
    return (
      <div
        className={classNames(
          styles.status,
          right && styles.right,
          styles.disabled
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <Minus />
          </WrapIcon>
        )}
        <span className={'gray1'}>{title}</span>
      </div>
    )
  }
  if (status === Status.Archived) {
    return (
      <div
        className={classNames(
          ColorsNames.warning2,
          styles.status,
          right && styles.right,
          styles.archived
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <Archived />
          </WrapIcon>
        )}
        {title}
      </div>
    )
  }
  if (
    status === VerificationStatus.Unverified ||
    status === VerificationStatus.Restricted
  ) {
    return (
      <div className={classNames(styles.status, right && styles.right)}>
        {!noIcon && (
          <WrapIcon>
            <AppIcon color={ColorsNames.error1}>
              <Info />
            </AppIcon>
          </WrapIcon>
        )}
        <span className={'error1'}>{title}</span>
      </div>
    )
  }
  if (status === Status.Invalid) {
    return (
      <div
        className={classNames(
          ColorsNames.error1,
          styles.status,
          right && styles.right,
          styles.failed
        )}
      >
        <WrapIcon>
          <Failed />
        </WrapIcon>
        {title}
      </div>
    )
  }
  if (limitedStatuses.includes(status)) {
    return (
      <div
        className={classNames(
          styles.limited,
          styles.status,
          right && styles.right
        )}
      >
        {!noIcon && (
          <WrapIcon>
            <Minus />
          </WrapIcon>
        )}
        <span className={'orange1'}>
          {status === InvoiceStatus.UnCollectible ? 'Uncollectible' : title}
        </span>
      </div>
    )
  }
  if (status === Status.Paused) {
    return (
      <div className={classNames(styles.status, right && styles.right)}>
        {!noIcon && (
          <WrapIcon>
            <AppIcon
              color={ColorsNames.orange1}
              type={IIconType.circle}
              icon={ActionsEnum.pause}
            />
          </WrapIcon>
        )}
        <span className={'orange1'}>{title}</span>
      </div>
    )
  }
  if (status === Status.Processing) {
    return (
      <div className={classNames(styles.status, right && styles.right)}>
        {!noIcon && (
          <WrapIcon>
            <Checking />
          </WrapIcon>
        )}
        <span className={'gray1'}>{title}</span>
      </div>
    )
  }
  return <span>{status}</span>
}
