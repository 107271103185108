import { IOption } from 'src/types/IOption'

export const contactToOption = ({
  name,
  id,
  formattedPhoneNumber,
  email,
  phone,
}: {
  name?: string | null
  id?: string
  formattedPhoneNumber?: string | null
  phone?: string | null
  email?: string | null
}): IOption => ({
  value: id,
  id: id,
  title: name?.trim() || formattedPhoneNumber || phone || '',
  subTitle: (name?.trim() && formattedPhoneNumber) || '',
  group: 'contact',
  leftIcon: 'user',
  rightContent: email ? <span className={'s2 gray1'}>{email}</span> : undefined,
  minHeight: 56,
})
