// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlskV8D7mOD2cgOOBVIg{cursor:pointer;display:grid;grid-template-columns:max-content 1fr max-content;gap:16px;justify-content:space-between;padding-inline:16px;align-items:center;height:44px;border-radius:8px}.PlskV8D7mOD2cgOOBVIg:hover,.PlskV8D7mOD2cgOOBVIg.WGRkuJPob6UfuqBZ6xXf{background-color:var(--secondary-color-aqua-2)}.PlskV8D7mOD2cgOOBVIg:hover .BI3hOOGDI0kWvLoBqIjg,.PlskV8D7mOD2cgOOBVIg.WGRkuJPob6UfuqBZ6xXf .BI3hOOGDI0kWvLoBqIjg{color:var(--primary-color-blue-1)}`, "",{"version":3,"sources":["webpack://./src/routes/main/newOrganization/components/navbarItem/SwitchOrganization/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,YAAA,CACA,iDAAA,CACA,QAAA,CACA,6BAAA,CACA,mBAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,uEACE,8CAAA,CACA,mHACE,iCAAA","sourcesContent":[".wrap{\n  cursor: pointer;\n  display: grid;\n  grid-template-columns: max-content 1fr max-content;\n  gap: 16px;\n  justify-content: space-between;\n  padding-inline: 16px;\n  align-items: center;\n  height: 44px;\n  border-radius: 8px;\n  &:hover, &.active{\n    background-color: var(--secondary-color-aqua-2);\n    .icon{\n      color: var(--primary-color-blue-1);\n    }\n\n  }\n  .icon{\n\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `PlskV8D7mOD2cgOOBVIg`,
	"active": `WGRkuJPob6UfuqBZ6xXf`,
	"icon": `BI3hOOGDI0kWvLoBqIjg`
};
export default ___CSS_LOADER_EXPORT___;
