import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  LegacyRef,
  MouseEventHandler,
} from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { ColorsValues } from 'styles/variables'
import { Icon, IIcon } from 'components/Icon/Icon'

type Props = {
  text: string
  leftIcon?: IIcon
  rightIcon?: JSX.Element
  count?: number
  close?: boolean
  noneEvents?: boolean
  onDeleteTag?: () => void
  onClick?: (e: React.MouseEvent) => void
  ref?: LegacyRef<HTMLButtonElement>
  className?: string
  backgroundColor?: ColorsValues
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>
  maxWidth?: CSSProperties['maxWidth']
}

// eslint-disable-next-line react/display-name
export const Tag = forwardRef(
  (
    {
      leftIcon,
      text,
      rightIcon,
      count,
      close,
      noneEvents,
      onDeleteTag,
      onClick,
      className,
      backgroundColor,
      onMouseEnter,
      maxWidth,
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        className={classNames(
          styles.wrap,
          noneEvents && styles.noneEvents,
          className
        )}
        onClick={onClick}
        ref={ref}
        style={{ backgroundColor, maxWidth }}
        onMouseEnter={onMouseEnter}
      >
        {leftIcon && <Icon icon={leftIcon} fontSize={12} />}
        <span className={'labelTag ellipsisNowrap'}>{text}</span>
        {!!count && <span className={'gray1'}>({count})</span>}
        {close && (
          <Button className={styles.close} onClick={onDeleteTag}>
            <Close />
          </Button>
        )}
        {rightIcon}
      </button>
    )
  }
)
