import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { secondToTime } from 'src/util/functions'
import { InputText } from 'components/Input/InputText/InputText'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'
import { ChooseVoice } from 'src/Modals/CreateTextToSpeechModal/components/ChooseVoice/ChooseVoice'
import { PreviewSpeech } from 'src/Modals/CreateTextToSpeechModal/components/PreviewSpeech/PreviewSpeech'
import { NewLabel } from 'components/NewLabel/NewLabel'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import { ColorsValues } from 'styles/variables'

export const CreateTextToSpeechModal = observer(() => {
  const {
    openModal,
    setOpenModal,
    name,
    setName,
    text,
    setText,
    disabledCreate,
    duration,
    handleCreate,
    loadingCreate,
    isError,
    audioFileDuration,
  } = createTextToSpeechStore

  const onClose = () => {
    setOpenModal(false)
  }

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      title={
        <>
          Create text to speech
          <NewLabel marginLeft={14} />
        </>
      }
    >
      <div className={'mediumModal'}>
        <div className={styles.wrapInput}>
          <InputText
            withoutBorder
            placeholder={'Audio name'}
            value={name}
            onChange={(event) => setName(event.target.value)}
            withoutClear
            focusOnMount
          />
        </div>
        <div className={styles.wrapTextField}>
          <SimpleTextarea
            className={styles.textArea}
            value={text}
            onChangeValue={setText}
          />
          <div className={styles.textareaActions}>
            {isError ? (
              <div className={'row8'}>
                <Icon icon={'warning'} color={ColorsValues.error1} />
                <span className={'s2 error1'}>
                  Your message must be under 3 minutes
                </span>
              </div>
            ) : (
              <ChooseVoice />
            )}
            {!!duration && (
              <span className={classNames('s2 gray2', { error1: isError })}>
                {(audioFileDuration && secondToTime(audioFileDuration)) ||
                  `~${secondToTime(duration)}`}
              </span>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={disabledCreate}
            onClick={handleCreate}
            loading={loadingCreate}
          >
            Create
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
          <div />
          <PreviewSpeech />
        </div>
      </div>
    </Modal>
  )
})
