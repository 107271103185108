import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  reaction,
} from 'mobx'
import { CreateStore } from 'components/CreateElement/store/createStore'
import {
  transformICustomFieldToWebFormCustomField,
  transformWebFormCustomFieldToICustomField,
} from 'store/webForms/functions'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import {
  generateField,
  mockPages,
  phoneCollectField,
} from 'store/webForms/mockData'
import contactStore from 'store/contacts/contactStore'
import CustomFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { IPreviewMessage } from 'store/broadcasts/createBroadCastStore'
import { nanoid } from 'nanoid'
import webFormStore from 'store/webForms/webFormStore'
import { Status, VerificationStatus, WebFormInput } from 'src/generated/graphql'
import { IPreviewContent } from 'components/Preview/types'
import { ICustomField } from 'src/types/ICustomField'
import { IWebForm } from 'src/types/IWebForm'
import { IOption } from 'src/types/IOption'
import numbersStore from 'store/settings/numbers/numbersStore'

export class CreateWebFormStore extends CreateStore {
  dropdownContactListsStore: DropdownContactListsStore
  customFieldsStore: typeof CustomFieldsStore
  contactStore: typeof contactStore
  webFormStore: typeof webFormStore
  constructor() {
    super({
      createBtnText: 'Create web from',
      compliance: false,
    })
    this.dropdownContactListsStore = new DropdownContactListsStore()
    this.contactStore = contactStore
    this.customFieldsStore = CustomFieldsStore
    this.webFormStore = webFormStore
    makeObservable(this, {
      initInput: observable,
      dropdownContactListsStore: observable,
      webFormStore: observable,
      edit: observable,
      duplicate: observable,
      recommended: observable,
      doubleOptIn: observable,
      editId: observable,
      fieldsToCollectMap: observable,
      selectedPage: observable,
      customWebsite: observable,
      thankYouPage: observable,
      subscribeBtnText: observable,

      disabledCreate: computed,
      previewContent: override,
      editWebForm: computed,
      fieldsToCollect: computed,
      orderFieldsCollect: computed,
      fieldsOptions: computed,
      smsMessagesWebForm: computed,
      thankYouPageValue: computed,
      webFrom: computed,

      setRecommended: action.bound,
      setDoubleOptIn: action.bound,
      setEdit: action.bound,
      setThankYouPage: action.bound,
      setCustomWebsite: action.bound,
      setOrderFieldsCollect: action.bound,
      addCollectField: action.bound,
      setFieldsCollect: action.bound,
      removeCollectField: action.bound,
      setSubscribeText: action.bound,
    })

    reaction(
      () => this.webFormInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        this.isHaveChanges = initInputString !== inputString
      }
    )
  }

  element = 'Web form'

  createId = nanoid()
  activeFilterId = ''
  status: Status | undefined = Status.Active

  edit = false
  duplicate = false
  recommended = true
  doubleOptIn = false
  editId = ''
  uuid = ''
  selectedPage: IOption | undefined = mockPages[0]
  customWebsite = ''
  subscribeBtnText = 'Subscribe'
  thankYouPage = ''
  fieldsToCollectMap: Map<string, ICustomField> = new Map<string, ICustomField>(
    [[phoneCollectField.id, phoneCollectField]]
  )
  initInput: WebFormInput | null = null

  get thankYouPageValue() {
    if (this.thankYouPage === '') {
      return ''
    }
    return 'custom'
  }

  get thankYouPageValueForBack() {
    if (this.thankYouPage === 'custom') {
      return this.customWebsite
    }
    return ''
  }

  get webFormInput(): WebFormInput {
    return {
      id: this.edit ? this.editId : undefined,
      name: this.name,
      subscribeBtnText: this.subscribeBtnText,
      smsMessage: this.textareaStore.smsMessageInput,
      doubleOptIn: this.doubleOptIn,
      sendFromPhoneNumber: numbersStore.numbersMapByFriendly.get(this.smsPhone),
      status: this.status,
      thankYouPage: this.thankYouPageValueForBack,
      fieldsRequest: this.fieldsToCollect.map((filed) => ({
        key: filed.key,
        required: filed.required,
      })),
      contactsListsIds: this.dropdownContactListsStore.selectedOptions.map(
        (opt) => +opt.value
      ),
    }
  }

  get editWebForm(): IWebForm | undefined {
    return this.webFormStore.webFormsMap.get(this.editId)
  }

  get webFrom(): IWebForm {
    return {
      ...this.webFormInput,
      uuid: this.uuid,
      id: this.edit ? this.editId : this.createId,
      name: this.name,
      status: this.status,
      doubleOptIn: this.doubleOptIn,
      fieldsToCollect: this.fieldsToCollect.map(
        transformICustomFieldToWebFormCustomField
      ),
      contactsListsIds: this.dropdownContactListsStore.selectedOptions.map(
        (opt) => opt.value
      ),
      visitors: 0,
    }
  }

  get previewContent(): IPreviewContent {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { ...super.previewContent, smsMessages: this.smsMessagesWebForm }
  }

  get smsMessagesWebForm() {
    const newSmsMessages: IPreviewMessage[] = [
      ...this.previewSmsContent.smsMessages,
    ]
    if (this.doubleOptIn) {
      const message: IPreviewMessage = {
        messageHtml:
          'Thanks for registering. To confirm that you want to receive SMS messages from Call Loop, please reply Yes.',
        attachImages: [],
        messageId: nanoid(),
        withoutCompliance: true,
      }
      newSmsMessages[0].answer = 'Yes'

      newSmsMessages.unshift(message)
    } else {
      newSmsMessages[0].answer = ''
    }
    return { ...this.previewSmsContent, smsMessages: newSmsMessages }
  }

  get fieldsOptions(): IOption[] {
    const currentFieldsValues = [
      ...this.fieldsToCollect.map((field) => field.key),
      'phoneNumber',
    ]
    return [
      ...this.contactStore.contactFieldsOptions,
      ...this.customFieldsStore.customFieldsOptions,
    ].filter((opt) => !currentFieldsValues.includes(opt.value))
  }

  get disabledCreate() {
    return !(
      this.name &&
      this.smsPhone &&
      !this.textareaStore.isError &&
      this.dropdownContactListsStore.selectedOptions.length
    )
  }
  get fieldsToCollect() {
    return Array.from(this.fieldsToCollectMap.values())
  }

  get orderFieldsCollect() {
    return this.fieldsToCollect.map((filed) => filed.id)
  }

  setOrderFieldsCollect(order: string[]) {
    const fields: ICustomField[] = []
    order.forEach((id) => {
      const field = this.fieldsToCollectMap.get(id)
      if (field) {
        fields.push(field)
      }
    })
    this.fieldsToCollectMap = new Map(fields.map((filed) => [filed.id, filed]))
  }

  setFieldsCollect(field: ICustomField) {
    this.fieldsToCollectMap.set(field.id, field)
  }

  setRecommended(state: boolean) {
    this.recommended = state
  }
  setDoubleOptIn(state: boolean) {
    this.doubleOptIn = state
  }

  setEdit = (webForm: IWebForm, isDuplicate: boolean) => {
    if (isDuplicate) {
      this.duplicate = true
      this.status = Status.Active
    } else {
      this.edit = true
      this.uuid = webForm.uuid
      this.status = webForm.status
    }

    this.editId = webForm.id
    this.textareaStore.setSmsMessage(webForm.smsMessage)
    this.doubleOptIn = !!webForm.doubleOptIn
    this.textareaStore.setTriggerMessageUpdate()
    this.subscribeBtnText = webForm.subscribeBtnText || 'Subscribe'
    this.name = `${webForm.name}${isDuplicate ? ' copy' : ''}`
    if (
      !webForm.sendFromPhoneNumber?.verificationStatus ||
      webForm.sendFromPhoneNumber?.verificationStatus ===
        VerificationStatus.Unverified
    ) {
      this.smsPhone = ''
    } else {
      this.smsPhone = webForm.sendFromPhoneNumber?.friendlyName || ''
    }
    const fieldsToCollect: ICustomField[] = []
    webForm.fieldsToCollect?.forEach((webFormFiled) => {
      if (webFormFiled) {
        fieldsToCollect.push(
          transformWebFormCustomFieldToICustomField(webFormFiled)
        )
      }
    })
    this.fieldsToCollectMap = new Map<string, ICustomField>(
      fieldsToCollect.map((filed) => [filed.id, filed])
    )
    this.thankYouPage = !webForm.thankYouPage ? '' : 'custom'
    this.customWebsite = webForm.thankYouPage || ''
    if (webForm.contactsListsIds) {
      this.dropdownContactListsStore.setInitListsIds(webForm.contactsListsIds)
    }

    this.initInput = this.webFormInput
  }

  setThankYouPage(value: string) {
    this.thankYouPage = value
  }
  setCustomWebsite(val: string) {
    this.customWebsite = val
  }
  setSubscribeText = (val: string) => {
    this.subscribeBtnText = val
  }
  addCollectField() {
    const newField = generateField()
    this.activeFilterId = newField.id
    this.fieldsToCollectMap.set(newField.id, newField)
  }
  removeCollectField(id: string) {
    this.fieldsToCollectMap.delete(id)
  }
}
