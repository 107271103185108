import { ActionBtnProps } from 'components/NewTable/types'
import { MediumBtn } from 'components/Button/MediumBtn'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { ActionsEnum } from 'components/Button/types'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { IconColor, IIconType } from 'components/AppIcon/AppIcon'
import { CopyBtn } from 'components/Button/CopyBtn'
import { BtnSize } from 'components/Button/Button'

export const TableBtn = <T,>({
  medium,
  menu,
  onAction,
  action,
  iconType,
  iconColor,
  copyContent,
  textBtn,
  loading,
  disabled,
  extraBtnSize,
}: ActionBtnProps<T> & {
  iconType?: IIconType
  iconColor?: IconColor
  copyContent?: string
  textBtn?: string
  loading?: boolean
  disabled?: boolean
  extraBtnSize?: BtnSize
}) => {
  if (action === ActionsEnum.copy) {
    return (
      <CopyBtn
        menuBtn={menu}
        content={copyContent || ''}
        textBtn={textBtn}
        linkIcon
        withCopiedIcon
      />
    )
  }
  if (action === ActionsEnum.delete) {
    return (
      <DeleteBtn
        extraBtnSize={extraBtnSize}
        onClick={onAction!}
        medium={medium}
        small={!menu && !medium}
      />
    )
  }
  return medium || menu ? (
    <MediumBtn
      type={action!}
      onClick={onAction}
      menu={menu}
      iconType={iconType}
      iconColor={iconColor}
      loading={loading}
      disabled={disabled}
      text={textBtn}
      extraBtnSize={extraBtnSize}
    />
  ) : (
    <SmallBtn
      type={action!}
      onClick={onAction}
      iconType={iconType}
      iconColor={iconColor}
      disabled={disabled}
      tooltip={textBtn}
    />
  )
}
