import styles from './styles.module.scss'
import {
  IAccountIntegrationType,
  IIntegration,
} from 'src/routes/settings/integrations/content/integrationCards'
import integrationsStore from 'store/settings/integrationsStore'
import { observer } from 'mobx-react-lite'
import { ReactComponent as SentIcon } from 'icons/16pxNoMask/Status/Sent.svg'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames, ColorsValues } from 'styles/variables'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { FC, useMemo } from 'react'
import {
  AccountIntegrationType,
  useDisconnectIntegrationMutation,
} from 'src/generated/graphql'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ConnectIntegrationBtn } from 'src/routes/settings/integrations/content/card/ConnectIntegrationBtn'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'

type Props = IIntegration & {
  loadingIntegrationType?: IAccountIntegrationType | null
  loading: boolean
}

export const IntegrationsCard = observer(
  ({ loadingIntegrationType, loading, ...integration }: Props) => {
    const [disconnectIntegration] = useDisconnectIntegrationMutation()
    const {
      Logo,
      title,
      subTitle,
      icon,
      type,
      logoContent,
      docsLink,
      authUrl,
      disabled,
      w50,
      exploreLink,
    } = integration

    const oAuthIntegration =
      ((type === AccountIntegrationType.HubSpot ||
        type === AccountIntegrationType.Keap) &&
        integrationsStore.oAuth2IntegrationsMap.get(type)) ||
      undefined

    const isConnected = useMemo(() => {
      if (type === AccountIntegrationType.ActiveCampaign) {
        return !!integrationsStore.activeCampaign?.connected
      }
      if (
        type === AccountIntegrationType.HubSpot ||
        type === AccountIntegrationType.Keap
      ) {
        return !!oAuthIntegration?.connected && !oAuthIntegration.invalidated
      }
      return false
    }, [type, integrationsStore.activeCampaign, oAuthIntegration])

    const MenuItemDocs: FC<MoreBtnItemProps> = ({ onCloseTT }) => {
      const onClick = () => {
        onCloseTT && onCloseTT()
      }
      return (
        <a
          href={integration.docsLink}
          target={'_blank'}
          rel="noreferrer"
          className={'btn hoverGray small left icon'}
          onClick={onClick}
        >
          <span>Docs</span>
        </a>
      )
    }

    const MenuItemDisconnect: FC<MoreBtnItemProps> = ({ onCloseTT }) => {
      const onClick = async () => {
        try {
          const { data } = await disconnectIntegration({
            variables: {
              accountIntegrationType: type as AccountIntegrationType,
            },
          })
          if (data?.disconnectIntegration) {
            integrationsStore.setAccountIntegrationsData(
              data.disconnectIntegration
            )
            onCloseTT && onCloseTT()
          }
        } catch (e) {
          console.error(e)
        }
      }
      return <DeleteBtn onClick={onClick} noIcon text={'Disconnect'} />
    }

    const connectedUser = useMemo(() => {
      if (
        type === AccountIntegrationType.HubSpot ||
        type === AccountIntegrationType.Keap
      ) {
        return oAuthIntegration?.connectedUser
      }
      if (type === AccountIntegrationType.ActiveCampaign) {
        return integrationsStore.activeCampaign?.connectedUser
      }
      return 'john.doe jr'
    }, [type, integrationsStore.activeCampaign?.connectedUser])

    const actions = useMemo(() => {
      if (isConnected) {
        return (
          <div className={styles.connectedBtn}>
            <AppIcon color={ColorsNames.success1}>
              <SentIcon />
            </AppIcon>
            <span
              className={'success2 ellipsisNowrap'}
              title={connectedUser || undefined}
            >
              {connectedUser}
            </span>
          </div>
        )
      }
      if (type === 'Mock') {
        return (
          <div
            rel="noreferrer"
            className={classNames(
              'btn secondaryBlue small space-between fullWidth',
              styles.full
            )}
          >
            Explore
            <Icon icon={'angleRight'} color={ColorsValues.blue1} />
          </div>
        )
      }
      return (
        <>
          <MuiTooltip
            title={disabled ? 'Coming soon' : ''}
            arrow
            placement={'top'}
          >
            <div>
              <ConnectIntegrationBtn
                loadingIntegrationType={loadingIntegrationType}
                type={type}
                loading={loading}
                authUrl={oAuthIntegration?.authUrl || authUrl}
                text={
                  type === AccountIntegrationType.Custom
                    ? 'Add contacts'
                    : undefined
                }
                invalidated={oAuthIntegration?.invalidated}
              />
            </div>
          </MuiTooltip>
          <a
            href={docsLink}
            target={'_blank'}
            rel="noreferrer"
            className={'btn secondary'}
          >
            Docs
          </a>
        </>
      )
    }, [
      isConnected,
      connectedUser,
      type,
      authUrl,
      docsLink,
      oAuthIntegration,
      loading,
      loadingIntegrationType,
    ])

    const Wrapper: FC = ({ children }) => {
      if (type === 'Mock') {
        return (
          <a href={exploreLink} rel="noreferrer" target={'_blank'}>
            {children}
          </a>
        )
      }
      return <>{children}</>
    }

    return (
      <Wrapper>
        <div className={styles.card}>
          <div className={styles.wrapHeader}>
            {type !== 'Mock' && (
              <div className={styles.type}>
                <span className={'s2 medium purple1'}>Native</span>
              </div>
            )}
            {isConnected && (
              <div className={styles.wrapWore}>
                <MoreBtn
                  menuItems={[MenuItemDocs, MenuItemDisconnect]}
                  width={130}
                />
              </div>
            )}
          </div>

          <div
            className={classNames(
              styles.wrapLogo,
              type === 'Custom' && styles.callloop,
              { [styles.w50]: w50 }
            )}
          >
            {logoContent}
            {icon && <Icon icon={icon} fontSize={'unset'} />}
            {Logo && <Logo />}
          </div>
          <div className={styles.content}>
            <div className={styles.title}>
              <p>{title}</p>
              {subTitle && <p className={'gray1'}>({subTitle})</p>}
            </div>

            {/*<div className={'row8'}>*/}
            {/*  <span className={'s2 gray1'}>{automationsCount} automations</span>*/}
            {/*  <div className={styles.point} />*/}
            {/*  <span className={'s2 gray1'}>{zapsCount} zaps</span>*/}
            {/*</div>*/}
          </div>
          <div className={styles.actions}>{actions}</div>
        </div>
      </Wrapper>
    )
  }
)
