import audioStore from 'store/audioStore/audioStore'
import { onRenameTableCell } from 'components/NewTable/functions'
import { ActionsEnum } from 'components/Button/types'
import { MediumBtn } from 'components/Button/MediumBtn'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { IAudioItem } from 'src/types/IAudioItem'
import {
  useCheckAudioItemExistsLazyQuery,
  useDeleteAudioItemsMutation,
  useUpdateAudioItemMutation,
} from 'src/generated/graphql'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { ErrorsEnum } from 'src/static/errors'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export function downloadBlob(blob: string, filename: string) {
  const a = document.createElement('a')
  a.download = filename
  a.href = blob
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function useAudioFunctions({
  audioItem,
  ids,
  tr,
  setHover,
  audioItems,
}: {
  audioItem?: IAudioItem | null
  audioItems?: IAudioItem[]
  ids?: string[]
  tr?: HTMLTableRowElement | null
  setHover?: (val: boolean) => void
}) {
  const [deleteAudioItems] = useDeleteAudioItemsMutation()
  const [updateAudioItem] = useUpdateAudioItemMutation()
  const [checkAudioItemExists] = useCheckAudioItemExistsLazyQuery()
  const onDelete = async (ids?: string[]) => {
    const usagesIds: string[] = []
    const checkItem = (item: IAudioItem) => {
      const isUsage =
        !!item.usageStatistics?.broadcastCount ||
        !!item.usageStatistics?.triggerCount ||
        !!item.usageStatistics?.campaignCount
      if (isUsage) {
        usagesIds.push(item.id)
      }
    }
    if (audioItem) {
      checkItem(audioItem)
    }
    if (audioItems) {
      audioItems.forEach(checkItem)
    }
    const isAvailableDeleteIds: string[] =
      ids?.filter((id) => !usagesIds.includes(id)) || []
    if (usagesIds.length) {
      alertStore.setAlert({
        type: AlertTypeEnum.warning1,
        title:
          'Audios that are connected to Broadcasts, Campaigns and Triggers can’t be deleted',
      })
    }
    if (isAvailableDeleteIds?.length) {
      try {
        await deleteAudioItems({
          variables: {
            ids: isAvailableDeleteIds,
          },
        })
        audioStore.deleteAudios(isAvailableDeleteIds)
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onDownload = () => {
    const downloadItem = (item: IAudioItem) => {
      if (item.name && item.url) {
        fetch(item.url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob)
            downloadBlob(blobUrl, item.name || '')
          })
          .catch((e) => console.error(e))
      }
    }
    if (audioItems) {
      audioItems?.forEach(downloadItem)
    } else {
      if (audioItem?.url) {
        downloadItem(audioItem)
      }
    }
  }
  const onRename = () => {
    if (tr && audioItem) {
      onRenameTableCell({
        tr,
        row: audioItem,
        onSave: async (id, name) => {
          if (audioItem.name === name) {
            return
          }
          const { data } = await checkAudioItemExists({
            variables: {
              name,
            },
          })
          if (data?.checkAudioItemExists) {
            throw new Error(ErrorsEnum.existAudio)
          }
          if (!data?.checkAudioItemExists) {
            const { data: dataUpdate } = await updateAudioItem({
              variables: {
                input: {
                  id,
                  name,
                },
              },
            })
            if (dataUpdate?.updateAudioItem) {
              audioStore.updateAudioItem(dataUpdate.updateAudioItem)
            }
          }
        },
      })
    }
  }

  const onEditTTS = () => {
    console.log('Edit TTS')
  }

  const onDuplicate = () => {
    console.log('duplicate')
  }

  const getRowBtnContentByAction = (action: ActionsEnum, medium?: boolean) => {
    if (action === ActionsEnum.testAudio) {
      return medium ? (
        <TestItBtn setHover={setHover} fromAudio medium />
      ) : (
        <TestItBtn fromTable setHover={setHover} fromAudio />
      )
    }
    if (action === ActionsEnum.edit) {
      return medium ? (
        <MediumBtn type={ActionsEnum.edit} onClick={onEditTTS} />
      ) : (
        <SmallBtn type={ActionsEnum.edit} onClick={onEditTTS} />
      )
    }
    if (action === ActionsEnum.rename) {
      return medium ? (
        <MediumBtn type={ActionsEnum.edit} onClick={onRename} text={'Rename'} />
      ) : (
        <SmallBtn
          type={ActionsEnum.edit}
          onClick={onRename}
          tooltip={'Rename'}
        />
      )
    }
    if (action === ActionsEnum.download) {
      return medium ? (
        <MediumBtn type={ActionsEnum.download} onClick={onDownload} />
      ) : (
        <SmallBtn type={ActionsEnum.download} onClick={onDownload} />
      )
    }
    if (action === ActionsEnum.delete) {
      return medium ? (
        (audioItem || ids) && (
          <DeleteBtn
            onClick={() => onDelete(audioItem ? [audioItem.id] : ids)}
            medium
          />
        )
      ) : (
        <SmallBtn
          type={ActionsEnum.delete}
          onClick={() => onDelete(audioItem ? [audioItem.id] : ids)}
        />
      )
    }
  }

  const getMoreContentByAction = (
    action: ActionsEnum,
    onCloseTT?: () => void
  ) => {
    if (action === ActionsEnum.duplicate) {
      return (
        <MenuBtn
          type={MenuEnum.duplicate}
          onClick={() => {
            onCloseTT && onCloseTT()
            onDuplicate()
          }}
        />
      )
    }
    if (action === ActionsEnum.delete) {
      return (
        (audioItem || ids) && (
          <DeleteBtn
            onClick={() => onDelete(audioItem ? [audioItem.id] : ids)}
          />
        )
      )
    }
    return <></>
  }

  return {
    getRowBtnContentByAction,
    getMoreContentByAction,
  }
}
