import React, { FC, useEffect, useRef, useState } from 'react'
import { Separator } from 'components/Separator/Separator'
import { ColorsValues } from 'styles/variables'
import styles from './styles.module.scss'

export const ScrollWrapper: FC<{ maxHeight?: string | number }> = ({
  maxHeight,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [withBorderTop, setWithBorderTop] = useState(false)
  const [withBorderBottom, setWithBorderBottom] = useState(false)
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setWithBorderTop(e.currentTarget.scrollTop !== 0)
    setWithBorderBottom(
      Math.round(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          e.currentTarget.offsetHeight
      ) !== 0
    )
  }
  useEffect(() => {
    if (ref.current) {
      setWithBorderBottom(
        ref.current.scrollHeight -
          ref.current.scrollTop -
          ref.current.offsetHeight !==
          0
      )
    }
  }, [])
  return (
    <>
      <Separator
        color={withBorderTop ? ColorsValues.gray3 : ColorsValues.white}
      />
      <div
        className={styles.wrapContent}
        style={{ maxHeight }}
        onScroll={onScroll}
        ref={ref}
      >
        {children}
      </div>

      <Separator
        color={withBorderBottom ? ColorsValues.gray3 : ColorsValues.white}
      />
    </>
  )
}
