import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { Separator } from 'components/Separator/Separator'
import { SizedBox } from 'components/SizedBox'
import { StatusContent } from 'components/StatusContent/StatusContent'
import * as React from 'react'
import { useState } from 'react'
import { Details } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/details/Details'
import { Numbers } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/numbers/Numbers'
import { TollFreeVerificationStatus } from 'src/generated/graphql'
import { Tabs } from 'components/Tabs/Tabs'
import { UseCaseErrorAlert } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/UseCaseErrorAlert'
import typesStore from 'store/typesStore'
import { SuperAdminTFProfileActions } from 'src/routes/superAdmin/routes/complianceRS/components/SuperAdminTFProfileActions'

export const UseCaseProfileModal = observer(() => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const {
    openModal,
    useCaseName,
    status,
    setOpenModal,
    verificationErrorDescription,
    isSuperAdminModal,
    modalProfile,
    adminOrgId,
  } = tollFreeVerificationStore
  const { tollFreeMapping } = typesStore
  const onClose = () => {
    setOpenModal(false)
  }

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      title={'Use case profile'}
      topRight
    >
      <div className={'mediumModal'}>
        <Separator />
        <SizedBox height={32} />
        {isSuperAdminModal && modalProfile && adminOrgId && (
          <SuperAdminTFProfileActions
            profile={modalProfile}
            organizationId={adminOrgId}
          />
        )}
        <div className={'col12'}>
          <h3>{useCaseName}</h3>
          {status && (
            <StatusContent status={status} text={tollFreeMapping.get(status)} />
          )}
        </div>
        {verificationErrorDescription &&
          status === TollFreeVerificationStatus.Rejected && (
            <UseCaseErrorAlert />
          )}
        <SizedBox height={40} />
        <div className={'col32'}>
          <Tabs
            tabs={['Numbers', 'Details']}
            activeTabIndex={activeTabIndex}
            onTabClick={setActiveTabIndex}
          />
          {activeTabIndex === 0 && <Numbers />}
          {activeTabIndex === 1 && <Details />}
        </div>
      </div>
    </Modal>
  )
})
