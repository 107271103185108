import { observer } from 'mobx-react-lite'
import integrationsStore from 'store/settings/integrationsStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Modal } from 'components/Modal/Modal'
import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const ErrorIntegrationsModal = observer(() => {
  const onClose = () => {
    integrationsStore.setHubspotErrorConnect(null)
  }
  return (
    <Modal
      open={!!integrationsStore.errorHubspotConnect}
      onClose={onClose}
      title={'Authorization error'}
    >
      <div className={'mediumModal'}>
        <Alert
          type={AlertTypeEnum.error}
          title={
            'This HubSpot account is already connected to another Call Loop account '
          }
          text={
            <div>
              <p
                className={'par2 bold mt20'}
                style={{ color: 'var(--primary-color-black)' }}
              >
                Connected organization:
              </p>
              <p className={'par2'}>
                {integrationsStore.errorHubspotConnect?.extensions?.companyName}
              </p>
              <p className={'par2'}>
                {integrationsStore.errorHubspotConnect?.extensions?.ownerName}
              </p>
              <p className={'par2'}>
                {integrationsStore.errorHubspotConnect?.extensions?.ownerEmail}
              </p>
            </div>
          }
        />
        <SizedBox height={40} />
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  )
})
