import styles from './styles.module.scss'
import { MessageTemplatesModal } from 'src/widgets/MessageTemplate'
import { InputText } from 'components/Input/InputText/InputText'
import { SizedBox } from 'components/SizedBox'
import { SMSContent } from 'components/CreateElement/SMSContent'
import { MainVoiceContent } from 'components/CreateElement/MainVoiceContent'
import { RinglessContent } from 'components/CreateElement/RinglessContent'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { Card } from 'components/Card/Card'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Schedule } from 'components/Schedule/Schedule'

import { ReactComponent as ArrowDown } from 'icons/arrow_down_sm.svg'
import { CampaignMessageStore } from 'store/campaigns/campaignMessageStore'
import classNames from 'classnames'
import { SettingMessageCampaign } from 'src/routes/main/campaigns/create/createForm/MessageCard/SettingMessageCampaign'
import { Tabs } from 'components/Tabs/Tabs'

export const MessageCard = observer(
  ({
    createStore,
    index,
  }: {
    createStore: CampaignMessageStore
    index: number
  }) => {
    useEffect(() => {
      if (!createStore.name) {
        createStore.setName(`Message ${index + 1}`)
      }
    }, [])
    return (
      <>
        <div className={styles.followWrap}>
          <div className={styles.vertical_line} />
          <Schedule scheduleStore={createStore.scheduleStore} noLabel />
          <ArrowDown />
        </div>

        <Card
          width={600}
          className={classNames(
            styles.card,
            createStore.expand && styles.active
          )}
        >
          <MessageTemplatesModal store={createStore} />
          <div className={styles.inputWrap}>
            <div className={'row8'}>
              <div
                className={classNames(
                  styles.counter,
                  createStore.expand && styles.active
                )}
              >
                <span className={'medium blue1'}>{index + 1}</span>
              </div>
              <InputText
                id={'name'}
                onClick={() => createStore.setExpand(true)}
                placeholder={'Message name'}
                withoutBorder
                header3
                value={createStore.name}
                onChange={(event) => {
                  createStore.setName(event.target.value)
                }}
                withoutClear
              />
            </div>

            {/*{createCampaignStore.messages.length > 1 && (*/}
            {/*  <div className={styles.switch}>*/}
            {/*    <Switch*/}
            {/*      active={createStore.active}*/}
            {/*      onChange={(value) => createStore.setActive(value)}*/}
            {/*      tabIndex={-1}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
          <SizedBox height={32} />
          {createStore.expand && (
            <>
              <SizedBox height={8} />
              <Tabs
                tabs={['SMS', 'Voice', 'Ringless']}
                activeTabIndex={createStore.activeTab}
                onTabClick={(index) => createStore.setActiveTab(index)}
              />
              <SizedBox height={16} />
              {createStore.activeTab === 0 && (
                <SMSContent store={createStore} noCompliance noAutoFocus />
              )}
              {createStore.activeTab === 1 && (
                <MainVoiceContent store={createStore} />
              )}
              {createStore.activeTab === 2 && (
                <RinglessContent store={createStore} />
              )}
              <SizedBox height={32} />
            </>
          )}

          <div className={styles.wrapInfo}>
            <div className={'row12'}>
              {/*<StatusContent*/}
              {/*  status={createStore.active ? Status.Active : Status.Disabled}*/}
              {/*/>*/}
              {/*<span className={'gray1'}>{AppSymbols.point}</span>*/}
              <TypeContent type={createStore.type} />
            </div>

            <SettingMessageCampaign createStore={createStore} />
          </div>

          {createStore.expand && (
            <button
              className={styles.closeBtn}
              onClick={() => createStore.setExpand(false)}
            >
              <span className={'gray1 bold'}>Close</span>
            </button>
          )}
        </Card>
      </>
    )
  }
)
