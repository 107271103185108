import { createContext, FC, useContext, useState } from 'react'
import { CreateWebFormStore } from 'store/webForms/createWebFormStore'

const CreateWebFormContext = createContext<CreateWebFormStore>(
  new CreateWebFormStore()
)

export function useCreateWebFromContext() {
  return useContext(CreateWebFormContext)
}

export const CreateWebFormState: FC = (props) => {
  const [store] = useState(() => new CreateWebFormStore())
  return <CreateWebFormContext.Provider value={store} {...props} />
}
