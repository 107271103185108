import { SizedBox } from 'components/SizedBox'
import styles from 'src/routes/auth/styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { HorizontalDivider } from 'components/HorizontalDevider/HorizontalDivider'
import { LegacyRef, useEffect, useRef, useState } from 'react'
import { InputText } from 'components/Input/InputText/InputText'
import auth from 'store/auth'
import { observer } from 'mobx-react-lite'
import { Alert } from 'components/Alert/Alert'
import { AppGoogleLogin } from 'components/GoogleLogin/AppGoogleLogin'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ColorsValues } from 'styles/variables'
import { ErrorsEnum } from 'src/static/errors'
import { SignupPolicy } from 'src/routes/auth/components/SignupPolicy/SignupPolicy'

dayjs.extend(utc)
dayjs.extend(timezone)

interface IInviteData {
  email: string
  invitedBy: string
  uuid: string
}

export const Invite = observer(() => {
  const timeZoneCode = dayjs.tz.guess()
  const { uid } = useParams()
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [isRecaptchaSuccess, setRecaptchaSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [typePassword, setTypePassword] = useState('password')
  const [isValidPassword, setValidPassword] = useState(false)
  const [isUseEmail, setIsUseEmail] = useState(false)
  const [isWrongEmail, setIsWrongEmail] = useState(false)
  const [inviteData, setIsInviteData] = useState<IInviteData | null>(null)

  useEffect(() => {
    axios
      .get(`/auth/invite/${uid}`)
      .then(({ data }) => {
        setIsInviteData(data)
        if (data.email) {
          setEmail(data.email)
        }
      })
      .catch(console.error)
  }, [])

  const onSignUp = async () => {
    try {
      setLoading(true)
      setIsUseEmail(false)
      let isRecaptcha = isRecaptchaSuccess
      if (!isRecaptcha) {
        isRecaptcha = !!(await recaptchaRef.current?.executeAsync())
      }

      if (isRecaptcha && uid) {
        setRecaptchaSuccess(true)

        await auth.onSignUpInvite({
          email: email.trim(),
          password,
          firstName: firstName,
          lastName: lastName,
          invitationUuid: uid,
          timeZoneCode,
        })
      }
    } finally {
      setLoading(false)
    }
  }
  const isDisabled = !(
    email.trim() &&
    password.trim() &&
    firstName.trim() &&
    lastName.trim() &&
    isValidPassword &&
    !isWrongEmail
  )
  const checkEmail = () => {
    setIsWrongEmail(!(email.includes('@') && email.includes('.')))
  }
  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef as LegacyRef<ReCAPTCHA>}
        sitekey={process.env.RECAPTCHA_KEY || ''}
        size={'invisible'}
        hidden
      />
      <h2>Invitation for sign up</h2>
      <SizedBox height={8} />
      <p className={'par2 gray1 medium'}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        You've been invited by {inviteData?.invitedBy}
      </p>
      <SizedBox height={32} />
      {auth.authError?.includes(ErrorsEnum.alreadyUseEmail) && (
        <>
          <Alert
            title={'This email is already in use'}
            rightContent={
              <button className={styles.errorLink}>
                <span className={'bold linkError'}>Recover password</span>
              </button>
            }
            text={'Please recover your password or choose another email'}
            type={AlertTypeEnum.error}
          />
          <SizedBox height={20} />
        </>
      )}
      {isWrongEmail && (
        <>
          <Alert
            title={'There’s something wrong with your email'}
            text={'Perhaps you missed “@” or “.”'}
            type={AlertTypeEnum.error}
          />
          <SizedBox height={20} />
        </>
      )}
      <AppGoogleLogin
        invitationUuid={uid}
        fromSignUp
        // onSuccess={onSuccess}
      />
      <HorizontalDivider color={ColorsValues.gray2} />
      <SizedBox height={20} />
      <div className={styles.inputWrap}>
        <InputText
          label={'First Name'}
          placeholder={'e.g. Adam'}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onClickRightIcon={() => setFirstName('')}
        />
        <InputText
          label={'Last Name'}
          placeholder={'e.g. Robertson'}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onClickRightIcon={() => setLastName('')}
        />
      </div>
      <SizedBox height={16} />
      <InputText
        id={'email'}
        label={'Email address'}
        placeholder={'email@address.com'}
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
        onClickRightIcon={() => setEmail('')}
        error={isUseEmail || isWrongEmail ? ErrorsEnum.verifyEmail : undefined}
        onBlur={checkEmail}
      />
      <SizedBox height={16} />
      <InputText
        setValidPassword={setValidPassword}
        valid
        password
        id={'password'}
        label={'Password'}
        placeholder={'Enter your password'}
        value={password}
        type={typePassword}
        onChange={(e) => setPassword(e.target.value.trim())}
        onClickRightIcon={() =>
          setTypePassword(typePassword === 'password' ? 'text' : 'password')
        }
      />
      <SizedBox height={40} />
      <Button
        size={BtnSize.large}
        typeBtn={BtnType.primary}
        onClick={onSignUp}
        disabled={isDisabled}
        loading={loading}
      >
        Sign up
      </Button>
      <SizedBox height={16} />
      <SignupPolicy />
    </>
  )
})
