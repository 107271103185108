import {
  Contact,
  ContactCustomField,
  ContactCustomFieldRequestInput,
  ContactCustomFieldResponse,
  ContactLightResponseForTableFragment,
  ContactRequestInput,
  CustomFieldType,
  Listing,
  Maybe,
  Status,
} from 'src/generated/graphql'
import { IContact } from 'src/types/IContact'
import { IContactList } from 'src/types/IContactList'
import { ICustomFieldValue } from 'src/types/ICustomFieldValue'
import { ICustomField } from 'src/types/ICustomField'
import { nanoid } from 'nanoid'
import { IOption } from 'src/types/IOption'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const customFieldsMapToCustomField = (
  customFieldsMap: Map<string, Partial<ICustomFieldValue>>
): Array<ContactCustomFieldRequestInput> => {
  const customFields: ContactCustomFieldRequestInput[] = []
  customFieldsMap.forEach((customField) => {
    const getValue = () => {
      if (
        customField.type === CustomFieldType.Text ||
        customField.type === CustomFieldType.Url
      ) {
        return customField.value?.trim()
      }
      if (
        customField.type === CustomFieldType.Date ||
        customField.type === CustomFieldType.DateTime
      ) {
        return customField.date
      }
      if (customField.type === CustomFieldType.Number) {
        return customField.value
      }
      return ''
    }

    customFields.push({
      key: customField.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: getValue(),
    })
  })

  return customFields
}

const customFieldToCustomFieldsMap = (
  customFields: Maybe<Array<Maybe<ContactCustomField>>>
): Map<string, Partial<ICustomFieldValue>> => {
  const filtered: ContactCustomField[] = []
  customFields?.forEach((customField) => {
    if (customField) {
      filtered.push(customField)
    }
  })
  return new Map<string, Partial<ICustomFieldValue>>(
    filtered.map((customField) => [
      customField.field?.key || '',
      {
        value: customField.text || customField.number,
        id: customField.field?.key || '',
        date: customField.date,
        type: customField.field?.type || CustomFieldType.Text,
      },
    ])
  )
}

const customFieldResponseToCustomFieldsMap = (
  customFields: Maybe<Array<Maybe<ContactCustomFieldResponse>>>
): Map<string, Partial<ICustomFieldValue>> => {
  const filtered: ContactCustomFieldResponse[] = []
  customFields?.forEach((customField) => {
    if (customField) {
      filtered.push(customField)
    }
  })
  return new Map<string, Partial<ICustomFieldValue>>(
    filtered.map((customField) => [
      customField?.key || '',
      {
        value: customField.text || customField.number || '',
        id: customField?.key || '',
        date: customField.date || '',
        type: (customField?.type as CustomFieldType) || CustomFieldType.Text,
      },
    ])
  )
}

export const transformContact = (contact: Contact): IContact => {
  const items: IItem[] = []
  const lists: IContactList[] = []
  contact.listContacts?.forEach((list) => {
    if (list?.listing) {
      lists.push(transformListingToList(list.listing))
      items.push({
        name: list.listing?.name || '',
        id: list.listing?.id,
        icon: 'contactsFill',
        group: 'list',
      })
    }
  })
  return <IContact>{
    ...contact,
    id: contact.id,
    items,
    // name: `${contact.firstName || ''} ${contact.lastName || ''}`.trim(),
    firstName: contact.firstName || '',
    lastName: contact.lastName || '',
    email: contact.email || '',
    phoneNumber: contact.formattedPhoneNumber || '',
    listContacts: lists.map((list) => list.id),
    optIn: contact.optIn,
    createdAt: contact.createdAt,
    status: contact.status || Status.Active,
    lists,
    customFieldsMap: contact.customFields
      ? customFieldToCustomFieldsMap(contact.customFields)
      : new Map<string, ICustomFieldValue>(),
  }
}

export const transformContactLightResponse = (
  contact: ContactLightResponseForTableFragment
): IContact => {
  const items: IItem[] = []
  contact.listings?.forEach((list) => {
    if (list) {
      items.push({
        name: list?.name || '',
        id: list?.id,
        icon: 'contactsFill',
        group: 'list',
      })
    }
  })
  return <IContact>{
    ...contact,
    id: contact.id,
    items,
    name: contact.name,
    email: contact.email || '',
    formattedPhoneNumber: contact.phone,
    phoneNumber: contact.phone || '',
    optIn: contact.optIn,
    createdAt: contact.createdAt,
    status: contact.status || Status.Active,
    customFieldsMap: contact.contactCustomFields
      ? customFieldResponseToCustomFieldsMap(contact.contactCustomFields)
      : new Map<string, ICustomFieldValue>(),
  }
}

export const transformContactToBack = (
  contact: IContact
): ContactRequestInput => ({
  email: contact.email?.trim(),
  firstName: contact.firstName?.trim(),
  id: contact.id,
  lastName: contact.lastName?.trim(),
  phoneNumber: contact.phoneNumber,
  status: contact.status,
  customFields: customFieldsMapToCustomField(contact.customFieldsMap),
  listIds: contact.lists?.map(listToListId),
})

export const listToListId = (list: IContactList) => Number(list.id)

export const transformListingToList = (listing: Listing): IContactList => ({
  ...listing,
  name: listing.name || '',
  id: String(listing.id),
  createdAt: listing.createdAt,
  isNew: false,
  contacts: [],
  listWebFormsNames:
    listing.listWebForms?.map((list) => list?.webForm?.name).join(' ') || '',
  listKeywordsNames:
    listing.listKeywords?.map((list) => list?.keyword?.name).join(' ') || '',
})
export const generateField = (): ICustomField => ({
  name: '',
  type: undefined,
  id: nanoid(),
  used: false,
  idLong: 0,
})
export const contactListToOption = ({
  name,
  id,
  activeContactsCount,
}: {
  name?: string | null
  id?: string
  activeContactsCount?: number
}): IOption => ({
  title: name || '',
  value: id || '',
  count: activeContactsCount,
  leftIcon: 'contactsFill',
  iconClass: 'ml12 flex',
  group: 'list',
})

export const itemToOption = ({
  name,
  id,
  count,
  icon,
  group,
}: IItem): IOption => ({
  title: name || '',
  value: id || '',
  count,
  leftIcon: icon,
  iconClass: 'ml12 flex',
  group: group || 'list',
})

export const segmentToOption = ({
  name,
  id,
}: {
  name?: string | null
  id?: number
}): IOption => ({
  title: name,
  value: id,
  leftIcon: 'segments',
  iconClass: 'ml12 flex',
  group: 'segment',
})
