import React, { CSSProperties, FC } from 'react'
import { Tag } from 'components/Tag/Tag'

type Props = {
  name?: string
  onRemoveFile?: () => void
  maxWidth?: CSSProperties['maxWidth']
}

export const AttachmentFile: FC<Props> = ({ name, onRemoveFile, maxWidth }) =>
  name ? (
    <Tag
      leftIcon={'file'}
      text={name || ''}
      onDeleteTag={onRemoveFile}
      close={!!onRemoveFile}
      maxWidth={maxWidth}
    />
  ) : (
    <></>
  )
