import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'

type Props = {
  width?: number
  padding?: number
  className?: string
  noMobileRadius?: boolean
  margin?: number | string
}
export const Card: FC<Props> = observer(
  ({ width, padding, className, noMobileRadius, margin, ...props }) => (
    <div
      className={classNames(
        styles.card,
        className,
        noMobileRadius && uiStore.isMobile && styles.noMobileRadius
      )}
      style={{ width: uiStore.isMobile ? undefined : width, padding, margin }}
      {...props}
    />
  )
)
