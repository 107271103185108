import { AudioType } from 'src/generated/graphql'
import { ActionsEnum } from 'components/Button/types'

export function getActionsByType(type?: AudioType | null): ActionsEnum[] {
  // if (type === AudioType.Tts) {
  //   return [
  //     // ActionsEnum.testAudio,
  //     ActionsEnum.edit,
  //     ActionsEnum.download,
  //     ActionsEnum.duplicate,
  //     ActionsEnum.delete,
  //   ]
  // }
  return [
    // ActionsEnum.testAudio,
    ActionsEnum.rename,
    ActionsEnum.download,
    ActionsEnum.delete,
  ]
}
