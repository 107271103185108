import { ICardDetail } from 'components/DetailCard/DetailCard'
import { observer } from 'mobx-react-lite'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import { useGetCampaignStepStatisticsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import { detailsCards } from 'components/DetailCard/variants'

export const AdditionalContent = observer(() => {
  const { activeStep, stepStatisticTableStore, setStepMessageStatistics } =
    campaignDetailStore

  const { data, refetch } = useGetCampaignStepStatisticsQuery({
    variables: {
      uuid: activeStep?.uuid,
      dateRange: stepStatisticTableStore.rangeDatePickerStore.dateRange,
    },
    skip: stepStatisticTableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  const shortUrlClickStatistics =
    data?.getCampaignStepStatistics?.shortUrlClickStatistics
  const totalStatistics = data?.getCampaignStepStatistics?.totalStatistics
  const percentageStatistics =
    data?.getCampaignStepStatistics?.percentageStatistics

  const totalCount = totalStatistics?.totalCount || 0
  const successCount = totalStatistics?.successCount || 0
  const unsubscribedCount = totalStatistics?.unsubscribedCount || 0
  const pendingCount = totalStatistics?.pendingCount || 0
  const failedCount = totalStatistics?.failedCount || 0

  const successPercentage = percentageStatistics?.successPercentage || 0
  const unsubscribedPercentage =
    percentageStatistics?.unsubscribedPercentage || 0
  const failedPercentage = percentageStatistics?.failedPercentage || 0
  const pendingPercentage = percentageStatistics?.pendingPercentage || 0

  const cards: ICardDetail[] = [
    detailsCards.total({ count: totalCount }),
    detailsCards.pending({ count: pendingCount, percent: pendingPercentage }),
    detailsCards.success({ count: successCount, percent: successPercentage }),
    shortUrlClickStatistics?.shortUrlVisits?.length &&
      detailsCards.shortUrlClickStatistics({ shortUrlClickStatistics }),

    detailsCards.failed({ count: failedCount, percent: failedPercentage }),
    detailsCards.unsubscribed({
      count: unsubscribedCount,
      percent: unsubscribedPercentage,
    }),
  ].filter((card) => Boolean(card)) as ICardDetail[]

  useEffect(() => {
    setStepMessageStatistics(data?.getCampaignStepStatistics || null)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [stepStatisticTableStore.refreshTrigger])
  return (
    <DetailCardsWrapper cards={cards} tableStore={stepStatisticTableStore} />
  )
})
