import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import { IOrganization } from 'src/types/IOrganization'
import { organizationColumns } from 'components/NewTable/columns/superAdmin/organizationColumns'
import {
  AllowImportStatus,
  BillingStatus,
  Maybe,
  OrganizationResponse,
  OrganizationsListQuery,
  OrganizationStatus,
} from 'src/generated/graphql'
import { nanoid } from 'nanoid'

class SuperAdminStore {
  organizationTableStore: TableStore<IOrganization>
  constructor() {
    this.organizationTableStore = new TableStore({
      columns: organizationColumns,
      tableName: 'OrganizationTable',
      orderBy: 'signupDate',
      defaultHiddenColumn: ['canUseGlobalNumber', 'ipAddress'],
    })
    makeAutoObservable(this)
  }

  organizationsMap: Map<string, IOrganization> = new Map()
  organizationStatuses: OrganizationStatus[] = []
  billingStatuses: BillingStatus[] = []
  importsStatuses: AllowImportStatus[] = [AllowImportStatus.NeedsReview]

  openAddCreditsModalOrganizationId = ''
  openAddCallerIdModalOrganizationId = ''
  openBanOrganizationId = ''
  resetPasswordUserId = ''
  orgPageTrigger = ''
  needsReviewImportsCount = 0
  needsReviewComplianceCount = 0

  get organizations(): IOrganization[] {
    return Array.from(this.organizationsMap.values())
    // return Array.from(this.organizationsMap.values()).map((organization) => ({
    //   ...organization,
    //   disabledHover: organization.status === Status.Pending,
    // }))
  }

  get addCreditOrganization() {
    return this.organizationsMap.get(this.openAddCreditsModalOrganizationId)
  }

  get banOrganization() {
    return this.organizationsMap.get(this.openBanOrganizationId)
  }

  setOrganizations(organizations: Array<Maybe<OrganizationResponse>>) {
    this.organizationsMap.clear()
    organizations.forEach((org) => {
      if (org) {
        this.organizationsMap.set(org.id, { id: org.id, ...org })
      }
    })
  }

  updateOrganizations(organizations: Array<Maybe<OrganizationResponse>>) {
    organizations.forEach((org) => {
      if (org) {
        this.organizationsMap.set(org.id, { id: org.id, ...org })
      }
    })
  }

  setOrganizationListData(data: OrganizationsListQuery) {
    this.organizationTableStore.setTotal(data.organizationsList?.total || 0)
    if (data.organizationsList?.content) {
      this.setOrganizations(data.organizationsList?.content)
    }
  }

  setOpenAddCreditsModalOrganizationId(id: string) {
    this.openAddCreditsModalOrganizationId = id
  }

  setOpenAddCallerIdModalOrganizationId = (id: string) => {
    this.openAddCallerIdModalOrganizationId = id
  }

  setOpenBanOrganizationId(id: string) {
    this.openBanOrganizationId = id
  }
  setResetPasswordUserId(id: string) {
    this.resetPasswordUserId = id
  }

  updateOrgPageTrigger() {
    this.orgPageTrigger = nanoid()
  }

  setOrganizationStatuses(statuses: OrganizationStatus[]) {
    this.organizationTableStore.setPage(0)
    this.organizationStatuses = statuses
  }

  setSubscriptionStatuses(statuses: BillingStatus[]) {
    this.organizationTableStore.setPage(0)
    this.billingStatuses = statuses
  }

  setNotificationCount(
    needsReviewImportsCount: number,
    needsReviewComplianceCount: number
  ) {
    this.needsReviewImportsCount = needsReviewImportsCount
    this.needsReviewComplianceCount = needsReviewComplianceCount
  }
}

export default new SuperAdminStore()
