import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import {
  Status,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
} from 'src/generated/graphql'
import { useCampaignFunctions } from 'src/routes/main/campaigns/hooks/useCampaignFunctions'
import campaignsStore from 'store/campaigns/campaignsStore'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import createCampaignStore from 'store/campaigns/createCampaignStore'
import { CampaignEnrollModal } from 'src/routes/main/campaigns/modals/CampaignEnrollModal'

export const ActionsContent = observer(() => {
  const [createCampaign, { loading: loadingCreate }] =
    useCreateCampaignMutation()
  const [updateCampaign, { loading: loadingUpdate }] =
    useUpdateCampaignMutation()
  const { setTrigger, trigger, onTriggerClick, showTT } = useFixedTooltip()
  const {
    campaignInput,
    campaign,
    campaignUpdateInput,
    dropdownContactListsStore,
  } = createCampaignStore
  const { onDelete, onArchive, onRestore } = useCampaignFunctions(
    campaign || undefined
  )

  const [openEnrollModal, setOpenEnrollModal] = useState(false)

  const navigate = useNavigate()

  const onOpenEnrollModal = () => {
    setOpenEnrollModal(true)
  }

  const onCreate = async (enrollExisting = false) => {
    try {
      const { data } = await createCampaign({
        variables: {
          campaignInput: {
            ...campaignInput,
            enrollExisting,
            status:
              campaignInput.status === Status.Draft
                ? campaignInput.enabled
                  ? Status.Active
                  : Status.Disabled
                : campaignInput.status,
          },
        },
      })
      if (data) {
        campaignsStore.tableStore.onRefresh()
        uiStore.setNextRoute('')
        navigate(RoutesEnum.campaigns)
      }
    } catch (e) {
      console.error(e)
    }
    uiStore.setNextRoute('')
    navigate(RoutesEnum.campaigns)
  }
  const onUpdate = async () => {
    try {
      const { data } = await updateCampaign({
        variables: {
          campaignInput: campaignUpdateInput,
        },
      })
      if (data) {
        campaignsStore.tableStore.onRefresh()
        uiStore.setNextRoute('')
        navigate(RoutesEnum.campaigns)
      }
    } catch (e) {
      console.error(e)
    }
    uiStore.setNextRoute('')
    navigate(RoutesEnum.campaigns)
  }
  const onClose = () => {
    if (uiStore.isBeforeUnload) {
      uiStore.setOpenCancelCreateModal(true)
    } else {
      navigate(-1)
    }
  }

  const isCreate =
    !createCampaignStore.edit ||
    createCampaignStore.campaign?.status === Status.Draft

  const onEnroll = async () => {
    try {
      await onCreate(true)
      setOpenEnrollModal(false)
    } catch (e) {
      console.error(e)
    }
  }
  const onDontEnroll = async () => {
    try {
      await onCreate(false)
      setOpenEnrollModal(false)
    } catch (e) {
      console.error(e)
    }
  }
  const onCancelModalEnroll = () => {
    setOpenEnrollModal(false)
  }

  return (
    <div className={styles.actionsWrap}>
      <CampaignEnrollModal
        onEnroll={onEnroll}
        onDontEnroll={onDontEnroll}
        open={openEnrollModal}
        count={dropdownContactListsStore.selectedContactsCount}
        onCancel={onCancelModalEnroll}
      />
      <div className={styles.left}>
        {isCreate ? (
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onOpenEnrollModal}
            disabled={createCampaignStore.disabledCreate}
            loading={loadingCreate}
          >
            {createCampaignStore.createBtnText}
          </Button>
        ) : (
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onUpdate}
            disabled={createCampaignStore.disabledCreate}
            loading={loadingUpdate}
          >
            Save
          </Button>
        )}
        {createCampaignStore.edit &&
        createCampaignStore.campaign?.status === Status.Draft ? (
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onUpdate}
            loading={loadingUpdate}
          >
            Update draft
          </Button>
        ) : (
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
      </div>
      {createCampaignStore.edit && (
        <>
          <FixedTooltip
            visible={showTT}
            trigger={trigger}
            white
            noArrow
            position={TTPositionEnum.topRight}
            addLeftPosition={8}
            width={154}
          >
            <div className={styles.menuWrap}>
              {createCampaignStore.status === Status.Archived ? (
                <MenuBtn
                  type={MenuEnum.unarchive}
                  onClick={() => onRestore()}
                />
              ) : (
                createCampaignStore.status !== Status.Draft && (
                  <MenuBtn
                    type={MenuEnum.archive}
                    onClick={() => onArchive(undefined, true)}
                  />
                )
              )}
              <MenuBtn type={MenuEnum.delete} onClick={() => onDelete()} />
            </div>
          </FixedTooltip>
          <Button
            ref={setTrigger}
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onTriggerClick}
            icon
          >
            <More />
          </Button>
        </>
      )}
    </div>
  )
})
