import styles from './styles.module.scss'
import { Tag } from 'components/Tag/Tag'
import { observer } from 'mobx-react-lite'
import { IIcon } from 'components/Icon/Icon'
import { PlusItems } from 'components/PlusItems/PlusItems'
import { IOption } from 'src/types/IOption'

export type IItem = {
  name: string
  id: string | number
  count?: number
  icon?: IIcon
  group: 'contact' | 'list' | 'segment'
  backgroundColor?: IOption['backgroundColor']
  title?: IOption['title']
}

type ItemsRenderProps = {
  items: IItem[]
  itemsCount?: number
  onHoverItemsCount?: () => void
}

export const ItemsRender = observer(
  ({ items, itemsCount, onHoverItemsCount }: ItemsRenderProps) => {
    return (
      <div className={styles.wrapLists}>
        {!!items.length && items[0] && (
          <Tag
            text={items[0]?.name || 'Customers'}
            leftIcon={items[0]?.icon || 'contactsFill'}
            maxWidth={200}
            // count={items[0]?.count}
          />
        )}
        {((items?.length > 1 && items[1]) ||
          !!(itemsCount && itemsCount > 0)) && (
          <PlusItems
            items={items.slice(1)}
            itemsCount={itemsCount}
            onHoverPlus={onHoverItemsCount}
          />
        )}
      </div>
    )
  }
)
